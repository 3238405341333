import { getOrAdd } from './map';

export function toArray(arrayLike: Window): Window[];
export function toArray<T extends Node>(arrayLike: NodeListOf<T>): T[];
export function toArray<T>(arrayLike: any) {
  const array: T[] = [];
  for (let i = 0; i < arrayLike.length; i++) array.push(arrayLike[i]);
  return array;
}

export function flatten<T>(collection: T[][]): T[] {
  const newCollection: T[] = [];
  collection.forEach((subCollection) => {
    subCollection.forEach((item) => {
      newCollection.push(item);
    });
  });
  return newCollection;
}

export function keyBy<TItem, TValue>(items: TItem[], keySelector: (item: TItem) => string, valueSelector?: (item: TItem) => TValue): { [key: string]: TValue } {
  const map: { [key: string]: TValue } = {};
  const valueSelectorSafe = valueSelector || (((value: TItem) => value) as any);
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    map[keySelector(item) as any] = valueSelectorSafe(item);
  }
  return map as any;
}

export function groupBy<TItem>(items: TItem[], keySelector: (item: TItem) => string): { [key: string]: TItem[] };
export function groupBy<TItem, TValue>(items: TItem[], keySelector: (item: TItem) => string, valueSelector: (item: TItem) => TValue): { [key: string]: TValue[] };
export function groupBy<TItem, TValue>(items: TItem[], keySelector: (item: TItem) => string, valueSelector?: (item: TItem) => TValue): { [key: string]: TValue[] } {
  const map: { [key: string]: TValue[] } = {};
  const valueSelectorSafe = valueSelector || (((value: TItem) => value) as any);
  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    const group = getOrAdd(map, keySelector(item), () => [] as TValue[]);
    group.push(valueSelectorSafe(item));
  }
  return map as any;
}

function join<TA, TB>(a: TA[], b: TB[], aKeySelector: (a: TA) => string, bKeySelector: (a: TB) => string): [TA[], TB[]][] {
  const keys: { [key: string]: { a: TA[]; b: TB[] } } = {};
  a.forEach((aItem) => {
    getOrAdd(keys, aKeySelector(aItem), () => ({ a: [], b: [] })).a.push(aItem);
  });
  b.forEach((bItem) => {
    getOrAdd(keys, bKeySelector(bItem), () => ({ a: [], b: [] })).b.push(bItem);
  });
  return Object.getOwnPropertyNames(keys).map((k) => [keys[k].a, keys[k].b] as [TA[], TB[]]);
}

export function joinSingle<TA, TB>(a: TA[], b: TB[], aKeySelector: (a: TA) => string, bKeySelector: (a: TB) => string): [TA | null, TB | null][] {
  return join(a, b, aKeySelector, bKeySelector).map(([aItems, bItems]) => {
    if (aItems.length > 1) throw new Error('More than a single element of a had key ' + aKeySelector(aItems[0]));
    if (bItems.length > 1) throw new Error('More than a single element of b had key ' + bKeySelector(bItems[0]));
    return [aItems.length === 1 ? aItems[0] : null, bItems.length === 1 ? bItems[0] : null] as [TA | null, TB | null];
  });
}

export function rejectNull<T>(array: (T | null)[]): T[] {
  return array.filter((i) => i !== null) as T[];
}

export function rejectUndefined<T>(array: (T | undefined)[]): T[] {
  return array.filter((i) => i !== undefined) as T[];
}
