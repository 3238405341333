import { ErrorResult, Result, SuccessResult } from '@/gr/common/result';
import { IHttpRequester, ITrendResourceActionDto, ITrendSummaryResourceDto, ITrendDescriptionDto, SuccessHttpResponse } from '@/apps/timeSeriesViewer';
import { TrendPutResult } from '@/repositories';

export class TrendsApi {
  constructor(private _requester: IHttpRequester) {}

  async getTrends(): Promise<Result<ITrendSummaryResourceDto[], string>> {
    const response = await this._requester.get<ITrendSummaryResourceDto[]>({ relativeUrl: '/api/trends' });
    return response.isSuccess ? Result.success((response as SuccessHttpResponse<ITrendSummaryResourceDto[]>).data) : Result.error("We couldn't retrieve your saved trends.");
  }

  async deleteTrend(trendId: string): Promise<SuccessResult<undefined, never> | ErrorResult<never, string>> {
    const response = await this._requester.delete({ url: `/api/trends/${encodeURIComponent(trendId)}` });
    return response.isSuccess ? Result.success() : Result.error("We couldn't delete the trend.");
  }

  async updateTrend(update: ITrendResourceActionDto, serializedDto: string): Promise<Result<TrendPutResult, string>> {
    const response = await this._requester.ajax<TrendPutResult>({
      url: update.resourceUri,
      type: update.method,
      data: serializedDto,
      contentType: 'application/json'
    });
    return response.isSuccess ? Result.success((response as SuccessHttpResponse<TrendPutResult>).data) : Result.error("We couldn't save your trend.");
  }

  async getDescription(trendId: string): Promise<Result<ITrendDescriptionDto, string>> {
    const response = await this._requester.get<ITrendDescriptionDto>({ url: `/api/trends/${encodeURIComponent(trendId)}/description` });
    return response.isSuccess ? Result.success((response as SuccessHttpResponse<ITrendDescriptionDto>).data) : Result.error("We couldn't get the trend description.");
  }
}
