import ko from 'knockout';
import { Metric } from '.';

export class CalculatedMetric {
  constructor(public expression = ko.observable<string>('')) {}

  equals(d: Metric): boolean {
    return d instanceof CalculatedMetric && d.expression().toLowerCase() === this.expression().toLowerCase();
  }

  toUniqueString(): string {
    return this.expression();
  }
}
