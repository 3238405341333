import { toArray, flatten } from '../array';

export function getAncestorWindowsAndSelfDepthFirst(window: Window): Window[] {
  const windows = getAncestorWindowsDepthFirst(window);
  windows.splice(0, 0, window);
  return windows;
}

export function getAncestorWindowsDepthFirst(window: Window): Window[] {
  const windows: Window[] = [];
  let currentWindow = window;
  while (currentWindow.parent !== currentWindow) {
    currentWindow = currentWindow.parent;
    windows.push(currentWindow);
  }
  return windows;
}

export function getDescendentWindowsDepthFirst(window: Window): Window[] {
  const childWindows = toArray(window.frames);
  const windows = flatten(
    childWindows.map((w) => {
      const descendentWindows = getDescendentWindowsDepthFirst(w);
      descendentWindows.push(w);
      return descendentWindows;
    })
  );
  return windows;
}

export function getDescendentWindowsAndSelfDepthFirst(window: Window): Window[] {
  const windows = getDescendentWindowsDepthFirst(window);
  windows.push(window);
  return windows;
}

export function isWindowSameOrigin(window: Window) {
  try {
    // Accessing window.document will throw an error if the window is cross-origin to the current window origin
    if (window && window.document) return true;
  } catch (error) {
    // do nothing
  }
  return false;
}
