import { Grid, GroupCellRenderer } from 'ag-grid/main';
import { on } from '@/gr/common/utils/element';
import { CompositeDisposable } from '@/gr/common/disposable';

export class SingleClickGroupCellRenderer extends GroupCellRenderer {
  static overwriteDefaultGroupRenderer(grid: Grid): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (grid as any).context.beans.cellRendererFactory.beanInstance.cellRendererMap.group = SingleClickGroupCellRenderer;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  init(params: any): void {
    super.init(params);
    const eGroupCell: HTMLElement = params.eGridCell;

    const disposable = new CompositeDisposable();

    disposable.add(
      on(
        eGroupCell,
        'click',
        (event) => {
          // Prevent existing single click behavior on contract/expand icons
          event.stopPropagation();

          this.onExpandOrContract();
        },
        true
      )
    );

    disposable.add(
      on(
        eGroupCell,
        'dblclick',
        (event) => {
          // Prevent existing double click behavior
          event.stopPropagation();
        },
        true
      )
    );

    this.addDestroyFunc(() => {
      disposable.dispose();
    });
  }
}
