import moment from 'moment';
import { DataPointAggregator, IDataPointsForTime } from '@/apps/timeSeriesViewer';
import { IDisposable, Disposable } from '@/gr/common/disposable';
import AmCharts from '@/libs/amcharts';

export class AmChartDataPointAggregator {
  private _timerId: number | null = null;
  private _rawDataPoints!: IDataPointsForTime[];
  private _disableReaggregation = false;

  constructor(
    private _chart: AmCharts.AmChart,
    private _dataPointAggregator: DataPointAggregator,
    private _getMaxDataPointsToRender: () => number
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._chart.addListener('zoomed', (eventData: any) => {
      if (this._disableReaggregation) return;
      this.clearTimeout();
      const timerFunction: TimerHandler = () => {
        this.clearTimeout();
        if (this._rawDataPoints) {
          this._chart.dataProvider = this._dataPointAggregator.aggregateData(this._rawDataPoints, _getMaxDataPointsToRender(), moment(eventData.startDate), moment(eventData.endDate));
          this._chart.validateNow();
        }
      };
      this._timerId = setTimeout(timerFunction, 20);
    });
  }

  disableReaggregation(): IDisposable {
    this._disableReaggregation = true;
    return new Disposable(() => (this._disableReaggregation = false));
  }

  setDataPointsOnChart(dataPoints: IDataPointsForTime[]): void {
    this._rawDataPoints = dataPoints;
    if (dataPoints.length > 0) {
      const start = moment(dataPoints[0].timeStamp);
      const end = moment(dataPoints[dataPoints.length - 1].timeStamp);
      this._chart.dataProvider = this._dataPointAggregator.aggregateData(dataPoints, this._getMaxDataPointsToRender(), start, end);
    } else {
      this._chart.dataProvider = dataPoints;
    }
  }

  private clearTimeout() {
    if (this._timerId) clearTimeout(this._timerId);
  }
}

export class AmChartDataPointAggregatorFactory {
  create(chart: AmCharts.AmChart, getMaxDataPointsToRender: () => number): AmChartDataPointAggregator {
    return new AmChartDataPointAggregator(chart, new DataPointAggregator(), getMaxDataPointsToRender);
  }
}
