import _ from 'lodash';
import moment from 'moment';
import {
  IDataPointsForTime,
  TrendData,
  ISeriesDataCollectionDto,
  ISeriesDataDto,
  UtcOffset,
  AppDateTimesSnapshot,
  ConfiguredDataDefinitionConverter,
  UtcOffsetDefinitionConverter,
  InputDataDefinitionResult,
  ICompleteConfiguredDataDefinition
} from '@/apps/timeSeriesViewer';
import { keyBy } from '@/gr/common/utils/array';
import { Results } from '@/gr/common/result';

export class TrendDataConverter {
  constructor(private _configuredDataDefinitionConverter: ConfiguredDataDefinitionConverter) {}

  toModel(data: ISeriesDataCollectionDto, appDateTimes: AppDateTimesSnapshot, inputDataDefinitions: InputDataDefinitionResult[]): TrendData {
    const messages = _.flatMap(data.series, (d) => d.messages);
    const utcOffset = UtcOffsetDefinitionConverter.toUtcOffset(appDateTimes.utcOffsetDefinition());
    const dataSeries = this.applyCommonUtcOffset(data.series, utcOffset);
    const mergedDataPoints = this.mergeSeriesData(dataSeries);

    const configuredDataDefinitions = Results.successes(data.series.map((s) => this._configuredDataDefinitionConverter.toModel(s.configuredDataDefinition, inputDataDefinitions)));

    return new TrendData(appDateTimes, configuredDataDefinitions as ICompleteConfiguredDataDefinition[], mergedDataPoints, utcOffset, messages);
  }

  private applyCommonUtcOffset(dataSeriesCollection: ISeriesDataDto[], utcOffset: UtcOffset): ISeriesDataDto[] {
    const utcOffsetAsMinutes = utcOffset.value.asMinutes();
    return dataSeriesCollection.map((d) => {
      const newDataSeries = _.cloneDeep(d);
      _.forEach(newDataSeries.dataPoints, (point) => (point.timeStamp = moment(point.timeStamp).utcOffset(utcOffsetAsMinutes).format()));
      return newDataSeries;
    });
  }

  private mergeSeriesData(dataSeriesCollection: ISeriesDataDto[]): IDataPointsForTime[] {
    const configuredSeries = keyBy(
      dataSeriesCollection.filter((d) => !d.hasError),
      (d) => d.configuredDataDefinition.id,
      (d) => d.dataPoints
    );

    const count = _.size(configuredSeries);
    if (count === 0) return [];

    const mergedSeriesMap: { [timeStamp: string]: IDataPointsForTime } = {};

    _.forEach(configuredSeries, (data, key: string) => {
      if (_.isNil(data)) return;

      for (let pointIndex = 0; pointIndex < data.length; pointIndex++) {
        const dataPoint = data[pointIndex];

        let mergedDataPoint = mergedSeriesMap[dataPoint.timeStamp];

        if (!mergedDataPoint) {
          mergedDataPoint = { timeStamp: dataPoint.timeStamp };
          mergedSeriesMap[dataPoint.timeStamp] = mergedDataPoint;
        }

        mergedDataPoint[key] = dataPoint.value;
      }
    });

    const mergedSeries = _(mergedSeriesMap)
      .values()
      .sortBy((dataPoint: IDataPointsForTime) => dataPoint.timeStamp)
      .value();

    return mergedSeries;
  }
}
