import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';

export class LoadingComponent {
  constructor(private _args: LoadingComponentArgs) {
    this.loadingText = _args && _args.description ? _args.description : ko.observable('Loading...');
  }

  loadingText: KnockoutObservable<string>;
}

export class LoadingComponentArgs {
  public description = ko.observable('Loading...');
}

import html from './loadingComponent.html';
defineComponent(() => LoadingComponent, 'loading', html);
require('./loadingComponent.less');
