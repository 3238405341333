import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import _ from 'lodash';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import {
  ArtifactsRepository,
  IOrganisationDto,
  ITrendApiLinks,
  ITrendLinks,
  Messages,
  OrganisationsRepository,
  PolicyAuthorisationsService,
  Trend,
  TrendSaver,
  TrendServices,
  untitledTrendName
} from '@/apps/timeSeriesViewer';
import { CompositeDisposable } from '@/gr/common/disposable';
import { GlobalRoamAuthentication } from '@gr/authentication';
import html from './publishComponent.html';

export class PublishComponent {
  private _disposable = new CompositeDisposable();

  isLoading = ko.observable(true);

  canPublishToOrganisations = this._args.policyAuthorisations.isAuthorised('GlobalRoam');

  publishToOptions = ko.observableArray<string>(['Selected Organisations', 'All Users']);
  publishTo = ko.computed({
    read: () => (this._args.isPublishedToEveryone() ? 'All Users' : 'Selected Organisations'),
    write: (value) => this._args.isPublishedToEveryone(value === 'All Users')
  });
  publishingToSelectedOrganisations = ko.computed(() => this.canPublishToOrganisations() && this.publishTo() == 'Selected Organisations');

  allOrganisations = ko.observableArray<IOrganisationDto>([]);

  selectedOrganisation = ko.observable<IOrganisationDto>();
  selectedOrganisations = ko.computed(() => {
    if (this.isLoading()) return [];

    return _.filter(this.allOrganisations(), (o) => _.includes(this._args.publishedToOrganisations(), o.id));
  });

  availableOrganisations = ko.computed<IOrganisationDto[]>(() => _.difference(this.allOrganisations(), this.selectedOrganisations()));

  trendName;
  trendNameInvalid = ko.observable(false);
  isPublished;
  existingArtifactUrl;
  untitledTrendName = untitledTrendName;
  isPublishing = ko.observable(false);
  messages;

  constructor(
    private _args: Args,
    private _element: Node
  ) {
    this.trendName = this._args.trendName;
    this.isPublished = this._args.isPublished;
    this.existingArtifactUrl = this._args.trendLinks.artifact.resourceUri;
    this.messages = this._args.messagesArgsFactory.create();
    this._disposable.add(
      ko.computed(() => {
        if (this.canPublishToOrganisations()) {
          this._args.organisationsRepository.getAll().then((result) => {
            if (result.isSuccess) {
              this.allOrganisations(result.value);
              this.isLoading(false);
            }
            if (result.isError) {
              this.messages.add(result.error);
            }
          });
        } else {
          this.isLoading(false);
        }
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }

  addSelectedOrganisation(): void {
    const organisation = this.selectedOrganisation();
    if (organisation?.id && !this._args.publishedToOrganisations().includes(organisation?.id)) this._args.publishedToOrganisations.push(organisation.id);
  }

  removeOrganisation(organisation: IOrganisationDto): void {
    this._args.publishedToOrganisations.remove(organisation.id);
  }

  async publish(): Promise<void> {
    if (!this.validate()) return;

    this.isPublishing(true);

    const myOrganisationId = (this._args.authentication.user as unknown as { organisationId: string }).organisationId;
    if (!_.includes(this._args.publishedToOrganisations(), myOrganisationId)) {
      this._args.publishedToOrganisations.push(myOrganisationId);
    }

    await this._args.trendSaver.saveAsync();

    const result = await this._args.artifactsRepository.publish(this._args.trendApiLinks);
    if (result.isSuccess) {
      this._args.isPublished(true);
      try {
        this.iframe().src = this.existingArtifactUrl;
        this.iframe().contentWindow?.location.reload();
      } catch (e) {
        // do nothing
      }
    } else {
      this.messages.add(result.error);
    }
    this.isPublishing(false);
  }

  async unpublish(): Promise<void> {
    this.isPublishing(true);
    const result = await this._args.artifactsRepository.unpublish(this._args.trendApiLinks);
    if (result.isSuccess) {
      this._args.isPublished(false);
    } else {
      this.messages.add(result.error);
    }
    this.isPublishing(false);
  }

  private iframe() {
    return (this._element as HTMLElement).querySelector('.published-artifact') as HTMLIFrameElement;
  }

  private validate() {
    this.trendNameInvalid(_.isEmpty(this.trendName()));
    return !this.trendNameInvalid();
  }
}

export class Args {
  constructor(
    readonly trendName: KnockoutComputed<string | null | undefined>,
    readonly isPublished: kx.Observable<boolean>,
    readonly isPublishedToEveryone: kx.Observable<boolean>,
    readonly publishedToOrganisations: KnockoutObservableArray<string>,
    readonly artifactsRepository: ArtifactsRepository,
    readonly organisationsRepository: OrganisationsRepository,
    readonly policyAuthorisations: PolicyAuthorisationsService,
    readonly trendLinks: ITrendLinks,
    readonly trendApiLinks: ITrendApiLinks,
    readonly messagesArgsFactory: Messages.ArgsFactory,
    readonly authentication: GlobalRoamAuthentication,
    readonly trendSaver: TrendSaver
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(
      this._trend.title,
      this._trend.isPublished,
      this._trend.isPublishedToEveryone,
      this._trend.publishedToOrganisations,
      this._services.artifactsRepository,
      this._services.organisationsRepository,
      this._services.policyAuthorisations,
      this._trend.links,
      this._trend.apiLinks,
      new Messages.ArgsFactory(),
      this._services.authentication,
      this._services.trendSaver
    );
  }
}

defineComponent(() => PublishComponent, 'publish', html);
require('./publishComponent.less');
