import _ from 'lodash';
import { TrendDataDefinition, ConfiguredDataDefinitionConverter, ISeriesDataCollectionRequestDto, UtcOffsetDefinitionConverter } from '@/apps/timeSeriesViewer';

export class TrendDataDefinitionConverter {
  constructor(private _configuredDataDefinitionConverter: ConfiguredDataDefinitionConverter) {}

  toDto(dataDefinition: TrendDataDefinition): ISeriesDataCollectionRequestDto {
    return {
      configuredDataDefinitions: _.map(dataDefinition.configuredDataDefinitions, (d) => this._configuredDataDefinitionConverter.toDto(d)),
      pointInTimeContext: dataDefinition.appDateTimes.now(),
      start: dataDefinition.appDateTimes.start(),
      end: dataDefinition.appDateTimes.end(),
      utcOffsetInMinutes: UtcOffsetDefinitionConverter.toUtcOffset(dataDefinition.appDateTimes.utcOffsetDefinition()).value.asMinutes(),
      requestId: dataDefinition.requestId
    };
  }
}
