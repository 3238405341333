import ko from 'knockout';
import _ from 'lodash';
import { UnitsOfMeasure, AxisPosition, ICompleteChartSeries } from '@/apps/timeSeriesViewer';

export class ChartAxis {
  constructor(
    readonly id: string,
    readonly title: KnockoutObservable<string | null>,
    readonly position: KnockoutObservable<'left' | 'right'>,
    readonly unitsOfMeasure: KnockoutObservable<UnitsOfMeasure>,
    readonly axisLogarithmic: KnockoutObservable<boolean>,
    readonly axisFixed: KnockoutObservable<boolean>,
    readonly max: KnockoutObservable<number | undefined>,
    readonly min: KnockoutObservable<number | undefined>
  ) {}

  readonly dataMin = ko.observable<number | undefined>();
  readonly dataMax = ko.observable<number | undefined>();

  description = ko.pureComputed(() => {
    return !_.isEmpty(this.title()) ? `${this.title()} (${this.unitsOfMeasure().displayName} on the ${this.position()})` : `${this.unitsOfMeasure().displayName} on the ${this.position()}`;
  });
}

export class ChartAxesBuilder {
  constructor(private _axes: ChartAxis[]) {}

  build(): ChartAxis[] {
    return this._axes;
  }

  removeUnusedAxes(associatedSeries: ICompleteChartSeries[]): this {
    this._axes = this._axes.filter((a) => associatedSeries.some((s) => s.axis()?.id === a.id));
    return this;
  }

  addOrGetAxis(id: string | null, unitOfMeasure: UnitsOfMeasure | null): ChartAxis | null {
    const axisMatchingId = this.getAxis(id);
    if (axisMatchingId != null) return axisMatchingId;
    if (unitOfMeasure == null) return null;
    const axisMatchingUnitOfMeasure = _.find(this._axes, (a) => a.unitsOfMeasure().id === unitOfMeasure.id);
    if (axisMatchingUnitOfMeasure != null) return axisMatchingUnitOfMeasure;
    return this.addAxis(id, unitOfMeasure, null);
  }

  addAxis(id: string | null, unitOfMeasure: UnitsOfMeasure, position: AxisPosition | null): ChartAxis {
    const newPosition = position != null ? position : this.getNewBalancedAxisPosition();
    const newId = id != null ? this.assertIdUnique(id) : this.getNewId();
    const axis = this.create(newId, unitOfMeasure, newPosition);
    this._axes.push(axis);
    return axis;
  }

  private assertIdUnique(id: string): string {
    if (this.getAxis(id) != null) throw new Error(`All axes must have a unique id but trying to add axis with id {id} which already exists`);
    return id;
  }

  private getAxis(id: string | null): ChartAxis | undefined {
    return id != null ? _.find(this._axes, (a) => a.id === id) : undefined;
  }

  private getNewBalancedAxisPosition() {
    const countInPosition = _.defaults(
      _.countBy(this._axes, (a) => a.position()),
      { left: 0, right: 0 }
    );
    return countInPosition.left <= countInPosition.right ? 'left' : 'right';
  }

  private getNewId() {
    const ids = _.keyBy(this._axes, (a) => a.id);
    let id = 0;
    for (;;) {
      if (!(id.toString() in ids)) return id.toString();
      id++;
    }
  }

  private create(id: string, unitOfMeasure: UnitsOfMeasure, position: AxisPosition) {
    return new ChartAxis(
      id,
      ko.observable<string | null>(''),
      ko.observable<AxisPosition>(position),
      ko.observable<UnitsOfMeasure>(unitOfMeasure),
      ko.observable<boolean>(false),
      ko.observable<boolean>(false),
      ko.observable<number>(),
      ko.observable<number>()
    );
  }
}
