import moment from 'moment';
import { ITimerFactory, TimerFactory } from '@/gr/common/timer';
import { CompositeDisposable } from '@/gr/common/disposable';
import { ArtifactsRepository } from '@/apps/timeSeriesViewer';

export class DetectTrendChangedService {
  private _disposable = new CompositeDisposable();

  static reloadWindowWhenChanges(trendId: string | null, lastSaved: moment.Moment, artifactsRepo: ArtifactsRepository): DetectTrendChangedService {
    return new DetectTrendChangedService(new TimerFactory(), artifactsRepo, trendId, lastSaved, () => window.location.reload()).start();
  }

  constructor(
    private readonly _timerFactory: ITimerFactory,
    private readonly _artifactsRepo: ArtifactsRepository,
    private readonly _trendId: string | null,
    private readonly _lastSaved: moment.Moment,
    private readonly _onTrendChanged: () => void
  ) {}

  start(): this {
    this._disposable.add(this._timerFactory.create().setToFireEvery(moment.duration(1, 'minute'), () => this.checkTrendUpdated()));
    // this._disposable.add(this._timerFactory.create().setToFireEvery(moment.duration(10, 'seconds'), () => this.checkTrendUpdated()));

    return this;
  }

  private async checkTrendUpdated() {
    if (this._trendId === null) return;

    const lastSaved = await this._artifactsRepo.getLastSaved(this._trendId);
    if (lastSaved.isSuccess) {
      if (lastSaved.value != null && !this._lastSaved.isSame(lastSaved.value)) {
        this._onTrendChanged();
      }
    }
  }

  dispose(): void {
    this._disposable.dispose();
  }
}
