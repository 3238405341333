import moment from 'moment';
import { IDisposable, Disposable, SerialDisposable } from './disposable';

interface ITimer {
  setToFireEvery(duration: moment.Duration, callback: () => void, config?: { fireImmediately?: boolean }): this;
  setToFireOnceIn(duration: moment.Duration, callback: () => void): this;
  stop(): this;
  dispose(): void;
}

export class Timer implements ITimer {
  private _isDisposed = false;
  private _disposable = new SerialDisposable();

  setToFireOnceIn(duration: moment.Duration, callback: () => void): this {
    if (this._isDisposed) return this;
    const timeoutId = setTimeout(callback, duration.asMilliseconds());
    this._disposable.setDisposable(() => {
      clearTimeout(timeoutId);
    });
    return this;
  }

  setToFireEvery(duration: moment.Duration, callback: () => void, config?: { fireImmediately?: boolean }): this {
    if (this._isDisposed) return this;
    const timeoutId = setInterval(callback, duration.asMilliseconds());
    this._disposable.setDisposable(() => {
      clearTimeout(timeoutId);
    });
    if (config && config.fireImmediately) callback();
    return this;
  }

  stop(): this {
    this._disposable.setDisposable(Disposable.empty);
    return this;
  }

  dispose(): void {
    this._isDisposed = true;
    this._disposable.dispose();
  }
}

export interface ITimerFactory {
  create(): ITimer;
}

export class TimerFactory implements ITimerFactory {
  create() {
    return new Timer();
  }
}
