import ko from 'knockout';
import _ from 'lodash';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import '@/gr/common/knockout/bindings/fastForEach';

export interface IGroup {
  id: string;
  name: string;
}

class Chooser<T extends IGroup> {
  private _disposable = new CompositeDisposable();
  private selected: KnockoutObservable<T | undefined>;

  constructor(
    public index: number,
    public groups: T[],
    onSelected: (index: number, selectedGroup: T | undefined) => void,
    selected?: T | undefined
  ) {
    this.selected = ko.observable<T | undefined>(selected);

    this._disposable.add(
      this.selected.subscribe((value) => {
        onSelected(index, value);
      })
    );
  }

  removeGroup = (chooser: Chooser<T>) => {
    chooser.selected(undefined);
  };

  dispose(): void {
    this._disposable.dispose();
  }
}

export class GroupByChooserArgs<T extends IGroup> {
  constructor(
    readonly groups: KnockoutComputed<T[]>,
    readonly chosenGroups: KnockoutComputed<T[]>
  ) {}
}

export class GroupByChooserViewModel<T extends IGroup> {
  private _disposable = new CompositeDisposable();
  choosers: KnockoutComputed<Chooser<T>[]>;

  constructor(public params: GroupByChooserArgs<T>) {
    this.choosers = ko.pureComputed(() => {
      const choosers: Chooser<T>[] = [];
      let remainingGroups = _.clone(params.groups());

      _.forEach(params.chosenGroups(), (chosenGroup, i) => {
        choosers.push(new Chooser(i, remainingGroups, this.selectGroup, chosenGroup));
        remainingGroups = _.without(remainingGroups, chosenGroup);
      });

      if (remainingGroups.length > 0) choosers.push(new Chooser(choosers.length, remainingGroups, this.selectGroup));

      return choosers;
    });

    this._disposable.add(this.choosers);
  }

  selectGroup = (index: number, selectedGroup: T | undefined): void => {
    if (selectedGroup && index >= this.params.chosenGroups().length) {
      const temp = this.params.chosenGroups();
      temp.push(selectedGroup);
      this.params.chosenGroups(temp);
    } else if (selectedGroup) {
      const temp = this.params.chosenGroups().slice(0, index + 1);
      temp[index] = selectedGroup;
      this.params.chosenGroups(temp);
    } else {
      const temp = this.params.chosenGroups().slice(0, index);
      this.params.chosenGroups(temp);
    }
  };

  dispose(): void {
    this._disposable.dispose();
  }
}

import html from './groupByChooser.html';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
defineComponent(() => GroupByChooserViewModel as any, 'groupByChooser', html);
require('./groupByChooser.less');
