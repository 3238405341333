import moment from 'moment';
import _ from 'lodash';
import { IDataPointsForTime, MomentConverter } from '@/apps/timeSeriesViewer';

export class AmChartDataPointsTransformer {
  private _momentConverter = new MomentConverter();

  removeDataPointsBelowZero(dataPoints: IDataPointsForTime[]): IDataPointsForTime[] {
    const modifiedDataPoints = _.map(dataPoints, (dataPoint) =>
      _.mapValues(dataPoint, (value, key) => {
        if (key === 'timeStamp') return value;

        return value <= 0 ? null : value;
      })
    );

    return modifiedDataPoints;
  }

  transformDataPointsToUtcOffset(dataPoints: IDataPointsForTime[], offset: moment.Duration): IDataPointsForTime[] {
    // AmCharts always displays data in the user's local UTC offset. We need to transform the data so it displays with the desired offset.
    // e.g. The user is in Brisbane (+10:00), but viewing data about the UK (+00:00).
    // We want data to be displayed in the +00:00 offset. Instead, AmCharts will display the
    // data in the local +10:00 offset. We need to transform the data so it display correctly.
    // For example:
    // Data time stamp: 10:00 +00:00
    // We want this to show up as: "10:00" because we want to show the data in +00:00.
    // AmCharts will show this as: "20:00" because that is 10:00 +00:00 in +10:00 local time.
    // If we change the data time stamp to: 00:00 +00:00
    // Then AmCharts will show this as: "10:00" because that is 00:00 +00:00 in +10:00 local time.

    return _.map(dataPoints, (dataPoint) => {
      const clonedDataPoint = _.clone(dataPoint);

      clonedDataPoint.timeStamp = this._momentConverter.convertToMomentInLocalOffsetWithLocalTimeAsIfInDesiredOffset(clonedDataPoint.timeStamp, offset).format();

      return clonedDataPoint;
    });
  }

  withStartAndEnd(dataPoints: IDataPointsForTime[], start: moment.Moment, end: moment.Moment): IDataPointsForTime[] {
    const newDataPoints = _.clone(dataPoints);

    const currentStart = newDataPoints.length > 0 ? moment(newDataPoints[0].timeStamp) : null;
    const currentEnd = newDataPoints.length > 0 ? moment(newDataPoints[newDataPoints.length - 1].timeStamp) : null;

    if (currentStart && currentStart.isAfter(start)) newDataPoints.unshift({ timeStamp: start.format() });

    if (currentEnd && currentEnd.isBefore(end)) newDataPoints.push({ timeStamp: end.format() });

    return newDataPoints;
  }
}
