import moment from 'moment';
import _ from 'lodash';

export namespace Format {
  export const dataMissingIndicator = '--';

  export namespace dateTime {
    export const formatWithNamedTimeZone = (dateTime: string, timeZone: string, format: string) => (dateTime ? `${Format.dateTime.format(dateTime, format)} (${timeZone})` : dataMissingIndicator);
    export const format = (dateTime: string, format: string) => (dateTime ? moment.parseZone(dateTime).format(format) : dataMissingIndicator);
  }

  export namespace dateTimeFormats {
    export const long = 'lll';
    export const longWithOffset = 'lll ([UTC] Z)';
  }

  export namespace number {
    export const round = (x: number, decimalPlaces: number) => {
      if (_.isNull(x)) return dataMissingIndicator;

      return x.toFixed(decimalPlaces);
    };

    export const toString = (x: number) => {
      if (_.isNull(x)) return dataMissingIndicator;

      return x.toString();
    };

    export const countDecimals = (x: number) => {
      if (_.isNil(x)) return 0;

      return countDecimalsInString(x.toString());
    };

    export const countDecimalsInString = (x: string | null | undefined) => {
      if (x == null) return 0;

      const decimalPointIndex = x.indexOf('.');
      if (decimalPointIndex === -1) return 0;

      let exponentIndex = x.indexOf('e', decimalPointIndex);
      if (exponentIndex === -1) exponentIndex = x.length;

      return exponentIndex - (decimalPointIndex + 1);
    };
  }
}
