import moment from 'moment';
import _ from 'lodash';
import { Results } from '@/gr/common/result';
import { IAppDateTimesDto, ITrendResourceDto, IUtcOffsetDefinitionDto, IRelativeDurationDto, TrendServices } from '@/apps/timeSeriesViewer';
import {
  TrendSummaryForChannels,
  Trend,
  AppDateTimes,
  RelativeDuration,
  getRelativeDurationUnitFromId,
  UtcOffsetDefinition,
  InputDataDefinitionFactory,
  InputDataDefinitionResult
} from '@/apps/timeSeriesViewer/models';
import { ConfiguredDataDefinitionConverter, ChannelConverter, InputDataDefinitionConverter, ChannelConverterFactory } from '.';

export class TrendConverter {
  constructor(
    private _configuredDataDefinitionConverter: ConfiguredDataDefinitionConverter,
    private _channelConverter: ChannelConverter
  ) {}

  copyToModel(trendDescriptorDto: ITrendResourceDto, trend: Trend, inputDataDefinitions: InputDataDefinitionResult[]): Trend {
    const trendDto = trendDescriptorDto.trend;

    trend.id = trendDto.id || null;
    trend._etag = trendDto._etag || null;
    trend.version = trendDto.version || null;
    trend.userId = trendDto.userId || null;
    trend.isDraft = trendDto.isDraft;
    trend.isPublished(trendDto.isPublished);
    trend.isPublishedToEveryone(trendDto.isPublishedToEveryone);
    trend.publishedToOrganisations(trendDto.publishedToOrganisations);
    trend.links = trendDescriptorDto.links;
    trend.apiLinks = trendDescriptorDto.apiLinks;
    trend.currentStepId(trendDto.currentStepId);
    trend.title(trendDto.title || null);
    trend.lastSavedTime = moment(trendDto.lastSavedTime);
    trend.creationTime = moment(trendDto.creationTime);

    const configuredDataDefinitions = trendDto.dataDefinition.configuredDataDefinitions.map((dto) => this._configuredDataDefinitionConverter.toModel(dto, inputDataDefinitions));

    Results.errors(configuredDataDefinitions).forEach((error) => trend.messages.add(error));

    trend.configuredDataDefinitions(Results.successes(configuredDataDefinitions));

    this.copyAppDateTimesToModel(trendDto.dataDefinition.appDateTimes, trend.appDateTimes);

    const channels = trendDto.channels.map((c) => this._channelConverter.toModel(c, new TrendSummaryForChannels(trend.dataDefinition, trend.title, trend.isReadOnlyMode, trend.isTrendEmbeddable)));
    trend.outputChannelContainers(channels);
    trend.selectedOutputChannelContainer(channels[trendDto.selectedChannelIndex]);

    return trend;
  }

  toDto(trend: Trend): ITrendResourceDto {
    return {
      trend: {
        id: trend.id,
        _etag: trend._etag != null ? trend._etag : undefined,
        version: trend.version ?? 0,
        userId: trend.userId ?? undefined,
        isDraft: trend.isDraft,
        isPublished: trend.isPublished(),
        isPublishedToEveryone: trend.isPublishedToEveryone(),
        publishedToOrganisations: trend.publishedToOrganisations(),
        channels: _.map(trend.outputChannelContainers(), (c) => this._channelConverter.toDto(c)),
        selectedChannelIndex: _.findIndex(trend.outputChannelContainers(), (c) => c === trend.selectedOutputChannelContainer()),
        title: trend.title(),
        lastSavedTime: trend.lastSavedTime.format(),
        creationTime: trend.creationTime.format(),
        currentStepId: trend.currentStepId(),
        dataDefinition: {
          appDateTimes: this.appDateTimesToDto(trend.appDateTimes),
          configuredDataDefinitions: _.map(trend.configuredDataDefinitions(), (d) => this._configuredDataDefinitionConverter.toDto(d))
        }
      },
      links: trend.links,
      apiLinks: trend.apiLinks
    };
  }

  private copyAppDateTimesToModel(dto: IAppDateTimesDto, model: AppDateTimes) {
    if (dto.fixedStart != null) model.fixedStart(moment(dto.fixedStart));
    if (dto.fixedEnd != null) model.fixedEnd(moment(dto.fixedEnd));
    if (dto.lookBack != null) model.lookBack(this.relativeDurationToModel(dto.lookBack));
    if (dto.lookForward != null) model.lookForward(this.relativeDurationToModel(dto.lookForward));
    model.isRelative(dto.isRelative);
    model.utcOffsetDefinition(this.utcOffsetDefinitionToModel(dto.utcOffsetDefinition, model.utcOffsetDefinition()));
    return model;
  }

  private relativeDurationToModel(dto: IRelativeDurationDto) {
    return new RelativeDuration(dto.count, getRelativeDurationUnitFromId(dto.unitId));
  }

  private utcOffsetDefinitionToModel(dto: IUtcOffsetDefinitionDto, current: UtcOffsetDefinition): UtcOffsetDefinition {
    if (dto.fixedOffsetInMinutes) {
      return { type: 'Offset', fixedOffsetInMinutes: dto.fixedOffsetInMinutes };
    } else if (dto.timeZone) {
      return { type: 'TimeZone', timeZone: dto.timeZone };
    } else {
      return current;
    }
  }

  private appDateTimesToDto(model: AppDateTimes): IAppDateTimesDto {
    return {
      fixedStart: model.fixedStart().format(),
      fixedEnd: model.fixedEnd().format(),
      lookForward: this.relativeDurationToDto(model.lookForward()),
      lookBack: this.relativeDurationToDto(model.lookBack()),
      isRelative: model.isRelative(),
      utcOffsetDefinition: this.utcOffsetDefinitionToDto(model.utcOffsetDefinition())
    };
  }

  private relativeDurationToDto(model: RelativeDuration): IRelativeDurationDto {
    return { count: model.count, unitId: model.unit.id };
  }

  private utcOffsetDefinitionToDto(model: UtcOffsetDefinition): IUtcOffsetDefinitionDto {
    switch (model.type) {
      case 'Offset':
        return { fixedOffsetInMinutes: model.fixedOffsetInMinutes };
      case 'TimeZone':
        return { timeZone: model.timeZone };
    }
  }
}

export class TrendConverterFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}
  create(): TrendConverter {
    return new TrendConverter(
      new ConfiguredDataDefinitionConverter(new InputDataDefinitionConverter(), new InputDataDefinitionFactory()),
      new ChannelConverterFactory(this._trend, this._services).create()
    );
  }
}
