import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { FileState, TrendDataDefinition, Trend, Messages, CsvDownloadService, TrendServices, LoadingComponentArgs } from '@/apps/timeSeriesViewer';
import { GrTimeChooser } from '@/components';
import { KnockoutVueComponent } from '@/common/knockout-vue-binding';
import { AppDateTimes, RelativeDuration, relativeDurationUnits } from '@/apps/timeSeriesViewer/models';
import { AppDateTimesDto } from '@/repositories';

export class Component {
  private _dateTimes;
  grTimeChooserProps: ComponentOptions<Vue>;
  grTimeChooser: KnockoutVueComponent;

  isLoading = ko.observable(false);
  messages;
  description;
  loadingArgs = new LoadingComponentArgs();

  constructor(private _args: Args) {
    this.messages = this._args.messagesArgsFactory.create();
    this.description = this._args.description;

    this._dateTimes = this._args.dateTimes.clone();
    this.grTimeChooserProps = {
      propsData: {
        value: {
          fixedStart: this._dateTimes.fixedStart(),
          fixedEnd: this._dateTimes.fixedEnd(),
          isRelative: this._dateTimes.isRelative(),
          lookBack: { count: this._dateTimes.lookBack().count, unitId: this._dateTimes.lookBack().unit.id },
          lookForward: { count: this._dateTimes.lookForward().count, unitId: this._dateTimes.lookForward().unit.id }
        },
        isAbleToLookForward: true,
        save: (dateTime: AppDateTimesDto): void => {
          this.saveDateTime(dateTime);
        }
      }
    };
    this.grTimeChooser = new KnockoutVueComponent(GrTimeChooser, this.grTimeChooserProps);
  }

  private saveDateTime(dateTime: AppDateTimesDto) {
    if (dateTime.fixedStart) this._dateTimes.fixedStart(dateTime.fixedStart);
    if (dateTime.fixedEnd) this._dateTimes.fixedEnd(dateTime.fixedEnd);
    this._dateTimes.isRelative(dateTime.isRelative);
    if (dateTime.lookBack) this._dateTimes.lookBack(new RelativeDuration(dateTime.lookBack.count, relativeDurationUnits[dateTime.lookBack.unitId]));
    if (dateTime.lookForward) this._dateTimes.lookForward(new RelativeDuration(dateTime.lookForward.count, relativeDurationUnits[dateTime.lookForward.unitId]));
  }

  async downloadAsCsv(): Promise<void> {
    this.isLoading(true);

    const currentDefinition = this._args.trendDataDefinition();
    const trendDataDefinition = new TrendDataDefinition(this._dateTimes.snapshot(), currentDefinition.configuredDataDefinitions, currentDefinition.requestId);

    await this._args.csvDownloadService.downloadAsCsv(trendDataDefinition, this.messages, this.loadingArgs.description, this.isLoading);

    this.isLoading(false);
  }
}

export class Args {
  constructor(
    public state: FileState,
    public messagesArgsFactory: Messages.ArgsFactory,
    public csvDownloadService: CsvDownloadService,
    public trendDataDefinition: kx.ReadOnlyObservable<TrendDataDefinition>,
    public description: KnockoutObservable<string>,
    public dateTimes: AppDateTimes
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(state: FileState): Args {
    return new Args(state, new Messages.ArgsFactory(), this._services.csvDownloadService(), this._trend.dataDefinition, this._trend.description, this._trend.appDateTimes);
  }
}

import html from './fileComponent.html';
import { ComponentOptions } from 'vue/types/options';
import Vue from 'vue';
defineComponent(() => Component, 'file', html);
require('./fileComponent.less');
