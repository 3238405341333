export class AmChartValueAxisOffsetCalculator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  updateAxisOffsets(chart: any): IAmChartAxisOffsets {
    const offsets: IAmChartAxisOffsets = {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    };

    if (chart.axisMargins === undefined) {
      chart.axisMargins = {
        left: chart.marginLeftReal,
        right: chart.marginRightReal,
        top: chart.marginTopReal,
        bottom: chart.marginBottomReal
      };
    }

    for (let i = 0; i < chart.valueAxes.length; i++) {
      const axis = chart.valueAxes[i];

      let axisWidth;
      const boundingBox = this.getBoundingBox(axis);

      if (axis.position === 'top' || axis.position === 'bottom') {
        if (boundingBox.height === 0) continue;
        axisWidth = boundingBox.height + chart.autoMarginOffset + 10;
        if (typeof axis.guides !== 'undefined' && axis.guides.length) axisWidth -= chart.plotAreaHeight;
      } else {
        if (boundingBox.width === 0) continue;
        axisWidth = boundingBox.width + chart.autoMarginOffset + 10;
        if (typeof axis.guides !== 'undefined' && axis.guides.length) axisWidth -= chart.plotAreaWidth;
      }

      if (axis.autoOffset === true) {
        axis.offset = offsets[axis.position];
        offsets[axis.position] += axisWidth;

        if (axis.axisThickness > 1) offsets[axis.position] += axis.axisThickness;
      }
    }

    return offsets;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getBoundingBox(axis: any): { width: number; height: number } {
    const boundingBox = axis.getBBox();
    if (boundingBox.width === 0 && boundingBox.height === 0) {
      return { width: 0, height: 0 };
    } else {
      return boundingBox;
    }
  }
}

export interface IAmChartAxisOffsets {
  [position: string]: number;
  left: number;
  right: number;
  top: number;
  bottom: number;
}
