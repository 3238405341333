import _ from 'lodash';
import { Result } from '@/gr/common/result';
import { CancellationToken } from '@/gr/common/cancellationToken';
import {
  RawDataDefinitionChooserStateToSearchConverter,
  InputDataDefinitionChooserState,
  InputDataDefinition,
  InputDataDefinitionConverter,
  Metric,
  InputDataDefinitionFactory,
  IInputMetricDto,
  InputDataDefinitionResult,
  ISearchRequestDto,
  ErroredInputDataDefinitionResult,
  SuccessfulInputDataDefinitionResult,
  InputDataDefinitionsApi
} from '@/apps/timeSeriesViewer';

export class InputDataDefinitionsRepository {
  constructor(
    private _inputDataDefinitionsApi: InputDataDefinitionsApi,
    private _stateConverter: RawDataDefinitionChooserStateToSearchConverter,
    private _inputConverter: InputDataDefinitionConverter,
    private _inputFactory: InputDataDefinitionFactory
  ) {}

  getSearchParameters(state: InputDataDefinitionChooserState): SearchParameters {
    const dto = this._stateConverter.stateToSearchDto(state);
    return new SearchParameters(state, dto);
  }

  async search(parameters: SearchParameters, cancellation: CancellationToken): Promise<Result<SearchParameters, string>> {
    const result = await this._inputDataDefinitionsApi.search(parameters.dto, cancellation);
    return result.mapSuccess((dto) => {
      const stateDto = this._stateConverter.updateState(parameters.state, dto);
      return Result.success(new SearchParameters(parameters.state, stateDto));
    });
  }

  private async getCollectionFromIds(metrics: Metric[]): Promise<Result<InputDataDefinitionResult[], string>> {
    const inputIdDtos = metrics.map((id) => this._inputConverter.idToDto(id));
    const inputDtos = await this._inputDataDefinitionsApi.get(inputIdDtos);
    if (inputDtos.isError) return Result.error(inputDtos.error);
    const partialInputs = inputDtos.value.map((d) => ({ id: this._inputConverter.idToModel(d.id), dto: d }));
    return Result.success(
      metrics.map((inputId) => {
        const matchingPartialInput = _.find(partialInputs, (partialInput) => partialInput.id.equals(inputId));
        if (matchingPartialInput === undefined) return new ErroredInputDataDefinitionResult(inputId, `Data set with id '${inputId.toUniqueString()}' no longer exists or has become invalid`);
        const input = this._inputFactory.create(matchingPartialInput.id);
        this._inputConverter.updateModel(input, matchingPartialInput.dto);
        return new SuccessfulInputDataDefinitionResult(input);
      })
    );
  }

  getCollectionFromIdDtos(inputIdDtos: IInputMetricDto[]): Promise<Result<InputDataDefinitionResult[], string>> {
    const inputIds = inputIdDtos.map((i) => this._inputConverter.idToModel(i));
    return this.getCollectionFromIds(inputIds);
  }

  async getFromId(inputId: Metric): Promise<Result<InputDataDefinition, string>> {
    const inputs = await this.getCollectionFromIds([inputId]);
    if (inputs.isError) return Result.error(inputs.error);
    const input = inputs.value[0];
    return input.isSuccess ? Result.success((input as SuccessfulInputDataDefinitionResult).value) : Result.error((input as ErroredInputDataDefinitionResult).errorMessage);
  }
}

export class SearchParameters {
  constructor(
    public state: InputDataDefinitionChooserState,
    public dto: ISearchRequestDto
  ) {}

  equals(other: SearchParameters | undefined): boolean {
    if (other === undefined) return false;
    return JSON.stringify(this.dto) === JSON.stringify(other.dto);
  }
}
