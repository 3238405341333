import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import '@/gr/common/knockout/bindings/textAnimate';
import '@/gr/common/knockout/bindings/drop';
import { AppDateTimes, Navigation, PolicyAuthorisationsService, RelativeDuration, SaveDialog, SavingIndicator, TemplateDialog, Trend, TrendServices, untitledTrendName } from '@/apps/timeSeriesViewer';
import './selectAllBinding';
import { KnockoutVueComponent } from '@/common/knockout-vue-binding';
import { GrTimeChooser } from '@/components';
import { AppDateTimesDto } from '@/repositories';
import { relativeDurationUnits } from '../../models';
import html from './trendPropertiesBar.html';
import { ComponentOptions } from 'vue/types/options';
import Vue from 'vue';

export class Component {
  grTimeChooser: KnockoutVueComponent;
  grTimeChooserProps: ComponentOptions<Vue>;

  trendName;
  untitledTrendName = untitledTrendName;
  trendNameSelectAll = ko.observable(false);
  isFavourited: KnockoutComputed<boolean>;

  navigation;
  savingIndicator;
  templateDialog;
  saveDialog;

  constructor(private readonly _args: Args) {
    this.grTimeChooserProps = {
      propsData: {
        value: {
          fixedStart: this._args.dateTimes.fixedStart(),
          fixedEnd: this._args.dateTimes.fixedEnd(),
          isRelative: this._args.dateTimes.isRelative(),
          lookBack: { count: this._args.dateTimes.lookBack().count, unitId: this._args.dateTimes.lookBack().unit.id },
          lookForward: { count: this._args.dateTimes.lookForward().count, unitId: this._args.dateTimes.lookForward().unit.id }
        },
        isAbleToLookForward: this._args.policyAuthorisations.isAuthorised('Forecasts')(),
        save: (dateTime: AppDateTimesDto): void => {
          this.saveDateTime(dateTime);
        }
      }
    };

    this.trendName = this._args.trendName;
    this.isFavourited = ko.pureComputed(() => this._args.trendName() != null);
    this.navigation = this._args.navigationComponentArgsFactory.create();
    this.savingIndicator = this._args.savingIndicatorComponentArgsFactory.create();
    this.templateDialog = this._args.templateDialogArgsFactory.create();
    this.saveDialog = this._args.saveDialogArgsFactory.create();
    this.grTimeChooser = new KnockoutVueComponent(GrTimeChooser, this.grTimeChooserProps);
  }

  private saveDateTime(dateTime: AppDateTimesDto) {
    if (dateTime.fixedStart && dateTime.fixedStart !== this._args.dateTimes.fixedStart()) this._args.dateTimes.fixedStart(dateTime.fixedStart);
    if (dateTime.fixedEnd && dateTime.fixedEnd != this._args.dateTimes.fixedEnd()) this._args.dateTimes.fixedEnd(dateTime.fixedEnd);
    if (dateTime.isRelative != this._args.dateTimes.isRelative()) this._args.dateTimes.isRelative(dateTime.isRelative);
    if (dateTime.lookBack) {
      const lookBackRelativeDuration = new RelativeDuration(dateTime.lookBack.count, relativeDurationUnits[dateTime.lookBack.unitId]);
      if (lookBackRelativeDuration != this._args.dateTimes.lookBack()) {
        this._args.dateTimes.lookBack(lookBackRelativeDuration);
      }
    }
    if (dateTime.lookForward) {
      const lookForwardRelativeDuration = new RelativeDuration(dateTime.lookForward.count, relativeDurationUnits[dateTime.lookForward.unitId]);
      if (lookForwardRelativeDuration != this._args.dateTimes.lookForward()) {
        this._args.dateTimes.lookForward(lookForwardRelativeDuration);
      }
    }
  }

  save(): void {
    this.saveDialog.show();
  }

  createTemplate(): void {
    this.templateDialog.show();
  }

  editTrendName(): void {
    this.trendNameSelectAll(true);
  }
}

export class Args {
  constructor(
    public navigationComponentArgsFactory: Navigation.ArgsFactory,
    public savingIndicatorComponentArgsFactory: SavingIndicator.ArgsFactory,
    public templateDialogArgsFactory: TemplateDialog.ArgsFactory,
    public saveDialogArgsFactory: SaveDialog.ArgsFactory,
    public trendName: KnockoutComputed<string | null | undefined>,
    public policyAuthorisations: PolicyAuthorisationsService,
    public dateTimes: AppDateTimes
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(
      new Navigation.ArgsFactory(this._trend, this._services),
      new SavingIndicator.ArgsFactory(this._trend, this._services),
      new TemplateDialog.ArgsFactory(this._trend, this._services),
      new SaveDialog.ArgsFactory(this._trend, this._services),
      this._trend.title,
      this._services.policyAuthorisations,
      this._trend.appDateTimes
    );
  }
}

defineComponent(() => Component, 'trendPropertiesBar', html);
require('./trendPropertiesBar.less');
