import moment from 'moment';

export class StopWatch {
  private _start!: moment.Moment;

  start(): StopWatch {
    this._start = moment();
    return this;
  }

  timeElapsedInSeconds() {
    return this._start ? moment().diff(this._start) / 1000 : 0;
  }
}
