import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { Trend, OutputChannelContainer } from '@/apps/timeSeriesViewer/models';
import { OutputChannelComponentFactory, ChartArgsFactory, OutputChannelSelector, TrendServices, File, Table, Api } from '@/apps/timeSeriesViewer';

export class OutputChannelContainerArgs {
  constructor(
    public outputChannelContainer: OutputChannelContainer,
    public componentFactory: OutputChannelComponentFactory,
    public outputChannelSelectorArgsFactory: OutputChannelSelector.ArgsFactory
  ) {}
}

export class OutputChannelContainerArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(outputChannelContainer: OutputChannelContainer): OutputChannelContainerArgs {
    const chartArgsFactory = new ChartArgsFactory(this._trend, this._services);
    const tableArgsFactory = new Table.ArgsFactory(this._trend, this._services);
    const fileArgsFactory = new File.ArgsFactory(this._trend, this._services);
    const apiArgsFactory = new Api.ArgsFactory();
    return new OutputChannelContainerArgs(
      outputChannelContainer,
      new OutputChannelComponentFactory(chartArgsFactory, tableArgsFactory, fileArgsFactory, apiArgsFactory),
      new OutputChannelSelector.ArgsFactory(this._trend, this._services)
    );
  }
}

export class OutputChannelContainerComponent {
  outputChannelSelector;

  hasSelectedChannelType = ko.pureComputed(() => this._args.outputChannelContainer.outputChannel().channelType !== 'NEW');

  component = ko.pureComputed(() => {
    const outputChannel = this._args.outputChannelContainer.outputChannel();
    return this._args.componentFactory.create(outputChannel);
  });
  constructor(private _args: OutputChannelContainerArgs) {
    this.outputChannelSelector = this._args.outputChannelSelectorArgsFactory.create(this._args.outputChannelContainer.outputChannel);
  }
}

import html from './outputChannelContainer.html';
defineComponent(() => OutputChannelContainerComponent, 'outputChannelContainer', html);
require('./outputChannelContainer.less');
