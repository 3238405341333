import _ from 'lodash';
import {
  IChannelStateConverter,
  ChartState,
  ChartSeries,
  ChartAxis,
  IChartAxisDto,
  IUnitsOfMeasureDto,
  UnitsOfMeasure,
  ChartStateFactory,
  IChartDto,
  ChartSeriesFactory,
  ConfiguredDataDefinition,
  IChartSeriesDto,
  ChartAxesBuilder,
  TrendSummaryForChannels,
  nilToNull,
  TrendServices
} from '@/apps/timeSeriesViewer';
import { rejectNull } from '@/gr/common/utils/array';

export class ChartStateConverter implements IChannelStateConverter<ChartState, IChartDto> {
  constructor(
    private _chartStateFactory: ChartStateFactory,
    private _chartSeriesFactory: ChartSeriesFactory
  ) {}

  toModel(dto: IChartDto, trendSummary: TrendSummaryForChannels): ChartState {
    const configuredDataDefinitions = trendSummary.dataDefinition().configuredDataDefinitions;
    const chartAxesBuilder = new ChartAxesBuilder([]);
    _.forEach(dto.axes, (dto) => this.chartAxisToModel(dto, chartAxesBuilder));
    const series = rejectNull(_.map(dto.series, (dto) => this.chartSeriesToModel(dto, chartAxesBuilder, configuredDataDefinitions)));
    const model = this._chartStateFactory.create(trendSummary, chartAxesBuilder.build(), series);
    model.theme(nilToNull(dto.theme));
    model.hasTitle(dto.hasTitle);
    model.hasLegend(dto.hasLegend);
    model.hasCustomLegend(dto.hasCustomLegend);
    model.hasTooltips(dto.hasTooltips);
    model.showTooltipsForNextAvailable(dto.showTooltipsForNextAvailable);
    model.hasScrollBar(dto.hasScrollBar);
    model.alignZeroOnYAxes(dto.alignZeroOnYAxes);
    return model;
  }

  toDto(model: ChartState): IChartDto {
    return {
      theme: model.theme(),
      hasTitle: model.hasTitle(),
      hasLegend: model.hasLegend(),
      hasCustomLegend: model.hasCustomLegend(),
      hasTooltips: model.hasTooltips(),
      showTooltipsForNextAvailable: model.showTooltipsForNextAvailable(),
      hasScrollBar: model.hasScrollBar(),
      series: _.map(model.rawSeries, (s) => this.chartSeriesToDto(s)),
      axes: _.map(model.rawAxes, (s) => this.chartAxisToDto(s)),
      alignZeroOnYAxes: model.alignZeroOnYAxes()
    };
  }

  private chartSeriesToModel(dto: IChartSeriesDto, chartAxesBuilder: ChartAxesBuilder, configuredDataDefinitions: ConfiguredDataDefinition[]): ChartSeries | null {
    const configuredDataDefinition = _.find(configuredDataDefinitions, (c) => c.id === dto.configuredDataDefinitionId);
    // Can be undefined in the case where a user removes a configured data definition but never re-renders the chart
    if (configuredDataDefinition === undefined) return null;
    const model = this._chartSeriesFactory.createFrom(configuredDataDefinition, nilToNull(dto.chartAxisId), chartAxesBuilder);
    model.bullet(nilToNull(dto.bullet));
    model.color(dto.color);
    model.dashLength(nilToNull(dto.dashLength));
    model.isShown(dto.isShown);
    model.type(dto.type);
    model.noStepRisers(nilToNull(dto.noStepRisers));
    model.stepDirection(nilToNull(dto.stepDirection));
    model.periodSpan(nilToNull(dto.periodSpan));
    model.columnWidth(nilToNull(dto.columnWidth));
    model.showInLegend(dto.showInLegend);
    model.isStacked(dto.isStacked);
    model.isStepped(dto.isStepped);
    model.lineThickness(nilToNull(dto.lineThickness));
    return model;
  }

  private chartSeriesToDto(model: ChartSeries): IChartSeriesDto {
    const axis = model.axis();
    return {
      chartAxisId: axis ? axis.id : null,
      bullet: model.bullet(),
      color: model.color(),
      dashLength: model.dashLength(),
      isShown: model.isShown(),
      type: model.type(),
      configuredDataDefinitionId: model.configuredDataDefinition.id,
      noStepRisers: model.noStepRisers(),
      stepDirection: model.stepDirection(),
      periodSpan: model.periodSpan(),
      columnWidth: model.columnWidth(),
      lineThickness: model.lineThickness(),
      showInLegend: model.showInLegend(),
      isStacked: model.isStacked(),
      isStepped: model.isStepped()
    };
  }

  private chartAxisToModel(dto: IChartAxisDto, chartAxesBuilder: ChartAxesBuilder): ChartAxis {
    const model = chartAxesBuilder.addAxis(nilToNull(dto.id), this.unitOfMeasureToModel(dto.unitsOfMeasure), dto.position);
    model.title(nilToNull(dto.title));
    model.axisLogarithmic(dto.isLogarithmic);
    model.axisFixed(dto.isAxisFixed);
    model.max(dto.maximum);
    model.min(dto.minimum);
    return model;
  }

  private chartAxisToDto(model: ChartAxis): IChartAxisDto {
    return {
      id: model.id,
      title: model.title(),
      position: model.position(),
      unitsOfMeasure: this.unitOfMeasureToDto(model.unitsOfMeasure()),
      isLogarithmic: model.axisLogarithmic(),
      isAxisFixed: model.axisFixed(),
      minimum: model.min(),
      maximum: model.max()
    };
  }

  private unitOfMeasureToModel(dto: IUnitsOfMeasureDto) {
    return new UnitsOfMeasure(dto.displayName, dto.id, dto.valueFormatString, dto.decimalPlaces);
  }

  private unitOfMeasureToDto(dto: UnitsOfMeasure): IUnitsOfMeasureDto {
    return {
      displayName: dto.displayName,
      id: dto.id,
      valueFormatString: dto.valueFormatString,
      decimalPlaces: dto.decimalPlaces
    };
  }
}

export class ChartStateConverterFactory {
  constructor(private _services: TrendServices) {}

  create(): ChartStateConverter {
    return new ChartStateConverter(new ChartStateFactory(this._services), new ChartSeriesFactory(this._services.colourProvider));
  }
}
