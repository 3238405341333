











































































































import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';
import moment from 'moment';
import { GrDateTimePicker } from '@/components';
import { AppDateTimesDto, IAppDateTimesDto, RelativeDurationDto, RelativeDurationUnitId } from '@/repositories';
import { RelativeDuration, relativeDurationUnits } from '../apps/timeSeriesViewer';

@Component({
  components: { GrDateTimePicker }
})
export default class GrTimeChooser extends Vue {
  @Prop({
    type: Object,
    default: () => {
      return {
        fixedStart: moment().add(-1, 'day'),
        fixedEnd: moment().add(1, 'hour'),
        lookBack: { count: 1, unitId: RelativeDurationUnitId.Day },
        lookForward: { count: 0, unitId: RelativeDurationUnitId.Day },
        isRelative: true
      };
    }
  })
  readonly value!: IAppDateTimesDto;

  // this localCopy is needed as vue component is not reactive to prop value passed from knockout.
  private unconfirmedAppDateTimes = this.cloneAppDateTimesDto(this.value);

  private revertAppDateTimes = this.cloneAppDateTimesDto(this.value);

  @Prop({ default: false })
  isAbleToLookForward!: boolean;

  @Prop()
  readonly save!: (dateTime: IAppDateTimesDto) => void;

  created(): void {
    this.propertyBarDisplay = this.populatePropertyBarDisplay();
  }

  // -- For Form --
  private dialog = false;

  @Watch('this.unconfirmedAppDateTimes.lookBack.count')
  private get lookBackItems() {
    const plural = this.unconfirmedAppDateTimes.lookBack?.count == 1 ? '' : 's';
    return Object.entries(RelativeDurationUnitId).map(([key, value]) => ({
      text: `${key.replace(/([A-Z])/g, ' $1').trim()}${plural}`,
      value: value
    }));
  }

  @Watch('this.unconfirmedAppDateTimes.lookForward.count')
  private get lookForwardItems() {
    const plural = this.unconfirmedAppDateTimes.lookForward?.count == 1 ? '' : 's';
    return Object.entries(RelativeDurationUnitId).map(([key, value]) => ({
      text: `${key.replace(/([A-Z])/g, ' $1').trim()}${plural}`,
      value: value
    }));
  }

  private formIsValid() {
    return (
      (this.unconfirmedAppDateTimes.isRelative && (this.unconfirmedAppDateTimes.lookBack?.count != 0 || this.unconfirmedAppDateTimes.lookForward?.count != 0)) ||
      (!this.unconfirmedAppDateTimes.isRelative && moment(this.unconfirmedAppDateTimes.fixedEnd) > moment(this.unconfirmedAppDateTimes.fixedStart))
    );
  }

  private cancel() {
    // console.log('cancel');
    this.unconfirmedAppDateTimes = this.cloneAppDateTimesDto(this.revertAppDateTimes);
    this.dialog = false;
  }

  private apply() {
    // console.log('apply');
    if (this.formIsValid()) {
      if (!this.isAbleToLookForward) {
        this.unconfirmedAppDateTimes.lookForward = new RelativeDurationDto({
          count: 0,
          unitId: RelativeDurationUnitId.Minute
        });
      }
      this.revertAppDateTimes = this.cloneAppDateTimesDto(this.unconfirmedAppDateTimes);
      this.save(this.unconfirmedAppDateTimes);
      this.propertyBarDisplay = this.populatePropertyBarDisplay();
      this.dialog = false;
    } else {
      this.dialog = true;
    }
  }

  // -- Getters and Setters for sub components as can't use complex type of moment --
  private get fixedStartAsString() {
    return this.unconfirmedAppDateTimes.fixedStart?.format() ?? '';
  }

  private set fixedStartAsString(value: string) {
    this.unconfirmedAppDateTimes.fixedStart = moment(value);
  }

  private get fixedEndAsString() {
    return this.unconfirmedAppDateTimes.fixedEnd?.format() ?? '';
  }

  private set fixedEndAsString(value: string) {
    this.unconfirmedAppDateTimes.fixedEnd = moment(value);
  }

  // --- For Display ----
  private UTC_OFFSET = 10;
  private propertyBarDisplay = { to: 'loading...', from: 'loading...' };

  private populatePropertyBarDisplay() {
    return { from: this.propertyBarFromOrBack(), to: this.propertyBarToOrForward() };
  }

  private propertyBarFromOrBack(): string {
    return this.unconfirmedAppDateTimes.isRelative
      ? this.unconfirmedAppDateTimes.lookBack?.count == 0
        ? 'now'
        : `${this.unconfirmedAppDateTimes.lookBack?.count} ${this.unconfirmedAppDateTimes.lookBack?.unitId}${this.unconfirmedAppDateTimes.lookBack?.count == 1 ? '' : 's'} ago`
            .replace(/([A-Z])/g, ' $1')
            .trim()
      : this.DateTimeInDisplayFormat(this.unconfirmedAppDateTimes.fixedStart);
  }

  private propertyBarToOrForward(): string {
    return this.unconfirmedAppDateTimes.isRelative
      ? this.unconfirmedAppDateTimes.lookForward?.count == 0
        ? 'now'
        : `${this.unconfirmedAppDateTimes.lookForward?.count} ${this.unconfirmedAppDateTimes.lookForward?.unitId}${this.unconfirmedAppDateTimes.lookForward?.count == 1 ? '' : 's'} ahead`
            .replace(/([A-Z])/g, ' $1')
            .trim()
      : this.DateTimeInDisplayFormat(this.unconfirmedAppDateTimes.fixedEnd);
  }

  private DateTimeInDisplayFormat(dateTime: string | moment.Moment | undefined) {
    return moment(dateTime).utcOffset(this.UTC_OFFSET).calendar(moment().utcOffset(this.UTC_OFFSET), {
      sameElse: 'Do MMM YYYY HH:mm'
    });

    // return moment(dateTime)
    //     .utcOffset(this.UTC_OFFSET)
    //     .format('DD/MM/YY HH:mm');
  }

  private start(): moment.Moment | undefined {
    if (this.unconfirmedAppDateTimes.isRelative) {
      if (this.unconfirmedAppDateTimes.lookBack) {
        const back = new RelativeDuration(this.unconfirmedAppDateTimes.lookBack?.count ?? 0, relativeDurationUnits[this.unconfirmedAppDateTimes.lookBack?.unitId]);
        return back.from(moment(), 'back');
      }
    } else {
      return this.unconfirmedAppDateTimes.fixedStart;
    }
  }

  private end(): moment.Moment | undefined {
    if (this.unconfirmedAppDateTimes.isRelative) {
      if (this.unconfirmedAppDateTimes.lookForward && this.unconfirmedAppDateTimes.lookBack) {
        const forward = new RelativeDuration(this.unconfirmedAppDateTimes.lookForward?.count ?? 0, relativeDurationUnits[this.unconfirmedAppDateTimes.lookForward?.unitId]);
        const backUnit = relativeDurationUnits[this.unconfirmedAppDateTimes.lookBack?.unitId];
        return forward.from(backUnit.addTo(moment(), 0), 'forward');
      }
    } else {
      return this.unconfirmedAppDateTimes.fixedEnd;
    }
  }

  private cloneAppDateTimesDto(appDateTimesDto: IAppDateTimesDto): IAppDateTimesDto {
    return new AppDateTimesDto({
      fixedStart: appDateTimesDto.fixedStart,
      fixedEnd: appDateTimesDto.fixedEnd,
      lookBack: { ...appDateTimesDto.lookBack } as RelativeDurationDto,
      lookForward: { ...appDateTimesDto.lookForward } as RelativeDurationDto,
      isRelative: appDateTimesDto.isRelative,
      isLive: false, // note this is not used so is just filler here
      utcOffsetDefinition: undefined // note this is not used so is just filler here
    });
  }
}
