import { IDisposable } from './disposable';

export class CancellationTokenSource implements IDisposable {
  readonly token = new CancellationToken(this);

  private _isCancelled = false;
  private _cancellationCallbacks: (() => void)[] = [];

  throwIfCancelled() {
    if (this.isCancelled) {
      throw this.getCancellationError();
    }
  }

  getCancellationError() {
    const error = Error('Task cancelled') as CancellationError;
    error.isCancellationError = true;
    return error;
  }

  get isCancelled() {
    return this._isCancelled;
  }

  register(cancellationCallback: () => void) {
    if (this._isCancelled) {
      cancellationCallback();
    } else {
      this._cancellationCallbacks.push(cancellationCallback);
    }
  }

  cancel() {
    if (!this._isCancelled) {
      this._isCancelled = true;
      this._cancellationCallbacks.forEach((c) => c());
    }
    return this;
  }

  dispose(): void {
    this.cancel();
  }
}

export class CancellationToken {
  constructor(private _source: CancellationTokenSource) {}

  private _isCancelled = false;

  throwIfCancelled() {
    this._source.throwIfCancelled();
  }

  getCancellationError() {
    return this._source.getCancellationError();
  }

  get isCancelled() {
    return this._source.isCancelled;
  }

  register(cancellationCallback: () => void) {
    this._source.register(cancellationCallback);
  }

  static isCancellationError(error?: Error | null) {
    return error && (error as CancellationError).isCancellationError !== undefined;
  }

  static get cancelled() {
    return new CancellationTokenSource().cancel().token;
  }
  static get none() {
    return new CancellationTokenSource().token;
  }
}

type CancellationError = Error & { isCancellationError: true };
