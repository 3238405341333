import ko from 'knockout';
import { InputDataDefinition, CalculatedMetric, RawMetric, AggregateMetric, Metric } from '@/apps/timeSeriesViewer';
import { never } from '@/gr/common/utils/never';

export class InputDataDefinitionFactory {
  duplicate(input: InputDataDefinition): InputDataDefinition {
    const id = input.id;

    if (id instanceof RawMetric) {
      return input;
    } else if (id instanceof AggregateMetric) {
      return input;
    } else if (id instanceof CalculatedMetric) {
      return new InputDataDefinition(new CalculatedMetric(ko.observable(id.expression())));
    } else {
      return never(id);
    }
  }

  create(id: Metric): InputDataDefinition {
    return new InputDataDefinition(id);
  }
}
