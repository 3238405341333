import moment from 'moment';
import ko from 'knockout';
import { UtcOffsetDefinition, relativeDurationUnits, RelativeDuration, Clock, AppDateTimesSnapshot } from '@/apps/timeSeriesViewer';

export interface IAppDateTimes {
  now(): moment.Moment;
  start(): moment.Moment;
  end(): moment.Moment;
  fixedStart(): moment.Moment;
  fixedEnd(): moment.Moment;
  lookBack(): RelativeDuration;
  lookForward(): RelativeDuration;
  isRelative(): boolean;
  utcOffsetDefinition(): UtcOffsetDefinition;
}

export class AppDateTimes implements IAppDateTimes {
  utcOffsetDefinition = ko.observable<UtcOffsetDefinition>({
    type: 'Offset',
    fixedOffsetInMinutes: 600 // +10:00 NEM Time
  });

  now: KnockoutObservable<moment.Moment> = ko.observable(this.getClock());

  fixedStart = ko.observable(this.now().clone().startOf('day').subtract(7, 'days'));
  fixedEnd = ko.observable(this.now().clone().startOf('day').add(1, 'day'));

  lookBack = ko.observable(new RelativeDuration(1, relativeDurationUnits.day));
  lookForward = ko.observable(new RelativeDuration(1, relativeDurationUnits.day));

  isRelative = ko.observable(true);

  start(): moment.Moment {
    if (this.isRelative()) {
      return this.lookBack().from(this.now(), 'back');
    } else {
      return this.fixedStart();
    }
  }

  end(): moment.Moment {
    if (this.isRelative()) {
      return this.lookForward().from(this.lookBack().unit.addTo(this.now(), 0), 'forward');
    } else {
      return this.fixedEnd();
    }
  }

  snapshot(): AppDateTimesSnapshot {
    return new AppDateTimesSnapshot(this.now(), this.start(), this.end(), this.fixedStart(), this.fixedEnd(), this.lookBack(), this.lookForward(), this.isRelative(), this.utcOffsetDefinition());
  }

  copyTo(other: AppDateTimes): void {
    if (other === this) return;
    other.now(this.now());
    other.fixedStart(this.fixedStart());
    other.fixedEnd(this.fixedEnd());
    other.lookBack(this.lookBack());
    other.lookForward(this.lookForward());
    other.isRelative(this.isRelative());
    other.utcOffsetDefinition(this.utcOffsetDefinition());
  }

  clone(): AppDateTimes {
    const other = new AppDateTimes();
    this.copyTo(other);
    return other;
  }

  private getClock() {
    return Clock.from(this.utcOffsetDefinition).now;
  }
}
