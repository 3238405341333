import { cloneCss, addIETransitionFixTo } from '../../iframes/iframeHelper';

export interface IModalIframeServiceFactory {
  create(): Promise<IModalIframeService>;
}

export interface IModalIframeService {
  show(): Promise<void>;
  hide(): Promise<void>;
  getOpenModalsCount(): number;

  modalWindow: Window;
}

export abstract class ModalIframeService implements IModalIframeService {
  private _openModalsCount: number;

  constructor(public modalWindow: Window) {
    this._openModalsCount = 0;

    cloneCss(window, this.modalWindow);
    addIETransitionFixTo(this.modalWindow);
  }

  show() {
    this._openModalsCount++;
    if (this._openModalsCount === 1) return this.showInternal();
    else return Promise.resolve();
  }

  protected abstract showInternal(): Promise<void>;

  hide() {
    this._openModalsCount--;
    if (this._openModalsCount === 0) return this.hideInternal();
    else return Promise.resolve();
  }

  protected abstract hideInternal(): Promise<void>;

  getOpenModalsCount() {
    return this._openModalsCount;
  }
}
