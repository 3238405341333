import ko from 'knockout';
import { IInputMetricDto, InputDataDefinition, IInputDataDefinitionDto, RawMetric, AggregateMetric, CalculatedMetric, Metric } from '@/apps/timeSeriesViewer';

export class InputDataDefinitionConverter {
  idToModel(dto: IInputMetricDto): Metric {
    switch (dto.type) {
      case 'Raw':
        return new RawMetric(dto.id);
      case 'Aggregate':
        return new AggregateMetric(dto.entityTypeId, dto.propertyId, dto.facets);
      case 'Calculated':
        return new CalculatedMetric(ko.observable(dto.expression));
    }
  }

  idToDto(model: Metric): IInputMetricDto {
    if (model instanceof RawMetric) {
      return { type: 'Raw', id: model.id };
    } else if (model instanceof AggregateMetric) {
      return { type: 'Aggregate', entityTypeId: model.entityTypeId, propertyId: model.propertyId, facets: model.facets };
    } else {
      return { type: 'Calculated', expression: model.expression() };
    }
  }

  updateModel(model: InputDataDefinition, dto: IInputDataDefinitionDto): void {
    model.name(dto.name);
  }
}
