import ko from 'knockout';
import _ from 'lodash';
import kx from '@/gr/common/knockout/extended';
import * as log from '@/gr/common/log';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { TableState } from '@/apps/timeSeriesViewer/models';
import { TrendDataDefinition, TrendDataRepository, Trend, TrendServices, Messages, TableOutputGridBinding, DateTimeFormatter, Download, LoadingComponentArgs } from '@/apps/timeSeriesViewer';
import '@/apps/timeSeriesViewer/components/delayRenderingComponent';

export class Component {
  private _disposable = new CompositeDisposable();

  tableOutputGridBinding;
  isLoading = ko.observable(true);
  messages;
  title: KnockoutComputed<string | null>;
  download;
  loadingArgs = new LoadingComponentArgs();

  constructor(private _args: Args) {
    this.tableOutputGridBinding = this._args.tableOutputGridBindingArgsFactory.create(this._args.state);
    this.messages = this._args.messagesArgsFactory.create();

    this.title = ko.pureComputed(() => {
      const data = this._args.state.trendData();
      return data ? `Data as at: ${DateTimeFormatter.dateTime(data.appDateTimes.now(), data.utcOffset).all}` : null;
    });

    this.download = this._args.downloadComponentFactory.create(() => this._args.trendDataDefinition(), this.messages);

    this._disposable.add(
      ko.computed(() => {
        const trendData = this._args.state.trendData();
        _.forEach(trendData?.messages, (message) => this.messages.add(message.html, message.type));
        const length = trendData?.dataPoints.length ?? 0;
        if (length > 1000) {
          this.messages.add('Your table has been limited to display only the first 1000 data points.', 'warning');
        }
      })
    );

    this._disposable.add(
      ko.computed(async () => {
        this.messages.clear();
        this.isLoading(true);
        try {
          await this._args.trendDataRepository.fillTrendData(this._args.trendDataDefinition(), this._args.state.trendData, this.loadingArgs.description, this.isLoading);
        } catch (error) {
          this.messages.add(`Failed to load data for table : ${error}`);
          log.errorEx(error, 'Failed to load data for table');
        } finally {
          this.isLoading(false);
        }
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }
}

export class Args {
  constructor(
    public state: TableState,
    public trendDataDefinition: kx.ReadOnlyObservable<TrendDataDefinition>,
    public trendDataRepository: TrendDataRepository,
    public messagesArgsFactory: Messages.ArgsFactory,
    public tableOutputGridBindingArgsFactory: TableOutputGridBinding.ArgsFactory,
    readonly downloadComponentFactory: Download.ArgsFactory
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(state: TableState): Args {
    return new Args(
      state,
      this._trend.dataDefinition,
      this._services.trendDataRepository,
      new Messages.ArgsFactory(),
      new TableOutputGridBinding.ArgsFactory(),
      new Download.ArgsFactory(this._services)
    );
  }
}

import html from './tableComponent.html';
defineComponent(() => Component, 'table', html);
require('./tableComponent.less');
