import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { TrendServices, CsvDownloadService, TrendDataDefinition, Messages, LoadingComponentArgs } from '@/apps/timeSeriesViewer';
import '@/gr/common/knockout/bindings/modal';

export class Component {
  constructor(private readonly _args: Args) {
    this.loadingArgs.description('');
  }

  async downloadCsv(): Promise<void> {
    this.isLoading(true);
    await this._args.csvDownloadService.downloadAsCsv(this._args.getTrendDataDefinition(), this._args.messages, ko.observable(''), this.isLoading);
    this.isLoading(false);
  }

  isLoading = ko.observable(false);
  loadingArgs = new LoadingComponentArgs();
}

export class Args {
  constructor(
    readonly csvDownloadService: CsvDownloadService,
    readonly getTrendDataDefinition: () => TrendDataDefinition,
    readonly messages: Messages.Args
  ) {}
}

export class ArgsFactory {
  constructor(private _trendServices: TrendServices) {}

  create(getTrendDataDefinition: () => TrendDataDefinition, messages: Messages.Args): Args {
    return new Args(this._trendServices.csvDownloadService(), getTrendDataDefinition, messages);
  }
}

import html from './downloadComponent.html';
defineComponent(() => Component, 'download', html);
require('./downloadComponent.less');
