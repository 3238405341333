import { CompositeDisposable } from '@/gr/common/disposable';
import {
  ChartAxis,
  ChartSeries,
  IOutputChannelStateFactory,
  TrendData,
  TrendDataDefinition,
  ChartAxesBuilder,
  ChartSeriesFactory,
  // TrendServices,
  TrendSummaryForChannels,
  ICompleteChartSeries,
  ChartTheme,
  TrendServices
} from '@/apps/timeSeriesViewer';
import moment from 'moment';
import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import { UnitsOfMeasure } from '../model';

export const newAxisOption = { id: 'new', description: ko.pureComputed(() => 'to a new axis') };

export class ChartState {
  private _disposable = new CompositeDisposable();

  trendData = ko.observable<TrendData | null>(null);
  hasTitle = ko.observable(true);
  hasCustomLegend = ko.observable(false);
  hasLegend = ko.observable<boolean>(false);
  hasScrollBar = ko.observable<boolean>(false);
  hasTooltips = ko.observable<boolean>(true);
  showTooltipsForNextAvailable = ko.observable<boolean>(true);
  theme = ko.observable<ChartTheme | null>('light');
  alignZeroOnYAxes = ko.observable<boolean>(false);
  minPeriod = moment.duration(1, 'minute');

  private _seriesAndAxes = ko.computed(() => {
    const trendData = this.trendData();
    if (!trendData) return { axes: [], series: [] };

    const completeConfiguredDataDefinitions = trendData.configuredDataDefinitions.filter((c) => c.isIncludedInOutputChannels());
    const chartAxesBuilder = new ChartAxesBuilder(this.rawAxes);
    const series = this._chartSeriesFactory.createCollectionFrom(completeConfiguredDataDefinitions, this.rawSeries, chartAxesBuilder);
    this.rawSeries = series;
    this.rawAxes = chartAxesBuilder.removeUnusedAxes(series).build();
    return { axes: this.rawAxes, series: series };
  });

  series: kx.ReadOnlyObservable<ICompleteChartSeries[]> = ko.pureComputed(() => this._seriesAndAxes().series);
  axes: kx.ReadOnlyObservable<ChartAxis[]> = ko.pureComputed(() => this._seriesAndAxes().axes);

  moveSeriesToAxis = (seriesId: string, axisId: string): void => {
    const series = this.rawSeries.find((x) => x.id === seriesId);
    if (axisId === newAxisOption.id) {
      const unitOfMeasure = series?.configuredDataDefinition.unitsOfMeasure;
      const position = series?.axis()?.position() === 'left' ? 'right' : 'left';
      const chartAxesBuilder = new ChartAxesBuilder(this.rawAxes);
      const newAxis = chartAxesBuilder.addAxis(null, unitOfMeasure as UnitsOfMeasure, position);
      this.rawAxes = chartAxesBuilder.build();
      series?.axis(newAxis);
    } else {
      const axis = this.rawAxes.find((s) => s.id === axisId);
      series?.axis(axis ?? null);
    }
  };

  hasOptionsPanel: KnockoutComputed<boolean>;
  hasDownloadButton: KnockoutComputed<boolean>;

  constructor(
    public dataDefinition: kx.ReadOnlyObservable<TrendDataDefinition>,
    public title: KnockoutComputed<string | null | undefined>,
    private _isTrendReadOnly: kx.ReadOnlyObservable<boolean | undefined>,
    private _chartSeriesFactory: ChartSeriesFactory,
    public rawAxes: ChartAxis[],
    public rawSeries: ChartSeries[],
    private _isTrendEmbeddable: kx.ReadOnlyObservable<boolean>
  ) {
    this._disposable.add(this._seriesAndAxes);
    this.hasOptionsPanel = ko.pureComputed(() => !this._isTrendReadOnly());
    this.hasDownloadButton = ko.pureComputed(() => !this._isTrendEmbeddable());
  }

  dispose(): void {
    this._disposable.dispose();
  }
}

export class ChartStateFactory implements IOutputChannelStateFactory {
  constructor(private _services: TrendServices) {}

  create(trendSummary: TrendSummaryForChannels, axes: ChartAxis[] = [], series: ChartSeries[] = []): ChartState {
    return new ChartState(
      trendSummary.dataDefinition,
      ko.pureComputed(() => trendSummary.title()).extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } }),
      trendSummary.isTrendReadOnly,
      new ChartSeriesFactory(this._services.colourProvider),
      axes,
      series,
      trendSummary.isTrendEmbeddable
    );
  }
}
