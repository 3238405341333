import * as log from '../log';

export function copyDataToClipboard(data: string) {
  executeOnTempElement((element) => {
    element.value = data;
    element.select();
    element.focus();
    return document.execCommand('copy');
  });
}

function executeOnTempElement(callback: (element: HTMLTextAreaElement) => any) {
  const textArea = document.createElement('textarea');
  try {
    textArea.style.width = '1px';
    textArea.style.height = '1px';
    textArea.style.top = '0px';
    textArea.style.left = '0px';
    textArea.style.position = 'absolute';
    textArea.style.opacity = '0.0';
    document.body.appendChild(textArea);
    callback(textArea);
  } catch (err) {
    log.errorEx(err, "Browser doesn't support document.execComment('copy') for clipboard operations");
  }
  textArea.remove();
}
