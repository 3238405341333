import ko from 'knockout';
import moment from 'moment';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CancellationToken } from '@/gr/common/cancellationToken';
import { copyDataToClipboard } from '@/gr/common/utils/clipboard';
import { Timer } from '@/gr/common/timer';
import { untitledTrendName, Trend, TrendServices, TrendTemplatesRepository, PolicyAuthorisationsService } from '@/apps/timeSeriesViewer';
import '@/gr/common/knockout/bindings/modal';
import { ProvideTrendUrls } from '../../services';

export class Component {
  isOpen;
  name;
  canCreateTemplate = ko.pureComputed(() => this.name() != null);
  untitledTrendName = untitledTrendName;
  includeOnHomePage = ko.observable<boolean>(false);

  hasTemplate = ko.pureComputed(() => this.trendFromTemplateUrl() != null);
  trendFromTemplateUrl = ko.observable<string | null>(null);

  errorMessage = ko.observable<string | null>(null);
  isLoading = ko.observable(false);
  isShowOnHomePageAllowed;
  isTrendUrlCopied = ko.observable(false);

  private _timer = new Timer();

  constructor(private _args: Args) {
    this.isOpen = this._args.isOpen;
    this.name = this._args.name;
    this.isShowOnHomePageAllowed = this._args.policyAuthorisations.isAuthorised('GlobalRoam');
  }

  async createTemplate(): Promise<void> {
    this.isLoading(true);
    this.errorMessage(null);
    const result = await this._args.trendTemplateRepository.create(this._args.trend, this.includeOnHomePage(), CancellationToken.none);
    this.isLoading(false);
    result
      .ifSuccess((templateSummary) => {
        this.trendFromTemplateUrl(this._args.trendUrls.fromTemplate(templateSummary.id));
      })
      .ifError((error) => {
        this.errorMessage(error);
      });
  }

  copyToClipboard(): void {
    copyDataToClipboard(this.trendFromTemplateUrl() as string);
    this.isTrendUrlCopied(true);
    this._timer.setToFireOnceIn(moment.duration(2, 'seconds'), () => this.isTrendUrlCopied(false));
  }

  startAgain(): void {
    this.trendFromTemplateUrl(null);
  }

  cancel(): void {
    this._args.isOpen(false);
  }

  createTrend(trendFromTemplateUrl: string): void {
    this.cancel();
    window.location.href = trendFromTemplateUrl;
  }

  dispose(): void {
    this._timer.dispose();
  }
}

export class Args {
  constructor(
    readonly name: KnockoutComputed<string | null | undefined>,
    readonly trend: Trend,
    readonly trendTemplateRepository: TrendTemplatesRepository,
    readonly policyAuthorisations: PolicyAuthorisationsService,
    readonly trendUrls: ProvideTrendUrls
  ) {}

  isOpen = ko.observable(false);

  show(): void {
    this.isOpen(true);
  }
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(this._trend.title, this._trend, this._services.trendTemplatesRepository, this._services.policyAuthorisations, this._services.trendUrls);
  }
}

import html from './templateDialog.html';
defineComponent(() => Component, 'templateDialog', html);
require('./templateDialog.less');
