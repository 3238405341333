import ko from 'knockout';
import { IOutputChannelStateFactory } from '@/apps/timeSeriesViewer';

export class ApiState {
  constructor(public blah: KnockoutObservable<string>) {}
}

export class ApiStateFactory implements IOutputChannelStateFactory {
  create(): ApiState {
    return new ApiState(ko.observable(''));
  }
}
