import * as log from '@/gr/common/log';
import { Result } from '@/gr/common/result';
import { IHttpRequester, ITrendDto, ErrorHelper, SuccessHttpResponse } from '@/apps/timeSeriesViewer';
import { CancellationToken } from '@/gr/common/cancellationToken';
import { ITemplateSummaryDto } from '@/repositories';

export class TrendTemplatesApi {
  constructor(private _requester: IHttpRequester) {}

  async create(includeOnHomePage: boolean, trend: ITrendDto, cancellationToken: CancellationToken): Promise<Result<ITemplateSummaryDto, string>> {
    const response = await this._requester.post<ITemplateSummaryDto>({
      relativeUrl: `api/templates?includeOnHomePage=${includeOnHomePage}`,
      cancellationToken: cancellationToken,
      data: JSON.stringify(trend)
    });
    if (response.isSuccess) {
      return Result.success((response as SuccessHttpResponse<ITemplateSummaryDto>).data);
    } else {
      log.error('Request to create template failed {@error}', ErrorHelper.SummaryObject.getForAjaxRequest(response));
      return Result.error(ErrorHelper.Message.getForAjaxDataRequest(response, 'An error occurred while trying to create your template.'));
    }
  }
}
