import moment from 'moment';
import ko from 'knockout';
import { CompositeDisposable, IDisposable } from '@/gr/common/disposable';
import { ITimerFactory } from '@/gr/common/timer';
import { PolicyAuthorisationsService, Trend, RelativeDuration, Clock } from '@/apps/timeSeriesViewer';
import { ITimeControl } from '@/common/timeContext';

export class TimeTravelController implements IDisposable {
  private _disposable = new CompositeDisposable();

  private _isControlled = ko.observable(true);

  constructor(
    private _trend: Trend,
    timeControl: ITimeControl,
    timerFactory: ITimerFactory,
    policyAuthorisations: PolicyAuthorisationsService,
    clock: Clock
  ) {
    this._disposable.add(
      ko.computed(() => {
        const time = timeControl.time() ?? clock.now;
        const isRealTime = timeControl.isRealTime();
        this._isControlled(isRealTime);
        this._trend.appDateTimes.now(time);
      })
    );

    // update app time when moving to the viewer
    this._disposable.add(
      ko.computed(() => {
        if (this._trend.currentStepId() === 'Viewer' && this._isControlled()) {
          this._trend.appDateTimes.now(clock.now);
        }
      })
    );

    // update now on a timer
    this._disposable.add(
      timerFactory.create().setToFireEvery(moment.duration(1, 'minutes'), () => {
        // timerFactory.create().setToFireEvery(moment.duration(10, 'seconds'), () => {
        const shouldUpdate = this._isControlled() && this._trend.appDateTimes.isRelative() && policyAuthorisations.isAuthorised('LiveUpdates')();
        console.log(`checking time ${moment(Date.now())} : ${shouldUpdate}`);
        if (shouldUpdate) {
          console.log(`updating time ${moment(Date.now())}`);
          this._trend.appDateTimes.now(clock.now);
        }
      })
    );

    // set look forward to zero if no forecasts allowed
    this._disposable.add(
      ko.computed(() => {
        if (!policyAuthorisations.isAuthorised('Forecasts')()) {
          this._trend.appDateTimes.lookForward(RelativeDuration.none);
        }
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }
}
