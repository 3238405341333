import {
  IConfiguredDataDefinitionDto,
  IDataSummaryDto,
  IInputMetricDto,
  IUnitsOfMeasureDto,
  TrendData,
  TrendDataConverter,
  TrendDataDefinition,
  TrendDataDefinitionConverter
} from '@/apps/timeSeriesViewer';
import {
  AggregateMetricDto,
  CalculatedMetricDto,
  ConfiguredMetricDto,
  DataSummaryDto,
  IMetricDto,
  RawMetricDto,
  SeriesDataCollectionRequestDto,
  TrendsMetricsApiClient,
  UnitsOfMeasureDto
} from '@/repositories';
import { IProgressStatus, ProgressStatus } from './progressStatus';
import { ResourcePumps } from './resourcePumps';
import { ITrendDataUpdater, TrendDataUpdater } from './trendDataUpdater';

function abortableFetch(abort: AbortWrapper) {
  const windowFetch = window.fetch;

  return (input: RequestInfo, init?: RequestInit) => {
    return windowFetch(input, { ...init, signal: abort.signal });
  };
}

class AbortWrapper {
  private _abortController: AbortController = new AbortController();

  get signal() {
    return this._abortController.signal;
  }

  reset() {
    if (this._abortController) this._abortController.abort();
    this._abortController = new AbortController();
  }
}

export class TrendsMetricsApi {
  private _abort: AbortWrapper = new AbortWrapper();
  private _fetch = abortableFetch(this._abort);

  constructor(
    private _trendDataDefinitionConverter: TrendDataDefinitionConverter,
    private _trendDataConverter: TrendDataConverter
  ) {}

  async fillData(dataDefinition: TrendDataDefinition, trendData: KnockoutObservable<TrendData | null>, progress: KnockoutObservable<string>, isLoading: KnockoutObservable<boolean>): Promise<void> {
    progress('Requesting data');

    const dto = this._trendDataDefinitionConverter.toDto(dataDefinition);

    const request = new SeriesDataCollectionRequestDto({
      start: dto.start,
      end: dto.end,
      pointInTimeContext: dto.pointInTimeContext,
      configuredDataDefinitions: dto.configuredDataDefinitions.map((x) => ConfiguredMetricDto.fromJS(x)),
      requestId: dto.requestId,
      utcOffsetInMinutes: dto.utcOffsetInMinutes
    });

    const progressStatusCreator = (totalRequests: number) => new ProgressStatus(totalRequests, progress, isLoading);
    const trendDataUpdaterCreator = (configuredMetrics: { configuredMetric: IConfiguredDataDefinitionDto; isForecast: boolean }[]) =>
      new TrendDataUpdater(trendData, configuredMetrics, this._trendDataConverter, dataDefinition);
    await this.handleRequest(request, trendDataUpdaterCreator, progressStatusCreator);
  }

  public async handleRequest(
    request: SeriesDataCollectionRequestDto,
    trendDataUpdaterCreator: (configuredMetrics: { configuredMetric: IConfiguredDataDefinitionDto; isForecast: boolean }[]) => ITrendDataUpdater,
    progressStatusCreator: (totalRequests: number) => IProgressStatus
  ): Promise<void> {
    try {
      // abort any previous requests
      this._abort.reset();

      const api = new TrendsMetricsApiClient('', { fetch: this._fetch });

      const configuredMetrics: { configuredMetric: IConfiguredDataDefinitionDto; isForecast: boolean }[] = [];
      const availableRequests: { configuredMetricId: string; url: string }[] = [];
      const pendingRequests: { configuredMetricId: string; url: string }[] = [];

      const responses = await api.trendsQuery(request);

      for (const response of responses) {
        configuredMetrics.push({ configuredMetric: toIConfiguredDataDefinitionDto(response.configuredMetric), isForecast: response.isForecast });
        for (const availableUrl of response.available) availableRequests.push({ configuredMetricId: response.configuredMetric.id, url: availableUrl });
        for (const pendingUrl of response.pending) pendingRequests.push({ configuredMetricId: response.configuredMetric.id, url: pendingUrl });
      }
      const trendDataUpdater = trendDataUpdaterCreator(configuredMetrics);

      const progressStatus = progressStatusCreator(availableRequests.length + pendingRequests.length);
      const resourcePumps = new ResourcePumps(api, availableRequests, pendingRequests, trendDataUpdater, progressStatus, this._abort.signal, this._fetch);
      await resourcePumps.run();
    } catch (error) {
      if (error instanceof DOMException && error.code === DOMException.ABORT_ERR) {
        console.warn('User aborted request');
      } else {
        throw error;
      }
    }
  }
}

function toIConfiguredDataDefinitionDto(configuredMetric: ConfiguredMetricDto): IConfiguredDataDefinitionDto {
  return {
    id: configuredMetric.id,
    name: configuredMetric.name,
    calculationVariableName: configuredMetric.calculationVariableName,
    timeStep: configuredMetric.timeStep,
    aggregate: configuredMetric.aggregate,
    isIncludedInOutputChannels: configuredMetric.isIncludedInOutputChannels,
    inputDataDefinitionId: toIInputMetricDto(configuredMetric.inputDataDefinitionId),
    unitsOfMeasure: toIUnitsOfMeasureDto(configuredMetric.unitsOfMeasure),
    dataSummary: toIDataSummary(configuredMetric.dataSummary)
  };
}

function toIInputMetricDto(inputMetricDto: IMetricDto): IInputMetricDto {
  switch (inputMetricDto.type) {
    case 'Raw':
      return inputMetricDto as RawMetricDto as IInputMetricDto;
    case 'Aggregate':
      return inputMetricDto as AggregateMetricDto as IInputMetricDto;
    case 'Calculated':
      return inputMetricDto as CalculatedMetricDto as IInputMetricDto;
    default:
      throw 'unknown input metric type';
  }
}

function toIUnitsOfMeasureDto(unitsOfMeasure: UnitsOfMeasureDto): IUnitsOfMeasureDto {
  return {
    displayName: unitsOfMeasure.displayName,
    decimalPlaces: unitsOfMeasure.decimalPlaces,
    id: unitsOfMeasure.id,
    valueFormatString: unitsOfMeasure.valueFormatString
  };
}

function toIDataSummary(dataSummary: DataSummaryDto): IDataSummaryDto {
  return {
    period: dataSummary.period?.toString()
  };
}
