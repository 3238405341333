import ko from 'knockout';
import kx from '../extended';
import $ from 'jquery';
import _ from 'lodash';
import * as log from '../../log';
import { CompositeDisposable, SerialDisposable } from '../../disposable';
import { IModalIframeService } from '../../ui/modals/modalIframeService';
import { ModalGlobalSettings } from '../../ui/modals/modalGlobalSettings';
// import "../../libs/bootstrap/js/transition";
// import "../../libs/bootstrap/js/modal-modified";

import { ModalOption } from 'bootstrap';

export interface IModalBindingArgs {
  templateId: kx.ReadOnlyObservable<string> | string;
  isOpen: kx.Observable<boolean>;
  isClosable?: boolean;
}

interface IInitializedModalBindingArgs extends IModalBindingArgs {
  isClosable: boolean;
}

try {
  let initialized = false;
  let modalIframeServicePromise: Promise<IModalIframeService>;

  ko.bindingHandlers.modal = {
    init(element, valueAccessor, allBindings, viewModel, bindingContext) {
      if (!initialized) {
        initialized = true;
        modalIframeServicePromise = ModalGlobalSettings.get().iframeFactory.create();
      }

      const bindingArgs = ko.unwrap(valueAccessor());
      const bindingArgsWithDefaults = _.defaults(_.clone(bindingArgs), { isClosable: true }) as IInitializedModalBindingArgs;
      const instance = new ModalBindingInstance(modalIframeServicePromise);
      instance.initialize(bindingArgsWithDefaults, bindingContext);
      ko.utils.domNodeDisposal.addDisposeCallback(element, () => instance.dispose());
    }
  };
} catch (ex) {
  log.fatalEx(ex, 'Failed to initialise modals');
}

class ModalBindingInstance {
  private readonly _bindingLifetime = new CompositeDisposable();
  private readonly _latestModalLifetime = new SerialDisposable();

  private _$latestModal!: JQuery<HTMLElement>;

  constructor(private modalIframeServicePromise: Promise<IModalIframeService>) {}

  initialize(args: IInitializedModalBindingArgs, bindingContext: any) {
    this.assertArgsValid(args);
    this._bindingLifetime.add(this._latestModalLifetime);
    this._bindingLifetime.add(ko.computed(() => this.createModal(ko.unwrap(args.templateId), args.isClosable, args.isOpen, bindingContext)));
    this._bindingLifetime.add(ko.computed(() => this.updateModalIsOpen(args.isOpen())));
  }

  dispose(): void {
    this._bindingLifetime.dispose();
  }

  private createModal(templateId: string, isClosable: boolean, isOpen: kx.Observable<boolean>, bindingContext: any) {
    this.modalIframeServicePromise
      .then((modalIframeService) => {
        const modalLifetime = new CompositeDisposable();
        this._latestModalLifetime.setDisposable(modalLifetime);

        const $modalContainer = this.createModalContainer(modalIframeService, templateId, modalLifetime);

        this.copyTemplateToModalIFrame(modalIframeService, templateId);

        this._$latestModal = this.renderModalTemplate(templateId, $modalContainer, bindingContext, modalLifetime);

        this._$latestModal.modal({
          show: false,
          modalWindow: modalIframeService.modalWindow,
          modalParentWindow: modalIframeService.modalWindow,
          backdropZIndex: 10001 + 2 * modalIframeService.getOpenModalsCount(),
          modalZIndex: 10002 + 2 * modalIframeService.getOpenModalsCount(),
          backdrop: isClosable ? true : 'static',
          keyboard: isClosable
        } as ModalOption);

        this._$latestModal.on('shown.bs.modal', () => {
          this._$latestModal.find('[autofocus]').focus();
        });

        this._$latestModal.on('hide.bs.modal', () => {
          isOpen(false);
        });

        this._$latestModal.on('hidden.bs.modal', () => {
          modalIframeService.hide();
        });
      })
      .catch((error) => {
        log.errorEx(error, 'Error in createModal');
      });
  }

  private updateModalIsOpen(isOpen: boolean) {
    this.modalIframeServicePromise
      .then((modalIframeController) => {
        if (!this._$latestModal) return;

        if (isOpen) {
          modalIframeController.show().then(() => {
            this._$latestModal.modal('show');
          });
        } else {
          this._$latestModal.modal('hide');
        }
      })
      .catch((error) => {
        log.errorEx(error, 'Error in updateModalIsOpen');
      });
  }

  private createModalContainer(modalIframeController: IModalIframeService, templateId: string, modalLifeTime: CompositeDisposable) {
    const $modalContainer = $(`<div class="${templateId}"></div>`);
    $(modalIframeController.modalWindow.document.body).append($modalContainer);
    modalLifeTime.add(() => {
      ko.cleanNode($modalContainer[0]);
      $($modalContainer[0]).unbind();
      $modalContainer.remove();
    });
    return $modalContainer;
  }

  private copyTemplateToModalIFrame(modalIframeController: IModalIframeService, templateId: string) {
    // The template needs to be present in the modal iframe, so copy if not already present
    if (!modalIframeController.modalWindow.document.getElementById(templateId)) {
      const template = document.getElementById(templateId);
      if (!template) throw new Error('Could not find template with ID: ' + templateId);
      const clonedTemplate = template.cloneNode(true);
      modalIframeController.modalWindow.document.body.appendChild(clonedTemplate);
    }
  }

  private renderModalTemplate(templateId: string, $modalContainer: JQuery, bindingContext: any, modalLifeTime: CompositeDisposable) {
    modalLifeTime.add(ko.renderTemplate(templateId, bindingContext, {}, $modalContainer[0]));
    const $latestModal = $modalContainer.children('.modal');
    if ($latestModal.length !== 1) throw new Error(`Expected one root element with class ".modal" in template with id ${templateId} but found ${this._$latestModal.length}`);
    return $latestModal;
  }

  private assertArgsValid(value: IModalBindingArgs) {
    if (!value) throw new Error('Expected modal binding args to be non-null');
    if (!ko.isObservable(value.isOpen)) throw new Error(`Expected modal binding args { isOpen: kx.ReadOnlyObservable<boolean> }`);
    if (!ko.isObservable(value.templateId) && !_.isString(value.templateId)) throw new Error(`Expected modal binding args { templateId: kx.ReadOnlyObservable<string> | string }`);
  }
}
