import { Metric } from '.';

export class RawMetric {
  constructor(readonly id: string) {}

  equals(d: Metric): boolean {
    return d instanceof RawMetric && d.id === this.id;
  }

  toUniqueString(): string {
    return this.id;
  }
}
