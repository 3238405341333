import { UrlHelper } from '@/gr/common/urlHelper';
import { IProductDto } from '@/apps/timeSeriesViewer';

export class FeedbackService {
  constructor(
    private _baseUrl: string,
    private _product: IProductDto
  ) {}

  getUrl(): string {
    return UrlHelper.combine(this._baseUrl, `/app/${this._product.id}?appName=${encodeURIComponent(this._product.name)}&referrer=${encodeURIComponent(window.location.href)}`);
  }
}
