// The different boxes of an element (the box model) are shown here:
// https://msdn.microsoft.com/en-us/library/hh781509(VS.85).aspx
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Introduction_to_the_CSS_box_model
// https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetHeight
// https://developer.mozilla.org/en-US/docs/Web/API/Element/clientHeight
// https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollHeight

/** MarginBox */
export function getVisibleWidthOutsideMargin(element: HTMLElement) {
  return element.offsetWidth + getComputedStylePropertyAsInteger(element, 'marginLeft') + getComputedStylePropertyAsInteger(element, 'marginRight');
}

function getComputedStyleProperty(element: HTMLElement, property: keyof CSSStyleDeclaration) {
  const computedStyles = document.defaultView!.getComputedStyle(element);
  const value = computedStyles !== null ? computedStyles[property] : 0;
  return value;
}

export function getComputedStylePropertyAsInteger(element: HTMLElement, property: keyof CSSStyleDeclaration) {
  const value = getComputedStyleProperty(element, property) as string;
  return parseInt(value, 10);
}
