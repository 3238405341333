import { ChartStateConverter, TableStateConverter, ChartStateConverterFactory, TableStateConverterFactory } from '@/apps/timeSeriesViewer/converters';
import { OutputChannelContainer, OutputChannelTypeId, OutputChannel, TrendSummaryForChannels, Trend, ChartState, TableState, FileState, ApiState } from '@/apps/timeSeriesViewer/models';
import { TrendServices } from '@/apps/timeSeriesViewer/services';
import { IChannelContainerDto } from '..';

export class ChannelConverter {
  private _channelStateConverters: {
    id: OutputChannelTypeId;
    converter: IChannelStateConverter<ChartState | TableState | FileState | ApiState | null, ChartState | TableState | FileState | ApiState | null>;
  }[];

  constructor(
    private _chartStateConverter: ChartStateConverter,
    private _tableStateConverter: TableStateConverter
  ) {
    this._channelStateConverters = [
      { id: 'CHART', converter: this._chartStateConverter },
      { id: 'TABLE', converter: this._tableStateConverter }
    ];
  }

  toDto(model: OutputChannelContainer): IChannelContainerDto {
    return {
      type: model.outputChannel().channelType,
      state: this.getConverter(model.outputChannel().channelType).toDto(model.outputChannel().state)
    };
  }

  toModel(dto: IChannelContainerDto, trendSummary: TrendSummaryForChannels): OutputChannelContainer {
    const container = OutputChannelContainer.empty();
    container.outputChannel(new OutputChannel(dto.type, this.getConverter(dto.type).toModel(dto.state, trendSummary)));
    return container;
  }

  private getConverter(type: OutputChannelTypeId): IChannelStateConverter<ChartState | TableState | FileState | ApiState | null, ChartState | TableState | FileState | ApiState | null> {
    const channel = this._channelStateConverters.find((c) => c.id === type);

    // Return default converter if one isn't defined
    if (channel == null) return { toModel: () => null, toDto: () => null };

    return channel.converter;
  }
}

export class ChannelConverterFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}
  create(): ChannelConverter {
    return new ChannelConverter(new ChartStateConverterFactory(this._services).create(), new TableStateConverterFactory().create());
  }
}

export interface IChannelStateConverter<TModel, TDto> {
  toModel(dto: TDto, trendSummary: TrendSummaryForChannels): TModel;
  toDto(model: TModel): TDto;
}
