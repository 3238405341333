import _ from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import { UtcOffset, UtcOffsetDefinition } from '@/apps/timeSeriesViewer';

export class UtcOffsetDefinitionConverter {
  static toUtcOffset(utcOffsetDefinition: UtcOffsetDefinition): UtcOffset {
    switch (utcOffsetDefinition.type) {
      case 'Offset': {
        const utcOffset = moment.duration(utcOffsetDefinition.fixedOffsetInMinutes, 'minutes');
        return new UtcOffset(utcOffset, this.formatOffset(utcOffset));
      }
      case 'TimeZone': {
        const momentInTimezone = moment().tz(utcOffsetDefinition.timeZone);
        const timeZoneOffset = moment.duration(momentInTimezone.utcOffset(), 'minutes');
        return new UtcOffset(timeZoneOffset, momentInTimezone.zoneAbbr());
      }
    }
  }

  private static formatOffset(utcOffset: moment.Duration) {
    const offsetHours = utcOffset.hours();
    const offsetMinutes = utcOffset.minutes();
    const offsetMinutesComponent = offsetMinutes !== 0 ? ':' + _.padStart(Math.abs(offsetMinutes).toString(), 2, '0') : '';
    const timeZone = 'UTC' + (offsetHours < 0 ? '-' : '+') + Math.abs(offsetHours) + offsetMinutesComponent;
    return timeZone;
  }
}
