import _ from 'lodash';
import $ from 'jquery';
import { UrlHelper } from '@/gr/common/urlHelper';
import { CancellationToken } from '@/gr/common/cancellationToken';
import { GlobalRoamAuthentication } from '@gr/authentication';

export interface IHttpRequester {
  ajax<T>(config: IAjaxConfig): Promise<HttpResponse<T>>;
  post<T>(config: IHttpRequestWithDataConfig): Promise<HttpResponse<T>>;
  put<T>(config: IHttpRequestWithDataConfig): Promise<HttpResponse<T>>;
  get<T>(config: IHttpRequestConfig): Promise<HttpResponse<T>>;
  delete<T>(config: IHttpRequestWithDataConfig): Promise<HttpResponse<T>>;
}

export interface IHttpRequestConfig {
  relativeUrl?: string;
  url?: string;
  contentType?: 'application/json';
  cancellationToken?: CancellationToken;
  timeout?: number;
}

export interface IAjaxConfig extends IHttpRequestConfig {
  data?: string;
  type: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

export interface IHttpRequestWithDataConfig extends IHttpRequestConfig {
  data?: string;
}

export class VersionedApiHttpRequester implements IHttpRequester {
  constructor(
    private readonly _baseUrl: string,
    private readonly _apiVersion: string,
    private readonly _onNewVersion: () => void,
    private _windowSessionId: string,
    private _authentication?: GlobalRoamAuthentication
  ) {}

  post<T>(config: IHttpRequestWithDataConfig): Promise<HttpResponse<T>> {
    return this.ajax(_.defaults(config, { type: 'POST' }) as IAjaxConfig);
  }

  put<T>(config: IHttpRequestWithDataConfig): Promise<HttpResponse<T>> {
    return this.ajax(_.defaults(config, { type: 'PUT' }) as IAjaxConfig);
  }

  get<T>(config: IHttpRequestConfig): Promise<HttpResponse<T>> {
    return this.ajax(_.defaults(config, { type: 'GET' }) as IAjaxConfig);
  }

  delete<T>(config: IHttpRequestWithDataConfig): Promise<HttpResponse<T>> {
    return this.ajax(_.defaults(config, { type: 'DELETE' }) as IAjaxConfig);
  }

  ajax<T>(config: IAjaxConfig): Promise<HttpResponse<T>> {
    const url = config.relativeUrl !== undefined ? UrlHelper.combine(this._baseUrl, config.relativeUrl) : config.url;

    if (_.isNil(url)) throw new Error('Either a relativeUrl or a url must be provided');

    if (config.cancellationToken && config.cancellationToken.isCancelled) return nonReturningPromise as Promise<HttpResponse<T>>;

    if (config.contentType == null) config.contentType = 'application/json';

    let isComplete = false;

    const promise = new Promise<HttpResponse<T>>((resolve, reject) => {
      const authenticationHeader = this._authentication === undefined ? {} : { Authorization: `Bearer ${this._authentication.user.access_token}` };
      const xhr = $.ajax({
        url: url,
        data: config.data,
        headers: { ...authenticationHeader, 'Api-Version': this._apiVersion, 'Window-Session-Id': this._windowSessionId },
        type: config.type,
        contentType: config.contentType,
        timeout: config.timeout,
        success: (data, textStatus, response) => {
          if (!isComplete) {
            isComplete = true;
            if (config.cancellationToken && config.cancellationToken.isCancelled) {
              reject(config.cancellationToken.getCancellationError());
              return;
            }
            resolve(new SuccessHttpResponse(data, response.status, response.statusText, response.responseText));
          }
        },
        error: (response) => {
          if (!isComplete) {
            isComplete = true;
            if (config.cancellationToken && config.cancellationToken.isCancelled) {
              reject(config.cancellationToken.getCancellationError());
              return;
            }
            const errorResponse = new ErrorHttpResponse(response.status, response.statusText, response.responseText);
            const dto = errorResponse.getContentAsObject();
            if (this.isBadRequestResponseDto(dto)) {
              this._onNewVersion();
              resolve(nonReturningPromise as Promise<HttpResponse<T>>);
            } else {
              resolve(errorResponse);
            }
          }
        }
      });
      if (config.cancellationToken) {
        const cancellationToken = config.cancellationToken;
        config.cancellationToken.register(() => {
          if (!isComplete) {
            isComplete = true;
            xhr.abort();
            reject(cancellationToken.getCancellationError());
          }
        });
      }
    });

    return promise;
  }

  private isBadRequestResponseDto(o: unknown): o is IBadRequestResponseDto {
    return o !== undefined && (o as IBadRequestResponseDto).code === 'UnsupportedApiVersion';
  }
}

const nonReturningPromise = new Promise(() => {
  // never resolve
});

export type HttpResponse<T> = SuccessHttpResponse<T> | ErrorHttpResponse;

export interface IHttpResponse {
  isSuccess: boolean;
  isError: boolean;
  status: number;
  statusText: string;
  responseText: string;
}

export class ErrorHttpResponse implements IHttpResponse {
  isSuccess = false;
  isError = true;

  constructor(
    public status: number,
    public statusText: string,
    public responseText: string
  ) {}

  getContentAsObject(): unknown {
    try {
      return JSON.parse(this.responseText);
    } catch (error) {
      return undefined;
    }
  }
}

export class SuccessHttpResponse<T> implements IHttpResponse {
  isSuccess = true;
  isError = false;

  constructor(
    public data: T,
    public status: number,
    public statusText: string,
    public responseText: string
  ) {}

  getContentAsObject(): T {
    return this.data;
  }
}

interface IBadRequestResponseDto {
  code: string;
  message: string;
}
