import { File, OutputChannel, ChartArgsFactory, Api, Table } from '@/apps/timeSeriesViewer';
import { ApiState, ChartState, FileState, TableState } from '@/apps/timeSeriesViewer/models';
import { ChartArgs } from '../chart';

export class OutputChannelComponentFactory {
  constructor(
    private _chartArgsFactory: ChartArgsFactory,
    private _tableArgsFactory: Table.ArgsFactory,
    private _fileArgsFactory: File.ArgsFactory,
    private _apiArgsFactory: Api.ArgsFactory
  ) {}

  create(outputChannel: OutputChannel): { name: string; args: ChartArgs | Table.Args | File.Args | Api.Args } {
    switch (outputChannel.channelType) {
      case 'CHART':
        return { name: 'chart', args: this._chartArgsFactory.create(outputChannel.state as ChartState) };
      case 'TABLE':
        return { name: 'table', args: this._tableArgsFactory.create(outputChannel.state as TableState) };
      case 'FILE':
        return { name: 'file', args: this._fileArgsFactory.create(outputChannel.state as FileState) };
      case 'API':
        return { name: 'api', args: this._apiArgsFactory.create(outputChannel.state as ApiState) };
      default:
        throw new Error('No idea how to create the args from this output channel. You need to add something to OutputChannelComponentFactory');
    }
  }
}
