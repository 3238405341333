export interface IProgressStatus {
  increment: () => void;
  setMessage: (message: string) => void;
}

export class ProgressStatus implements IProgressStatus {
  private _count = 0;

  constructor(
    private _total: number,
    private _progress: (value: string) => void,
    private _isLoading: (value: boolean) => void
  ) {
    this.setMessage('Processing');
  }

  private get percentage() {
    const percentage = (100 * this._count) / this._total;
    return percentage.toFixed(0);
  }

  increment = (): void => {
    this._count++;
    this.setMessage('Downloading');
  };

  setMessage = (message: string): void => {
    this._isLoading(true);
    const pendingCount = this._total - this._count;
    this._progress(`${message} ${this.percentage}% (${pendingCount} pending)`);
  };
}
