import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { ApiState } from '@/apps/timeSeriesViewer/models';

export class Component {}

export class Args {
  constructor(public state: ApiState) {}
}

export class ArgsFactory {
  create(settings: ApiState): Args {
    return new Args(settings);
  }
}

import html from './api.html';
defineComponent(() => Component, 'api', html);
require('./api.less');
