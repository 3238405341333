import { IChannelStateConverter, TableState, ITableDto, TableStateFactory, TrendSummaryForChannels } from '@/apps/timeSeriesViewer';

export class TableStateConverter implements IChannelStateConverter<TableState, ITableDto> {
  constructor(private _tableStateFactory: TableStateFactory) {}

  toModel(dto: ITableDto, trendSummary: TrendSummaryForChannels): TableState {
    return this._tableStateFactory.create(trendSummary);
  }

  toDto(): ITableDto {
    return {
      name: 'table'
    };
  }
}

export class TableStateConverterFactory {
  create(): TableStateConverter {
    return new TableStateConverter(new TableStateFactory());
  }
}
