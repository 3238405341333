import ko from 'knockout';
import * as log from '@/gr/common/log';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { IKoDropOptions } from '@/gr/common/knockout/bindings/drop';

export class Component {
  constructor(private _args: Args) {
    this.inProgress = this._args.inProgress;
    this.error = this._args.error;
  }

  inProgress: KnockoutObservable<boolean>;
  error: KnockoutObservable<string | null>;
  errorDrop: IKoDropOptions = { template: 'promise-indicator-drop-template', position: 'bottom center', constrainToScrollParent: false, openOn: 'hover' };
}

export class Args {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(
    private _action: () => Promise<void>,
    private _transformErrorToMessage: (error: Error) => string
  ) {}

  inProgress = ko.observable(false);
  error = ko.observable<string | null>(null);

  begin = (): void => {
    this.inProgress(true);
    this._action().then(
      () => {
        this.inProgress(false);
        this.error(null);
      },
      (error) => {
        const message = this._transformErrorToMessage(error);
        log.errorEx(error, 'Promise rejected with message: ' + message);
        this.inProgress(false);
        this.error(message);
      }
    );
  };
}

export class ArgsFactory {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  create(action: () => Promise<void>, errorMessage: (error: any) => string): Args {
    return new Args(action, errorMessage);
  }
}

import html from './promiseIndicator.html';
defineComponent(() => Component, 'promiseIndicator', html);
require('./promiseIndicator.less');
