import _ from 'lodash';
import { Format } from '@/gr/common/format';

export type TimeStep = 'Raw' | 'HalfHour' | 'Hour' | 'Day' | 'Week' | 'Month' | 'Quarter' | 'Yearly' | 'EntireRange';

export type Aggregate = 'None' | 'Average' | 'Minimum' | 'Maximum' | 'Sum' | 'Count';

export type GraphType = 'line' | 'area' | 'column';

export class SavingStatus {
  constructor(
    public state: 'unsaved' | 'saving' | 'saved' | 'errored',
    public htmlMessage: string,
    public tooltipHtmlMessage: string
  ) {}
}

export class UnitsOfMeasure {
  constructor(
    readonly displayName: string,
    readonly id: number,
    readonly valueFormatString: string,
    readonly decimalPlaces: number
  ) {}
}

export class SeriesUnitsOfMeasure {
  public static roundValue = (value: number, units: UnitsOfMeasure): string => {
    let stringValue: string;

    if (_.isNil(value)) {
      stringValue = '--';
    } else {
      if (units.displayName === '%') value = value * 100;
      // floating points in javascript seem to exhibit some weird innaccuracy that was screwing
      // with the axis on the chart so we "round" the value to the number of decimal places
      const decimalPlaces = units.decimalPlaces != null ? units.decimalPlaces : Format.number.countDecimals(value);
      const roundingFactor = Math.pow(10, decimalPlaces);
      value = Math.round(value * roundingFactor) / roundingFactor;
      stringValue = units.decimalPlaces != null ? value.toFixed(units.decimalPlaces) : value.toFixed(Format.number.countDecimals(value));
    }
    return stringValue;
  };
  public static formatValue = (value: number, units: UnitsOfMeasure): string => {
    return units.valueFormatString.replace('[[value]]', SeriesUnitsOfMeasure.roundValue(value, units));
  };
}

// export interface IDataPoint {
//     timeStamp;
//     value;
// }

export interface IDataPointsForTime {
  timeStamp: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [configuredDataDefinitionId: string]: any;
}
