import { writeHtmlTo } from '../utils/document';
import { toArray } from '../utils/array';

function createFullScreenInvisibleModalIframe(src?: string) {
  const iframe = createStandardIframe();
  iframe.name = 'gr-modal';
  iframe.className = 'gr-modal';
  if (src) iframe.src = src;
  iframe.style.height = '100%';
  iframe.style.position = 'fixed';
  iframe.style.left = '0';
  iframe.style.top = '0';
  iframe.style.display = 'none';
  iframe.style.zIndex = '100000';
  return iframe;
}

function createStandardIframe() {
  const iframe = document.createElement('iframe');
  iframe.frameBorder = 'no';
  iframe.scrolling = 'no';
  iframe.style.boxSizing = 'border-box';
  iframe.style.webkitBoxSizing = 'border-box';
  iframe.style.width = '100%';
  iframe.style.height = '0';
  iframe.style.border = 'none';
  iframe.style.background = 'transparent';
  // iframes default to "display: inline" but we want block layout
  iframe.style.display = 'block';
  iframe.style.overflow = 'hidden';
  return iframe;
}

export function addSameOriginFullScreenInvisibleModalIframeTo(window: Window) {
  const iframe = createFullScreenInvisibleModalIframe();
  window.document.body.appendChild(iframe);
  writeHtmlTo(iframe.contentDocument!, getBlankPageHtml());
  return iframe;
}

function getBlankPageHtml() {
  return [`<!DOCTYPE html>`, `<html>`, `   <head>`, `   </head>`, `   <body style="background-color: transparent;">`, `   </body>`, `</html>`].join('\n');
}

export function cloneCss(sourceWindow: Window, destinationWindow: Window) {
  const targetHead = destinationWindow.document.head;

  toArray(sourceWindow.document.querySelectorAll("link[rel='stylesheet']"))
    .map((e) => e.cloneNode(true))
    .forEach((e) => targetHead!.appendChild(e));

  // Copying style tags is particularly important in Firefox, where the library we are using lazy loads css
  // by inserting html of the form: <style>@import "<path to css file>"</style>
  toArray(sourceWindow.document.querySelectorAll('style'))
    .map((e) => e.cloneNode(true))
    .forEach((e) => targetHead!.appendChild(e));
}

// This is a fix for IE browsers which disables the sliding in transition for bootstrap modals.
// This is required because the bootstrap modal transition animation causes flickering in IE11 running on Win10 when inside a fixed position iframe.
export function addIETransitionFixTo(window: Window) {
  const styleElement = document.createElement('style');

  const css = [
    `_:-ms-fullscreen, :root .modal.fade .modal-dialog {`,
    `   -ms-transform: translate(0, 0%);`,
    `   transform: translate(0, 0%);`,
    `   transition: transform 0.0s ease;`,
    `}`,

    `_:-ms-fullscreen, :root .modal.in .modal-dialog {`,
    `   -ms-transform: translate(0, 0);`,
    `   transform: translate(0, 0);`,
    `}`
  ].join('\n');

  styleElement.appendChild(document.createTextNode(css));

  window.document.head!.appendChild(styleElement);
}
