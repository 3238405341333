
























import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import moment from 'moment';

@Component
export default class GrDateTimePicker extends Vue {
  @Prop({ default: Date().toString() })
  value!: string;

  @Prop()
  disabled!: boolean;

  @Prop({ default: 'Date / Time' })
  label!: string;

  @Prop()
  min!: string;

  @Prop()
  max!: string;

  @Prop()
  dataTestName!: string;

  private UTC_OFFSET = 10;

  private displayFormat = 'DD/MM/YYYY HH:mm';
  private parseFormats = [
    'D/M/YYYY H:m',
    'D/M/YY H:m',
    'D/M/YYYY H',
    'D/M/YY H',
    'D/M/YYYY',
    'D/M/YY',
    'YYYY/M/D H:m',
    'YY/M/D H:m',
    'YYYY/M/D H',
    'YY/M/D H',
    'YYYY/M/D',
    'YY/M/D',
    'YYYY/M',
    'YY/M',
    'YYYY',
    'YY',
    'D-M-YYYY H:m',
    'D-M-YY H:m',
    'D-M-YYYY H',
    'D-M-YY H',
    'D-M-YYYY',
    'D-M-YY',
    'YYYY-M-D H:m',
    'YY-M-D H:m',
    'YYYY-M-D H',
    'YY-M-D H',
    'YYYY-M-D',
    'YY-M-D',
    'YYYY-M',
    'YY-M'
  ];

  private updator(p: string, event: string) {
    if (event === undefined || event === null) return;
    let dateTime: moment.Moment;
    if (p === 'dateTimeString') {
      if (this.checkFormat(event) !== true) return;
      dateTime = moment(event, this.parseFormats, true).utcOffset(this.UTC_OFFSET, true);
    } else if (p === 'date') {
      dateTime = moment(`${event}T${moment(this.value).utcOffset(this.UTC_OFFSET).format(moment.HTML5_FMT.TIME)}`).utcOffset(this.UTC_OFFSET, true);
    } else if (p === 'hour') {
      dateTime = moment(this.value).utcOffset(this.UTC_OFFSET).hour(+event);
    } else if (p === 'minute') {
      dateTime = moment(this.value).utcOffset(this.UTC_OFFSET).minute(+event);
    } else return;
    this.$emit('input', dateTime.toISOString());
  }

  private dateMenu = false;
  private timeMenu = false;

  private get date() {
    return moment(this.value).utcOffset(this.UTC_OFFSET).format(moment.HTML5_FMT.DATE);
  }

  private get time() {
    return moment(this.value).utcOffset(this.UTC_OFFSET).format(moment.HTML5_FMT.TIME);
  }

  private get dateTimeString() {
    return moment(this.value).utcOffset(this.UTC_OFFSET).format(this.displayFormat);
  }

  private checkFormat(value: string) {
    return moment(value, this.parseFormats, true).isValid() ? true : `Try D/M/YY or D/M/YY H:m or YYYY/M/D H:m`;
  }
}
