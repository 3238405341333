import _ from 'lodash';
import { SeriesUnitsOfMeasure, ICompleteChartSeries, defaultIfNil } from '@/apps/timeSeriesViewer';
import AmCharts from '@/libs/amcharts';

export class GraphFactory {
  create(chartSeries: ICompleteChartSeries[], valueAxes: AmCharts.ValueAxis[]): AmCharts.AmGraph[] {
    return (
      _(chartSeries)
        .map((seriesVisual) => this.createGraph(seriesVisual, valueAxes))
        // Reversed so that the first item in the array (before it was reversed) is the top-most item in the AmChart (highest z order)
        .reverse()
        .value()
    );
  }

  private createGraph(chartSeries: ICompleteChartSeries, valueAxes: AmCharts.ValueAxis[]): AmCharts.AmGraph {
    const configuredDataDefinition = chartSeries.configuredDataDefinition;

    // const graph = new AmCharts.AmGraph();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const graph: any = {};

    graph.title = configuredDataDefinition.displayNameOrDefault();

    graph.valueField = configuredDataDefinition.id;
    // graph.balloonFunction = (graphData: AmCharts.GraphDataItem): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    graph.balloonFunction = (graphData: any): string => {
      let out = configuredDataDefinition.displayNameOrDefault() + ': ';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (graphData.values as any).value as number;
      const units = configuredDataDefinition.unitsOfMeasure;
      if (units) out += SeriesUnitsOfMeasure.formatValue(value, units);
      return out;
    };

    const valueAxis = _.find(valueAxes, (valueAxis) => valueAxis.id === chartSeries.axis()?.id);
    if (valueAxis === undefined) throw new Error('Could not find value axis with id ' + chartSeries.axis()?.id);
    graph.valueAxis = valueAxis;

    graph.hideBulletsCount = 50;
    graph.bulletSize = 5;
    graph.precision = 2;
    graph.stepDirection = defaultIfNil(chartSeries.stepDirection(), 'right');
    graph.noStepRisers = defaultIfNil(chartSeries.noStepRisers(), false);
    graph.periodSpan = defaultIfNil(chartSeries.periodSpan(), 1);
    graph.pointPosition = 'start'; // Position the point on the vertical grid line (rather than between grid lines)

    switch (chartSeries.type()) {
      case 'line':
        graph.type = 'line';
        graph.fillAlphas = 0;
        graph.lineThickness = chartSeries.lineThicknessWithDefault();
        break;
      case 'column':
        graph.type = 'column';
        graph.clustered = false;
        graph.lineThickness = 0;
        break;
      case 'area':
        graph.type = 'line';
        graph.lineThickness = 0;
        break;
    }

    if (chartSeries.isStepped() && chartSeries.isSteppedApplicable()) {
      graph.type = 'step';
    }

    const isStacked = chartSeries.isStackedApplicable() && chartSeries.isStacked();
    graph.stackable = isStacked;
    if (isStacked) graph.valueAxis.stackType = 'regular';

    graph.lineColor = chartSeries.color();
    graph.fillColors = chartSeries.color();
    graph.dashLength = defaultIfNil(chartSeries.dashLength(), 0);
    graph.bullet = defaultIfNil(chartSeries.bullet(), 'none');
    graph.lineAlpha = 1;
    graph.bulletAlpha = graph.lineAlpha;
    graph.columnWidth = defaultIfNil(chartSeries.columnWidth(), NaN);
    graph.visibleInLegend = chartSeries.showInLegend();

    if (graph.fillAlphas == null) graph.fillAlphas = 1;

    return graph;
  }
}
