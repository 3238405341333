/** @description Converts hash colour string to an rgba() string.
 * This function is implemented to work around old browsers not recognsing rgba colours when given in #rrggbbaa form.
 * Most importantly for chrome version 57 as embedded in ez2view v7. As such it is designed to be a workaround so will not error on a badly formed input. It will return it as is.
 * @param {string} hash The colour as #rrggbb[aa]
 * @return {string} The colour as rgba\(\)
 */
function hashToRgba(hash: string): string {
  // quick return if not a hash
  if (hash[0] !== '#' || (hash.length !== 7 && hash.length !== 9)) return hash;

  const paddedHash = `${hash}${hash.length === 7 ? 'FF' : ''}`;

  return `rgba(${parseInt(paddedHash.substr(1, 2), 16)}, ${parseInt(paddedHash.substr(3, 2), 16)}, ${parseInt(paddedHash.substr(5, 2), 16)}, ${(parseInt(paddedHash.substring(7), 16) / 255).toFixed(
    4
  )})`;
}

/** @description Converts rgb[a]() colour string to a hash colour
 * This function is implemented to work around old browsers not recognsing rgba colours when given in #rrggbbaa form.
 * Most importantly for chrome version 57 as embedded in ez2view v7. As such it is designed to be a workaround so will not error on a badly formed input. It will return it as is.
 * @param {string} rgba The colour as a string: rgb(r,g,b) or rgba(r,g,b,a)
 * @return {string} The colour as a hash #rrggbbaa
 */
function rgbaToHash(rgba: string): string {
  // quick return if not a rgb[a]
  if (rgba.substr(0, 3) !== 'rgb') return rgba;

  let cleanedRgba = rgba.replace('rgba(', '').replace('rgb(', '').replace(')', '').split(',');

  if (cleanedRgba.length !== 3 && cleanedRgba.length !== 4) return rgba;

  if (cleanedRgba.length === 3) cleanedRgba = cleanedRgba.concat(['1.0']);
  cleanedRgba[3] = (parseFloat(cleanedRgba[3]) * 255).toString();

  return `#${cleanedRgba
    .map((x) => {
      const num = parseInt(x).toString(16);
      return `${num.length === 1 ? '0' : ''}${num}`;
    })
    .join('')}`;
}

export { hashToRgba, rgbaToHash };
