import _ from 'lodash';
import moment from 'moment';
import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { TimerFactory, Timer } from '@/gr/common/timer';
import './loadingFillParent';

export interface IArgs {
  delayInMilliseconds?: number;
  showLoading?: boolean;
}

export class Component {
  private _timer: Timer;

  constructor(args: IArgs) {
    const argsWithDefaults = _.defaults({}, args, { delayInMilliseconds: 0, showLoading: false });
    this._timer = new TimerFactory().create().setToFireOnceIn(moment.duration(argsWithDefaults.delayInMilliseconds, 'ms'), () => this.ready(true));
    this.showLoading = argsWithDefaults.showLoading;
  }

  ready = ko.observable(false);
  showLoading: boolean;

  dispose(): void {
    this._timer.dispose();
  }
}

import html from './delayRenderingComponent.html';
defineComponent(() => Component, 'delayRendering', html);
