import { analytics, ConfiguredDataDefinition, ConfiguredDataDefinitionFactory, Messages, InputDataDefinitionsRepository, Metric } from '@/apps/timeSeriesViewer';
import { Result } from '@/gr/common/result';

export class InputDataDefinitionsStore {
  constructor(
    private _configuredDataDefinitions: KnockoutObservableArray<ConfiguredDataDefinition>,
    private _configuredDataDefinitionFactory: ConfiguredDataDefinitionFactory,
    private _isLoading: KnockoutObservable<boolean>,
    private _messages: Messages.Args,
    private _repo: InputDataDefinitionsRepository
  ) {}

  setSelected(input: Metric, value: boolean): Promise<Result<undefined, undefined>> {
    if (value) {
      return this.add(input);
    } else {
      this.remove(input);
      return Promise.resolve(Result.success());
    }
  }

  private async add(id: Metric) {
    const configuredDataDefinitions = this.getConfiguredFromInput(id);
    if (configuredDataDefinitions.length > 0) return Result.success();

    analytics.stepEvent({ Action: 'AddedRawDataDefinition', RawDataDefinition: id.toUniqueString() });
    this._isLoading(true);

    const result = await this._repo.getFromId(id);
    this._isLoading(false);

    return result
      .mapSuccess((input) => {
        const newConfigured = this._configuredDataDefinitionFactory.createFromInput(input);
        this._configuredDataDefinitions.push(newConfigured);
        return Result.success();
      })
      .mapError((error) => {
        this._messages.add(error);
        return Result.error();
      });
  }

  remove(id: Metric): void {
    const configuredDataDefinitions = this.getConfiguredFromInput(id);
    if (configuredDataDefinitions.length === 0) return;
    analytics.stepEvent({ Action: 'RemovedInputDataDefinition', Id: id.toUniqueString() });
    this._configuredDataDefinitions.removeAll(configuredDataDefinitions);
  }

  private getConfiguredFromInput(id: Metric) {
    return this._configuredDataDefinitions().filter((d) => d.input.id.equals(id));
  }
}
