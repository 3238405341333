import html2canvas from 'html2canvas';

async function saveAsImage(element: HTMLElement, fileName = 'Image Captured'): Promise<void> {
  const htmlElement = document.createElement('a');
  htmlElement.setAttribute('download', `${fileName ?? 'Trend'} ${new Date().toISOString()}.png`);
  htmlElement.setAttribute('href', (await html2canvas(element)).toDataURL('image/png').replace('image/png', 'image/octet-stream'));
  htmlElement.click();
}

export { saveAsImage };
