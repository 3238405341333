import { InputDataDefinition, Metric } from '.';

export class SuccessfulInputDataDefinitionResult {
  isSuccess = true;
  id: Metric;
  constructor(public value: InputDataDefinition) {
    this.id = this.value.id;
  }

  static collection(collection: InputDataDefinition[]): SuccessfulInputDataDefinitionResult[] {
    return collection.map((i) => new SuccessfulInputDataDefinitionResult(i));
  }
}
