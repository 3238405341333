import _ from 'lodash';
import { Disposable, IDisposable } from '../disposable';

export function override<T>(target: T, overrides: any, action: (target: T) => void) {
  const override = createOverride(target, overrides);

  try {
    action(target);
    override.dispose();
  } catch (error) {
    override.dispose();
    throw error;
  }
}

export function createOverride<T extends { [key: string]: any }>(target: { [key: string]: any }, overrides: any): IDisposable {
  const overrideKeys = _.keys(overrides);

  const existingProperties: { [key: string]: any } = {};
  _.forEach(overrideKeys, (key) => {
    existingProperties[key] = target[key];
  });

  _.forEach(overrideKeys, (key) => {
    target[key] = overrides[key];
  });

  return new Disposable(() => {
    _.forEach(overrideKeys, (key) => {
      target[key] = existingProperties[key];
    });
  });
}
