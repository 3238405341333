import _ from 'lodash';
import { Disposable } from '@/gr/common/disposable';

export function resetGlobalVariables() {
  // AmCharts modifies window as follows:
  // window.safari = window.safari ? window.safari : {};
  // The first time ag-grid's new Grid() is called, it executes the following, expecting window.safari to be undefined or fully defined:
  // !anyWindow.safari || anyWindow.safari.pushNotification
  // Because AmCharts sets window.safari to an empty object, ag-grid crashes.
  const global = window as any;
  const safari = global.safari;
  if (safari && _.isPlainObject(safari) && _.isEmpty(safari)) {
    delete global.safari;
    return new Disposable(() => (global.safari = safari));
  }
  return Disposable.empty;
}
