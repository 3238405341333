import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import moment from 'moment';
import _ from 'lodash';
import { CompositeDisposable } from '@/gr/common/disposable';
import { Messages } from '@/apps/timeSeriesViewer/components';
import {
  ITrendLinks,
  AppDateTimes,
  ConfiguredDataDefinition,
  OutputChannelContainer,
  TrendDataDefinition,
  InputDataDefinition,
  InputDataDefinitionChooserState,
  ITrendApiLinks
} from '@/apps/timeSeriesViewer/models';

import { CalculatedMetric } from '../dataDefinitions/metrics';

export type TrendStepId = 'RawDataSelection' | 'ConfigureData' | 'Viewer' | 'Publish';

export class Trend {
  private _disposable = new CompositeDisposable();
  private _title = ko.observable<string | null>();

  id!: string | null;
  _etag!: string | null;
  version!: number | null;
  userId!: string | null;
  isDraft = false;
  isPublished = ko.observable(false);
  isPublishedToEveryone = ko.observable(false);
  publishedToOrganisations = ko.observableArray<string>([]);
  productName!: string;
  prereleaseTag!: string;
  appVersion!: string;
  title = ko.pureComputed({ read: () => this._title(), write: (value) => this._title(coalesceTrendName(value)) });
  isReadOnlyMode = ko.observable<boolean>();
  isTrendEmbeddable = ko.observable<boolean>(false);
  currentStepId = ko.observable<TrendStepId>('RawDataSelection');
  lastSavedTime!: moment.Moment;
  creationTime!: moment.Moment;

  description = ko.observable<string>('');

  appDateTimes = new AppDateTimes();

  configuredDataDefinitions = ko.observableArray<ConfiguredDataDefinition>([]);

  selectedInputDataDefinitions: kx.ReadOnlyObservable<InputDataDefinition[]> = ko.pureComputed(() =>
    _(this.configuredDataDefinitions())
      .reject((configured) => configured.input.id instanceof CalculatedMetric)
      .map((configured) => configured.input)
      .uniqBy((i) => i.id.toUniqueString())
      .value()
  );

  outputChannelContainers = ko.observableArray<OutputChannelContainer>([OutputChannelContainer.empty()]);
  selectedOutputChannelContainer = ko.observable(this.outputChannelContainers()[0]);

  dataDefinition = ko
    .pureComputed(() => {
      return new TrendDataDefinition(this.appDateTimes.snapshot(), _.clone(this.configuredDataDefinitions()), this._etag ?? this.lastSavedTime.valueOf().toString());
    })
    .extend({ deferred: true });

  links!: ITrendLinks;
  apiLinks!: ITrendApiLinks;

  rawDataDefinitionChooserState = new InputDataDefinitionChooserState();

  isLoadingPart = ko.observable(false);
  messages = new Messages.ArgsFactory().create();

  dispose(): void {
    this._disposable.dispose();
  }

  isAffectedByRelease(): boolean {
    for (const dataDefinition of this.configuredDataDefinitions()) {
      if (dataDefinition.isAffectedByRelease()) {
        return true;
      }
    }
    return false;
  }
}

export const untitledTrendName = 'Untitled';

export function coalesceTrendName(value: string | null | undefined): string | null {
  return value === undefined || value === null || _.isEmpty(value?.trim()) ? null : value;
}
