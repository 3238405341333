














import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class GrConfirmDialogue extends Vue {
  private value = false;
  private title = 'Confirmation';
  private message = 'Are you sure?';
  private resolve!: (value: boolean | PromiseLike<boolean>) => void;
  private reject!: (reason?: unknown) => void;

  public async activate(title: string, message: string): Promise<boolean> {
    this.title = title;
    this.message = message;
    this.value = true;
    return new Promise<boolean>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  private confirmation(result: boolean) {
    this.resolve(result);
    this.value = false;
  }
}
