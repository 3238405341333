import ko from 'knockout';
import _ from 'lodash';
import kx from '@/gr/common/knockout/extended';
import { joinSingle, rejectUndefined } from '@/gr/common/utils/array';
import {
  InputDataDefinitionChooserState,
  ISelectedFacetValueDto,
  Facet,
  FacetValue,
  Tab,
  Column,
  ISearchResultDto,
  IFacetDto,
  IColumnDto,
  ITabDto,
  IRowDto,
  Row,
  ICellDto,
  Cell,
  ITabSummaryDto,
  ISearchRequestDto,
  IFacetKeyDto,
  FacetKey,
  InputDataDefinition,
  InputDataDefinitionConverter
} from '@/apps/timeSeriesViewer';

export class RawDataDefinitionChooserStateToSearchConverter {
  private readonly _selectedInputDataDefinitionsById: KnockoutComputed<_.Dictionary<InputDataDefinition>>;

  constructor(
    private _selectedInputDataDefinitions: kx.ReadOnlyObservable<InputDataDefinition[]>,
    private _inputDataDefinitionConverter: InputDataDefinitionConverter
  ) {
    this._selectedInputDataDefinitionsById = ko.pureComputed(() => _.keyBy(this._selectedInputDataDefinitions(), (d) => d.id.toUniqueString()));
  }

  updateState(state: InputDataDefinitionChooserState, dto: ISearchResultDto): ISearchRequestDto {
    this.updateTabsFromSummaryDtos(state.tabs, dto.tabs);
    const newSelectedTab = this.updateTabFromDto(state.tabs, dto.selectedTab);
    state.availableFacets(this.facetDtosToModels(dto.facets));
    state.resultCount(dto.resultCount);
    state.selectedTab(newSelectedTab);

    // Capture current state from search results (for caching)
    const resultStateDto = this.stateToSearchDto(state);

    // Selected tab is not visible, so trigger a new search
    if (newSelectedTab != null && !newSelectedTab.isVisible()) {
      const firstVisibleTab = _.find(state.tabs(), (tab) => tab.isVisible());
      if (firstVisibleTab != null) state.selectedTab(firstVisibleTab);
    }

    return resultStateDto;
  }

  stateToSearchDto(state: InputDataDefinitionChooserState): ISearchRequestDto {
    const facetValueDtos: ISelectedFacetValueDto[] = state.selectedFacetValues().map((v) => ({
      facetId: v.facet.id,
      facetValueId: v.id
    }));
    const selectedTab = state.selectedTab();
    const selectedGroupingFacets = selectedTab ? selectedTab.selectedGroupingFacets() : null;
    const selectedGroupingFacetsDto = selectedGroupingFacets ? selectedGroupingFacets.map((g) => ({ id: g.id })) : null;
    const selectedTabDto = selectedTab ? { groupingFacets: selectedGroupingFacetsDto, id: selectedTab.id } : null;

    const dto: ISearchRequestDto = {
      facets: facetValueDtos,
      search: state.search(),
      tab: selectedTabDto
    };

    return dto;
  }

  private facetDtosToModels(facets: IFacetDto[]) {
    return facets.map((dto) => {
      const model = new Facet(dto.id, dto.name);
      model.values = dto.values.map((v) => new FacetValue(v.id, v.name, v.recordCount, model));
      return model;
    });
  }

  private facetKeysToModel(facetKeys: IFacetKeyDto[]) {
    return facetKeys.map((dto) => {
      return new FacetKey(dto.id, dto.name);
    });
  }

  private updateTabsFromSummaryDtos(tabs: KnockoutObservableArray<Tab>, tabDtos: ITabSummaryDto[]) {
    joinSingle(
      tabDtos,
      tabs(),
      (t) => t.id,
      (t) => t.id
    ).forEach(([tabDto, tab]) => {
      if (tabDto != null && tab != null) {
        tab.isVisible(true);
      } else if (tabDto == null && tab != null) {
        tab.isVisible(false);
      } else if (tabDto != null && tab == null) {
        tabs.push(new Tab(tabDto.id, tabDto.name, ko.observableArray<FacetKey>([]), ko.observable(null), ko.observableArray<Column>([]), ko.observableArray<Row>([]), ko.observable(true)));
      }
    });
  }

  private updateTabFromDto(tabs: KnockoutObservableArray<Tab>, tabDto: ITabDto) {
    const tab = _.find(tabs(), (tab) => tab.id === tabDto.id);
    if (tab === undefined) throw new Error('Could not find tab with id ' + tabDto.id);

    tab.columns(this.columnsToModel(tabDto.columns));
    tab.rows(this.rowsToModel(tabDto.rows, tab.columns()));

    this.normalizeScores(tab.rows());

    const availableGroupingFacets = _(this.facetKeysToModel(tabDto.availableGroupingFacets))
      .concat(tab.selectedGroupingFacets() || [])
      .uniqBy((f) => f.id)
      .value();
    const selectedGroupingFacets = rejectUndefined(tabDto.selectedGroupingFacets.map((selectedGroupingFacet) => _.find(availableGroupingFacets, (f) => f.id === selectedGroupingFacet.id)));

    tab.availableGroupingFacets(availableGroupingFacets);
    tab.selectedGroupingFacets(selectedGroupingFacets);

    return tab;
  }

  private normalizeScores(rows: Row[]) {
    const allCells = this.getAllCells(rows);
    const minScore = _(allCells)
      .map((cell) => cell.score)
      .min() as number;
    const maxScore = _(allCells)
      .map((cell) => cell.score)
      .max() as number;
    allCells.forEach((cell) => (cell.score = this.normalizeScore(cell.score, minScore, maxScore)));
  }

  private getAllCells(rows: Row[]): Cell[] {
    if (rows == null) return [];
    const cells = _.flattenDeep([rows.map((row) => row.cells)].concat(rows.map((row) => this.getAllCells(row.children)))) as Cell[];
    return cells;
  }

  private normalizeScore(score: number, minScore: number, maxScore: number) {
    const spread = maxScore - minScore;
    if (spread === 0) return 1;
    const minValue = Math.max(1 - spread / 2, 0);
    return Math.min(Math.max((score - minScore) / spread, minValue), 1);
  }

  private columnsToModel(columns: IColumnDto[]) {
    return columns.map((c) => new Column(c.index, c.name));
  }

  private rowsToModel(rows: IRowDto[] | null, columns: Column[]): Row[] {
    if (rows == null) return [];
    return rows.map((r) => new Row(r.name, this.cellsToModel(r.cells, columns), this.rowsToModel(r.children, columns), ko.observable(true)));
  }

  private cellsToModel(cells: ICellDto[] | null, columns: Column[]) {
    if (cells == null) return [];
    return cells.map((c) => {
      const inputId = this._inputDataDefinitionConverter.idToModel(c.metric);
      const isSelected = ko.pureComputed(() => {
        return this._selectedInputDataDefinitionsById()[inputId.toUniqueString()] != null;
      });
      return new Cell(columns[c.columnIndex], inputId, isSelected, c.score);
    });
  }
}
