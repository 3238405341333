import { DataPointDto, TrendsMetricsApiClient } from '@/repositories';
import { IProgressStatus } from './progressStatus';
import { ITrendDataUpdater } from './trendDataUpdater';

export class ResourcePumps {
  private _resourcesAvailable = new Deferred();
  private _parallelRequestsLimit = 20;

  constructor(
    private _api: TrendsMetricsApiClient,
    private _availableRequests: { configuredMetricId: string; url: string }[],
    private _pendingRequests: { configuredMetricId: string; url: string }[],
    private _trendDataUpdater: ITrendDataUpdater,
    private _progress: IProgressStatus,
    private _signal: AbortSignal,
    private _fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>
  ) {}

  async run(): Promise<void> {
    await delay(0);
    await Promise.all([this.availablePump(), this.pendingPump()]);
  }

  async availablePump(): Promise<void> {
    // continue the download pump while there are both pending and available requests
    while (this._pendingRequests.length + this._availableRequests.length > 0 && !this._signal.aborted) {
      while (this._availableRequests.length > 0 && !this._signal.aborted) {
        const nextSetOfDownloads = this._availableRequests.slice(0, this._parallelRequestsLimit).map(async (request) => {
          const response = await this._fetch(request.url);
          const text = await response.text();
          const result = text ? JSON.parse(text).map((i: unknown) => DataPointDto.fromJS(i)) : [];

          this._trendDataUpdater.add(request.configuredMetricId, result);
          this._availableRequests.removeIfExists(request);
          this._progress.increment();
        });
        await Promise.all(nextSetOfDownloads);
      }

      // exit if aborted
      if (this._signal.aborted) {
        break;
      }
      this._progress.setMessage(`Displaying data`);
      // putting a delay in here seems to get knockout to update the progress indicator
      await delay(0);
      this._trendDataUpdater.update();
      // await delay(0);

      // wait for the pending resource to become available
      if (this._pendingRequests.length > 0) {
        await this._resourcesAvailable.wait();
      }
    }

    // make sure update is called at least once if it wasn't aborted
    if (!this._signal.aborted) this._trendDataUpdater.update();
  }

  async pendingPump(): Promise<void> {
    const initialDelay = 500;
    const maxDelay = 10 * 60 * 1000;

    let delayBetweenPumps = initialDelay;
    let attempts = 0;
    while (this._pendingRequests.length > 0 && !this._signal.aborted) {
      const requestsNowAvailable: { configuredMetricId: string; url: string }[] = [];

      // check the status of every pending request
      for (let i = 0; i < this._pendingRequests.length; i += this._parallelRequestsLimit) {
        if (this._signal.aborted) {
          break;
        }

        const nextSetOfChecks = this._pendingRequests.slice(i, i + this._parallelRequestsLimit).map(async (request) => ({ request, response: await this._api.status(request.url) }));
        const statusChecks = await Promise.all(nextSetOfChecks);
        requestsNowAvailable.push(
          ...statusChecks
            .filter((x) => x.response.status == 200)
            .map((x) => {
              return x.request;
            })
        );
      }

      if (this._signal.aborted) {
        break;
      }

      // move from pending to available
      for (const request of requestsNowAvailable) {
        this._availableRequests.push({ configuredMetricId: request.configuredMetricId, url: request.url });
        this._pendingRequests.removeIfExists(request);
      }

      // signal that new resources are available to the available pump
      if (requestsNowAvailable.length > 0) {
        this._resourcesAvailable.signal();
      }

      // attempt
      if (attempts++ > 60) {
        attempts = 0;
        delayBetweenPumps = 2 * delayBetweenPumps;
      }

      // end if the delay is now longer than the max
      if (delayBetweenPumps > maxDelay) {
        // abandon pending requests
        this._pendingRequests = [];
        break;
      }

      // delay between checking the status's again
      await delay(delayBetweenPumps);
    }
  }
}

async function delay(ms: number): Promise<void> {
  await new Promise<void>((resolve) => setTimeout(() => resolve(), ms));
}

class Deferred {
  private _resolve!: () => void | undefined;

  async wait() {
    await new Promise<void>((resolve) => (this._resolve = resolve));
  }

  signal() {
    if (this._resolve) this._resolve();
  }
}
