import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import { IOutputChannelStateFactory, TrendDataDefinition, TrendData, TrendSummaryForChannels } from '@/apps/timeSeriesViewer';

export class TableState {
  constructor(public dataDefinition: kx.ReadOnlyObservable<TrendDataDefinition>) {}

  trendData = ko.observable<TrendData | null>(null);
}

export class TableStateFactory implements IOutputChannelStateFactory {
  create(trendSummary: TrendSummaryForChannels): TableState {
    return new TableState(trendSummary.dataDefinition);
  }
}
