import ko from 'knockout';
import moment from 'moment';
import _ from 'lodash';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import '@/gr/common/knockout/bindings/fadeVisible';

export class Component {
  constructor(private _args: Args) {
    this.messages = this._args.messages;
  }
  messages;
  messageCount = ko.pureComputed(() => this.messages().length);
  showMore = ko.observable(true);
  css = ko.pureComputed(() => {
    const messages = this.messages();
    return messages.length > 0 ? messages[0].css : 'information';
  });

  clear(): void {
    this._args.clear();
  }

  toggleMore(): void {
    this.showMore(!this.showMore());
  }
}

export interface IError {
  timestamp: moment.Moment;
  html: string;
  type: string;
  css: string;
}

export type MessageType = 'information' | 'warning' | 'error';

export class Args {
  constructor(public messages: KnockoutObservableArray<IError>) {}

  clear(): void {
    this.messages([]);
  }

  add(html: string, type: MessageType = 'error'): void {
    this.messages.unshift({ timestamp: moment(), type: type === 'information' ? '' : ' - ' + _.upperFirst(type), html: html, css: `gr-${type}` });
  }
}

export class ArgsFactory {
  create(): Args {
    return new Args(ko.observableArray<IError>([]));
  }
}

import html from './messagesComponent.html';
defineComponent(() => Component, 'messages', html);
require('./messagesComponent.less');
