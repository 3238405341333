import moment from 'moment';
import _ from 'lodash';
import { IDataPointsForTime } from '@/apps/timeSeriesViewer';

export class DataPointAggregator {
  aggregateData(dataPoints: IDataPointsForTime[], maxPoints: number, timeAxisMin: moment.Moment, timeAxisMax: moment.Moment): IDataPointsForTime[] {
    const startIndex = _.sortedIndexBy(dataPoints, { timeStamp: timeAxisMin.format() }, 'timeStamp');
    const endIndex = _.sortedIndexBy(dataPoints, { timeStamp: timeAxisMax.format() }, 'timeStamp');

    if (endIndex - startIndex > maxPoints) {
      const numOfBuckets = Math.ceil(maxPoints / 2);
      const numOfPointsPerBucket = Math.ceil((endIndex - startIndex) / numOfBuckets);
      const bucketedData = _.chunk(
        _.concat(
          dataPoints[0],
          _.slice(
            dataPoints, // leave a few buckets on either side (if there's room)
            Math.max(startIndex - 10 * numOfPointsPerBucket, 0),
            Math.min(endIndex + 10 * numOfPointsPerBucket, dataPoints.length)
          ),
          dataPoints[dataPoints.length - 1]
        ),
        numOfPointsPerBucket
      );
      let aggregatedDataPoints: IDataPointsForTime[] = [];
      // add first piece so it's always there
      aggregatedDataPoints.push(dataPoints[0]);
      _.forEach(bucketedData, (bucket, index) => {
        // skip first and last datapoints (already added)
        if (index === 0 || index === dataPoints.length - 1) {
          return;
        }

        const min: { [series: string]: IDataPointsForTime } = {};
        const max: { [series: string]: IDataPointsForTime } = {};

        _.forEach(bucket, (dataPoint) => {
          for (const key in dataPoint) {
            if (Object.prototype.hasOwnProperty.call(dataPoint, key) && key !== 'timeStamp') {
              if (_.isNil(min[key])) {
                this.updateMaxOrMin(dataPoint, min, key);
              } else if (dataPoint[key] < min[key][key]) {
                this.updateMaxOrMin(dataPoint, min, key);
              }
              if (_.isNil(max[key])) {
                this.updateMaxOrMin(dataPoint, max, key);
              } else if (dataPoint[key] > max[key][key]) {
                this.updateMaxOrMin(dataPoint, max, key);
              }
            }
          }
        });
        let bucketData = this.addMinsOrMaxes(min, []);
        bucketData = this.addMinsOrMaxes(max, bucketData);
        aggregatedDataPoints = _.concat(aggregatedDataPoints, bucketData);
      });
      // add last one
      aggregatedDataPoints.push(dataPoints[dataPoints.length - 1]);
      return aggregatedDataPoints;
    }
    return dataPoints;
  }

  private updateMaxOrMin(thisDataPoint: IDataPointsForTime, minOrMax: { [series: string]: IDataPointsForTime }, key: string): void {
    const tempMin = {
      timeStamp: thisDataPoint.timeStamp
    } as { [name: string]: string; timeStamp: string };
    tempMin[key] = thisDataPoint[key];
    minOrMax[key] = tempMin;
  }

  private addMinsOrMaxes(minOrMax: { [series: string]: IDataPointsForTime }, dataPoints: IDataPointsForTime[]): IDataPointsForTime[] {
    _.forEach(minOrMax, (value, key: string) => {
      let index = _.findIndex(dataPoints, (o) => {
        return o.timeStamp === value.timeStamp;
      });
      if (index !== -1) {
        dataPoints[index][key] = value[key];
      } else {
        index = _.sortedIndexBy(dataPoints, value, 'timeStamp');
        if (index === dataPoints.length) {
          // will save time if on end
          dataPoints.push(value);
        } else {
          dataPoints = _.concat(_.slice(dataPoints, 0, index), value, _.slice(dataPoints, index));
        }
      }
    });
    return dataPoints;
  }
}
