import _ from 'lodash';
import { ColourProvider, ConfiguredDataDefinitionFactory, InputDataDefinitionFactory, Trend } from '@/apps/timeSeriesViewer/models';
import { InputDataDefinitionsStore, TimeTravelController } from '@/apps/timeSeriesViewer/stores';
import {
  analytics,
  AutoRefreshService,
  Clock,
  CsvDownloadService,
  FileNamingService,
  PolicyAuthorisationsService,
  ProvideTrendUrls,
  refreshTopmostSameOriginWindow,
  TimeController,
  TrendSaver
} from '@/apps/timeSeriesViewer/services';
import {
  ConfiguredDataDefinitionConverter,
  InputDataDefinitionConverter,
  RawDataDefinitionChooserStateToSearchConverter,
  TrendConverterFactory,
  TrendDataConverter,
  TrendDataDefinitionConverter
} from '@/apps/timeSeriesViewer/converters';
import {
  ArtifactsApi,
  ArtifactsRepository,
  InputDataDefinitionsApi,
  InputDataDefinitionsRepository,
  IPolicyAuthorisationDto,
  OrganisationsApi,
  OrganisationsRepository,
  TrendDataRepository,
  TrendsApi,
  TrendsRepository,
  TrendTemplatesApi,
  TrendTemplatesRepository,
  VersionedApiHttpRequester
} from '@/apps/timeSeriesViewer/repos';

import { IDisposable } from '@/gr/common/disposable';
import { TimerFactory } from '@/gr/common/timer';
import { GlobalRoamAuthentication } from '@gr/authentication';
import { TrendsMetricsApi } from '@/apps/timeSeriesViewer/repos/api/trendsMetricsApi';
import { ITimeControl } from '@/common/timeContext';

export class TrendServices implements IDisposable {
  timeController: TimeController;

  private _versionedApiRequester: VersionedApiHttpRequester;
  private _trendsMetrics: TrendsMetricsApi;
  private _timerFactory = new TimerFactory();
  private _inputDataDefinitionsApi: InputDataDefinitionsApi;
  private _inputDataDefinitionConverter = new InputDataDefinitionConverter();
  private _inputDataDefinitionFactory = new InputDataDefinitionFactory();
  private _configuredDataDefinitionConverter = new ConfiguredDataDefinitionConverter(this._inputDataDefinitionConverter, this._inputDataDefinitionFactory);
  private _trendDataConverter = new TrendDataConverter(this._configuredDataDefinitionConverter);
  private _trendDataDefinitionConverter = new TrendDataDefinitionConverter(this._configuredDataDefinitionConverter);
  private _rawDataDefinitionChooserStateToSearchConverter: RawDataDefinitionChooserStateToSearchConverter;
  private _trendConverterFactory: TrendConverterFactory;
  private _trendTemplatesApi: TrendTemplatesApi;
  private _artifactsApi: ArtifactsApi;
  private _organisationsApi: OrganisationsApi;
  private _fileNamingService = _.memoize(() => FileNamingService.create(this._trend));

  trendUrls = new ProvideTrendUrls();

  trendApi: TrendsApi;
  trendDataRepository: TrendDataRepository;
  colourProvider = new ColourProvider();
  inputDataDefinitionsRepository: InputDataDefinitionsRepository;
  trendsRepository: TrendsRepository;
  trendTemplatesRepository: TrendTemplatesRepository;
  artifactsRepository: ArtifactsRepository;
  organisationsRepository: OrganisationsRepository;
  trendSaver: TrendSaver;
  policyAuthorisations: PolicyAuthorisationsService;
  clock: Clock;
  appDateTimesStore: TimeTravelController;
  configuredDataDefinitionFactory: ConfiguredDataDefinitionFactory;
  inputDataDefinitionsStore: InputDataDefinitionsStore;
  csvDownloadService = _.memoize(() => new CsvDownloadService(this.trendDataRepository, this._fileNamingService()));

  constructor(
    private _trend: Trend,
    private _apiVersion: string,
    private _timeControl: ITimeControl,
    private _onNewVersion: () => void,
    private _policyAuthorisations: IPolicyAuthorisationDto[] | null,
    private _authentication: GlobalRoamAuthentication
  ) {
    this.timeController = new TimeController(this._timeControl);
    this._versionedApiRequester = new VersionedApiHttpRequester('/', this._apiVersion, this._onNewVersion, analytics.raw.observer.javascriptSessionId.toString(), this._authentication);
    this._trendsMetrics = new TrendsMetricsApi(this._trendDataDefinitionConverter, this._trendDataConverter);
    this._inputDataDefinitionsApi = new InputDataDefinitionsApi(this._versionedApiRequester);

    this._rawDataDefinitionChooserStateToSearchConverter = new RawDataDefinitionChooserStateToSearchConverter(this._trend.selectedInputDataDefinitions, this._inputDataDefinitionConverter);
    this._trendConverterFactory = new TrendConverterFactory(this._trend, this);
    this._trendTemplatesApi = new TrendTemplatesApi(this._versionedApiRequester);
    this._artifactsApi = new ArtifactsApi(this._versionedApiRequester);
    this._organisationsApi = new OrganisationsApi(this._versionedApiRequester);
    this.trendApi = new TrendsApi(this._versionedApiRequester);
    this.trendDataRepository = new TrendDataRepository(this._trendDataDefinitionConverter, this._trendDataConverter, this._trendsMetrics);
    this.inputDataDefinitionsRepository = new InputDataDefinitionsRepository(
      this._inputDataDefinitionsApi,
      this._rawDataDefinitionChooserStateToSearchConverter,
      this._inputDataDefinitionConverter,
      this._inputDataDefinitionFactory
    );

    this.trendsRepository = new TrendsRepository(this.inputDataDefinitionsRepository, this.trendApi, this._trendConverterFactory);
    this.trendTemplatesRepository = new TrendTemplatesRepository(this._trendTemplatesApi, this._trendConverterFactory);
    this.artifactsRepository = new ArtifactsRepository(this._artifactsApi);
    this.organisationsRepository = new OrganisationsRepository(this._organisationsApi);
    this.trendSaver = new TrendSaver(this._trend, this.trendsRepository);
    this.policyAuthorisations = new PolicyAuthorisationsService(this._policyAuthorisations || [], this._policyAuthorisations == null);
    this.clock = Clock.from(this._trend.appDateTimes.utcOffsetDefinition);
    this.appDateTimesStore = new TimeTravelController(this._trend, this._timeControl, this._timerFactory, this.policyAuthorisations, this.clock);
    this.configuredDataDefinitionFactory = new ConfiguredDataDefinitionFactory(this._trend.configuredDataDefinitions, this._inputDataDefinitionFactory);
    this.inputDataDefinitionsStore = new InputDataDefinitionsStore(
      this._trend.configuredDataDefinitions,
      this.configuredDataDefinitionFactory,
      this._trend.isLoadingPart,
      this._trend.messages,
      this.inputDataDefinitionsRepository
    );
  }

  get authentication(): GlobalRoamAuthentication {
    return this._authentication;
  }

  private _autoRefreshService = new AutoRefreshService(this._timerFactory, refreshTopmostSameOriginWindow).start();

  dispose(): void {
    this._autoRefreshService.dispose();
  }
}
