import { OutputChannelTypeId } from '@/apps/timeSeriesViewer';

export function getOutputChannelCss(channelType: OutputChannelTypeId): string {
  switch (channelType) {
    case 'API':
      return 'fa-code';
    case 'CHART':
      return 'fa-chart-line';
    case 'FILE':
      return 'fa-file';
    case 'NEW':
      return 'fa-question-circle';
    case 'TABLE':
      return 'fa-table';
  }
}
