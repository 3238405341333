import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { Facet, FacetValue, InputDataDefinitionChooserState } from '@/apps/timeSeriesViewer';
import '@/gr/common/knockout/bindings/fastForEach';

export class Component {
  private _disposable = new CompositeDisposable();

  readonly availableFacets;
  readonly selectedFacetValues;
  readonly searchQuery;
  readonly hasSearched = ko.pureComputed(() => this.searchQuery().length > 0);
  isFocused = ko.observable(true);
  triggerImmediateSearch;

  constructor(private _args: Args) {
    this.availableFacets = this._args.availableFacets;
    this.selectedFacetValues = this._args.selectedFacetValues;
    this.searchQuery = this._args.search;
    this.triggerImmediateSearch = this._args.triggerImmediateSearch;
  }

  dispose(): void {
    this._disposable.dispose();
  }

  onKeyPress(vm: this, event: KeyboardEvent): boolean {
    if (event.keyCode === 13 /* enter */) {
      this._args.triggerImmediateSearch();
    }
    return true; /* allow default event action */
  }

  removeSelectedFacetValue(facetValue: FacetValue): void {
    this.selectedFacetValues.remove(facetValue);
  }

  clearSearch(): void {
    this.searchQuery('');
    this._args.triggerImmediateSearch();
  }
}

export class Args {
  constructor(
    public search: KnockoutObservable<string>,
    public availableFacets: KnockoutObservableArray<Facet>,
    public selectedFacetValues: KnockoutObservableArray<FacetValue>,
    public triggerImmediateSearch: () => void
  ) {}
}

export class ArgsFactory {
  create(state: InputDataDefinitionChooserState, triggerImmediateSearch: () => void): Args {
    return new Args(state.search, state.availableFacets, state.selectedFacetValues, triggerImmediateSearch);
  }
}

import html from './searchBoxComponent.html';
defineComponent(() => Component, 'searchBox', html);
require('./searchBoxComponent.less');
