import _ from 'lodash';
import { IDataPointsForTime, ChartAxis, ICompleteChartSeries } from '@/apps/timeSeriesViewer';

export type AxisMinMax = { axis: ChartAxis; isEmpty: true; min: undefined; max: undefined } | { axis: ChartAxis; isEmpty: false; min: number; max: number };
export type SeriesMinMax = { series: ICompleteChartSeries; isEmpty: true; min: undefined; max: undefined } | { series: ICompleteChartSeries; isEmpty: false; min: number; max: number };

export class DataPointMinMaxCalculator {
  calculateMinMaxValue(dataPoints: IDataPointsForTime[], seriesCollection: ICompleteChartSeries[]): { max: number | undefined; min: number | undefined } {
    const seriesMinMax = this.calculateMinMaxValuePerSeries(dataPoints, seriesCollection);

    return {
      max: _(seriesMinMax)
        .map((s) => s.max)
        .max(),
      min: _(seriesMinMax)
        .map((s) => s.min)
        .min()
    };
  }

  calculateMinMaxValuePerAxis(dataPoints: IDataPointsForTime[], seriesCollection: ICompleteChartSeries[], axes: ChartAxis[]): AxisMinMax[] {
    const seriesMinMax = this.calculateMinMaxValuePerSeries(dataPoints, seriesCollection);

    return _.map(axes, (axis) => {
      const seriesOnAxis = seriesMinMax.filter((s) => s.series.axis()?.id === axis.id);
      const minMax = {
        axis: axis,
        max: _(seriesOnAxis)
          .map((s) => s.max)
          .max(),
        min: _(seriesOnAxis)
          .map((s) => s.min)
          .min()
      } as AxisMinMax;
      minMax.isEmpty = minMax.min === undefined || minMax.max === undefined;
      return minMax;
    });
  }

  calculateMinMaxValuePerSeries(dataPoints: IDataPointsForTime[], seriesCollection: ICompleteChartSeries[]): SeriesMinMax[] {
    return _.map(seriesCollection, (series) => {
      const minMax = {
        series: series,
        max: _(dataPoints)
          .map((d) => d[series.id])
          .max() as number | undefined,
        min: _(dataPoints)
          .map((d) => d[series.id])
          .min() as number | undefined
      } as SeriesMinMax;
      minMax.isEmpty = minMax.min === undefined || minMax.max === undefined;
      return minMax;
    });
  }
}
