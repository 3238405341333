import ko from 'knockout';
import * as log from '@/gr/common/log';
import _ from 'lodash';
import kx from '@/gr/common/knockout/extended';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { ChartState, Trend, TrendDataDefinition, AmChart, TrendDataRepository, Messages, ConfigureChart, TrendServices, Download, LoadingComponentArgs } from '@/apps/timeSeriesViewer';
import '@/apps/timeSeriesViewer/components/delayRenderingComponent';

export class ChartArgs {
  constructor(
    readonly state: ChartState,
    readonly trendDataDefinition: kx.ReadOnlyObservable<TrendDataDefinition>,
    readonly trendDataRepository: TrendDataRepository,
    readonly configureChartComponentArgsFactory: ConfigureChart.ArgsFactory,
    readonly amChartArgsFactory: AmChart.ArgsFactory,
    readonly messagesArgsFactory: Messages.ArgsFactory,
    readonly downloadComponentFactory: Download.ArgsFactory
  ) {}
}

export class ChartArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(state: ChartState): ChartArgs {
    return new ChartArgs(
      state,
      this._trend.dataDefinition,
      this._services.trendDataRepository,
      new ConfigureChart.ArgsFactory(this._trend.links),
      new AmChart.ArgsFactory(this._services, this._trend),
      new Messages.ArgsFactory(),
      new Download.ArgsFactory(this._services)
    );
  }
}

export class ChartComponent {
  private _disposable = new CompositeDisposable();

  isLoading: KnockoutObservable<boolean>;
  messages;
  hasOptionsPanel;
  hasDownloadButton;
  amChart;
  configureChart;
  download;
  loadingArgs;

  constructor(private _args: ChartArgs) {
    this.isLoading = ko.observable(true);
    this.messages = this._args.messagesArgsFactory.create();
    this.hasOptionsPanel = this._args.state.hasOptionsPanel;
    this.hasDownloadButton = this._args.state.hasDownloadButton;
    this.amChart = this._args.amChartArgsFactory.create(this._args.state);
    this.configureChart = this._args.configureChartComponentArgsFactory.create(this._args.state, this.amChart.chartFunctions);
    this.download = this._args.downloadComponentFactory.create(() => this._args.trendDataDefinition(), this.messages);
    this.loadingArgs = new LoadingComponentArgs();

    this._disposable.add(
      ko.computed(() => {
        _.forEach(this._args.state.trendData()?.messages, (message) => this.messages.add(message.html, message.type));
      })
    );

    this._disposable.add(
      ko.computed(async () => {
        this.messages.clear();
        this.isLoading(true);
        try {
          await this._args.trendDataRepository.fillTrendData(this._args.trendDataDefinition(), this._args.state.trendData, this.loadingArgs.description, this.isLoading);
        } catch (error) {
          this.messages.add(`Failed to load data for chart`);
          log.errorEx(error, 'Failed to load data for chart');
        } finally {
          this.isLoading(false);
        }
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }
}

import html from './chart.html';
defineComponent(() => ChartComponent, 'chart', html);
require('./chart.less');
