import * as log from '@/gr/common/log';
import { ErrorResult, Result, SuccessResult } from '@/gr/common/result';
import { ErrorHelper, IHttpRequester, ITrendResourceActionDto, nil, SuccessHttpResponse } from '@/apps/timeSeriesViewer';

export class ArtifactsApi {
  constructor(private _requester: IHttpRequester) {}

  async publish(action: ITrendResourceActionDto): Promise<Result<void, string>> {
    const response = await this._requester.ajax<void>({ url: action.resourceUri, type: action.method });
    if (response.isSuccess) {
      return Result.success();
    } else {
      log.error('Publishing trend failed with {@error}', response);
      return Result.error(ErrorHelper.Message.getForAjaxDataRequest(response, "We couldn't publish your trend."));
    }
  }

  async unpublish(action: ITrendResourceActionDto): Promise<Result<void, string>> {
    const response = await this._requester.ajax<void>({ url: action.resourceUri, type: action.method });
    if (response.isSuccess) {
      return Result.success();
    } else {
      log.error('Unpublishing trend failed with {@error}', response);
      return Result.error(ErrorHelper.Message.getForAjaxDataRequest(response, "We couldn't unpublish your trend."));
    }
  }

  async getLastSaved(trendId: string): Promise<ErrorResult<never, string> | SuccessResult<string | null | undefined, never>> {
    const response = await this._requester.get<string | nil>({ relativeUrl: `/api/trends/${encodeURIComponent(trendId)}/artifact/lastsaved` });
    return response.isSuccess ? Result.success((response as SuccessHttpResponse<string | nil>).data) : Result.error("We couldn't retrieve when your trend was last saved.");
  }
}
