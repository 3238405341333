import { IModalIframeService, IModalIframeServiceFactory, ModalIframeService } from './modalIframeService';
import { IModalContentService, IModal } from '../../windowsApis/modal/modalContentService';

export class ModalCrossOriginIframeServiceFactory implements IModalIframeServiceFactory {
  constructor(
    private _modalService: IModalContentService,
    private _sameOriginPathToBlankPage: string
  ) {}

  create(): Promise<IModalIframeService> {
    return this._modalService.create(this._sameOriginPathToBlankPage).then((modal) => new ModalCrossOriginIframeService(modal));
  }
}

class ModalCrossOriginIframeService extends ModalIframeService {
  constructor(private _modal: IModal) {
    super(_modal.window);
  }

  showInternal() {
    return this._modal.show();
  }

  hideInternal() {
    return this._modal.hide();
  }
}
