import _ from 'lodash';
import moment from 'moment';
import { MomentConverter } from '@/apps/timeSeriesViewer';
import AmCharts from '@/libs/amcharts';

export class AmChartCurrentPointInTimeLine {
  private _id = 'CurrentPointInTime';
  private _momentConverter = new MomentConverter();

  update(chart: AmCharts.AmSerialChart, now: moment.Moment, offset: moment.Duration): void {
    let guide = _.find(chart.categoryAxis.guides, (g) => g.id === this._id);
    if (!guide) {
      guide = this.createCurrentPointInTimeGuideLine(this._id);
      chart.categoryAxis.addGuide(guide);
    }
    guide.date = this._momentConverter.convertToMomentInLocalOffsetWithLocalTimeAsIfInDesiredOffset(now, offset).toISOString();
  }

  private createCurrentPointInTimeGuideLine(id: string) {
    // const guide = new AmCharts.Guide();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const guide: any = {};
    guide.id = id;
    guide.lineColor = '#CC0000';
    guide.lineAlpha = 1;
    guide.dashLength = 2;
    guide.inside = true;
    guide.labelRotation = 90;
    guide.label = 'current point in time';
    return guide;
  }
}
