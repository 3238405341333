import { Trend, TrendData, DateTimeFormatter, untitledTrendName } from '@/apps/timeSeriesViewer';

export class FileNamingService {
  static create(trend: Trend): FileNamingService {
    return new FileNamingService(trend.productName, trend.title);
  }

  constructor(
    private _productName: string,
    private _trendName: KnockoutComputed<string | null | undefined>
  ) {}

  getDefaultFileName(trendData: TrendData | null): string {
    return [this._productName, this._trendName() || untitledTrendName, trendData ? DateTimeFormatter.dateTime(trendData.appDateTimes.now(), trendData.utcOffset).all : null]
      .filter((x) => x != null)
      .join(' - ');
  }
}
