import ko from 'knockout';
export function defineComponent<TViewModelParams>(getViewModelConstructor: () => new (params: TViewModelParams, element: Node) => any, componentName: string, html: string) {
  ko.components.register(componentName, {
    viewModel: {
      createViewModel: (params: TViewModelParams, componentInfo: KnockoutComponentTypes.ComponentInfo) => {
        const viewModelConstructor = getViewModelConstructor();
        return new viewModelConstructor(params, componentInfo.element);
      }
    },
    template: html,
    synchronous: true
  } as any);
}
