import moment from 'moment';

export class MomentConverter {
  convertToMomentInLocalOffsetWithLocalTimeAsIfInDesiredOffset(dateTime: moment.Moment | string, desiredUtcOffset: moment.Duration): moment.Moment {
    const desiredUtcOffsetInMinutes = desiredUtcOffset.asMinutes();

    // Clone moment and convert to local time
    const dateTimeInLocalTime = moment(dateTime).local();

    // Get the local offset at <dateTime> - important in the case where the offset changes over time
    const localUtcOffsetInMinutes = dateTimeInLocalTime.utcOffset();

    // Convert to desired offset, preserving the instant in universal time (this is the correct time in the desired offset)
    const inDesiredTime = dateTimeInLocalTime.utcOffset(desiredUtcOffsetInMinutes);

    // Convert to local offset, preserving the instant in *local* time (rather than universal time). This is now lying about the time.
    return inDesiredTime.utcOffset(localUtcOffsetInMinutes, /* preserve local time */ true);
  }

  convertFromMomentInLocalOffsetWithLocalTimeAsIfInDesiredOffset(dateTime: moment.Moment, desiredUtcOffset: moment.Duration): moment.Moment {
    // Create a moment and convert into the desired offset but keep the time (as if it was in the desired offset) constant
    return moment(dateTime).utcOffset(desiredUtcOffset.asMinutes(), /* preserve local time */ true);
  }
}
