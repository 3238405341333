import _ from 'lodash';

interface IErrorHandler {
  showModal(html: string): void;
}

class Message {
  public static supportEmail = 'support@global-roam.com';

  public static getForAjaxDataRequest = (xhr: { status: number; statusText: string }, message = "We couldn't retrieve the data you requested."): string => {
    return (
      '<p><strong>Sorry! ' +
      message +
      '</strong></p>' +
      '<p>Here are some things you can try:</p>' +
      '<p>' +
      '<ul>' +
      '<li>Check your internet connection. Are you using a proxy?</li>' +
      '<li>Try again in a moment</li>' +
      "<li>Contact our support team at <a href='mailto:" +
      Message.supportEmail +
      "'>" +
      Message.supportEmail +
      '</a></li>' +
      '</ul>' +
      '</p>' +
      "<div style='font-size: 0.8em; margin-top: 10px; line-height: 1'>" +
      '<strong>Technical Details</strong> - ' +
      xhr.status +
      ': ' +
      xhr.statusText +
      '</div>'
    );
  };

  public static getGeneric = (): string => {
    return (
      '<p><strong>Sorry! Something went wrong.</strong></p>' +
      '<p>Here are some things you can try:</p>' +
      '<p>' +
      '<ul>' +
      '<li>Try again in a moment</li>' +
      "<li>Contact our support team at <a href='mailto:" +
      Message.supportEmail +
      "'>" +
      Message.supportEmail +
      '</a></li>' +
      '</ul>' +
      '</p>'
    );
  };

  public static getAnnouncement = (message: string, details: string): string => {
    return `
            <p>
                <strong>${message}</strong>
            </p>
            <div style='font-size: 0.8em; margin-top: 10px; line-height: 1'>
                <strong>Further Details</strong> - 
                <p>${details}</p>
            </div>`;
  };

  public static getGenericWithMessage = (message: string, details: string): string => {
    return `
            <p>
                <strong>Sorry! ${message}</strong>
            </p>
            <ul>
                <li>Try again in a moment</li>
                <li>Contact our support team at <a href='mailto:${Message.supportEmail}'>${Message.supportEmail}</a></li>
            </ul>
            <div style='font-size: 0.8em; margin-top: 10px; line-height: 1'>
                <strong>Technical Details</strong> - 
                <p>${details}</p>
            </div>`;
  };
}

class SummaryObject {
  public static getForAjaxRequest = (xhr: { status: number; statusText: string; responseText: string }): { code: number; codeText: string; content: string } => {
    const content = _.isString(xhr.responseText) ? _.truncate(xhr.responseText, { length: 1000, omission: '...' }) : xhr.responseText;
    return { code: xhr.status, codeText: xhr.statusText, content: content };
  };
}

export class ErrorHelper {
  private static _errorHandler: IErrorHandler = {
    showModal: () => {
      // do nothing
    }
  };

  static showModal(html: string): void {
    this._errorHandler.showModal(html);
  }

  static setErrorHandler(errorHandler: IErrorHandler): void {
    this._errorHandler = errorHandler;
  }

  public static Message = { ...Message };
  public static SummaryObject = { ...SummaryObject };

  public static isAjaxRequest = (exception: Response): boolean => !_.isUndefined(exception.status) && !_.isUndefined(exception.statusText);
}

export class MessageHelper {
  public static Message = { ...Message };
}
