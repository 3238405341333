import _ from 'lodash';
import ko from 'knockout';
import $ from 'jquery';

export interface ITextAnimateArgs {
  text: KnockoutObservable<string> | string;
  animateDurationInSeconds?: KnockoutObservable<number> | number;
  holdDurationInSeconds?: KnockoutObservable<number> | number;
}

export type FullyDefined<T> = { [P in keyof T]: T[P] };

ko.bindingHandlers.textAnimate = {
  init: (element, valueAccessor) => {
    const $element = $(element);

    let lastAnimationPromise: JQueryPromise<any> = $.Deferred().resolve().promise();
    let lastText = '';

    const computed = ko.computed(() => {
      const args = ko.unwrap(valueAccessor());
      const argsWithDefaults = defaults(args, { animateDurationInSeconds: 1, holdDurationInSeconds: 1 });
      const text = ko.unwrap(args.text);
      const animateDurationInMilliseconds = ko.unwrap(argsWithDefaults.animateDurationInSeconds) * 1000;
      const holdDurationInMilliseconds = ko.unwrap(argsWithDefaults.holdDurationInSeconds) * 1000;

      if (text === lastText) return;

      lastText = text;

      lastAnimationPromise = lastAnimationPromise.then(() => {
        return $element
          .fadeOut(animateDurationInMilliseconds)
          .promise()
          .then(() => $element.text(text).fadeIn(animateDurationInMilliseconds).delay(holdDurationInMilliseconds).promise());
      });
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => computed.dispose());
  }
};

function defaults<T, TDefaults>(target: T, defaults: TDefaults): T & TDefaults {
  return _.defaults(target, defaults) as T & TDefaults;
}
