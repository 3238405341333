import _ from 'lodash';
import { DataPointMinMaxCalculator, IDataPointsForTime, ChartAxis, ICompleteChartSeries } from '@/apps/timeSeriesViewer';
import { rejectUndefined } from '@/gr/common/utils/array';
import AmCharts from '@/libs/amcharts';

export class AmChartAlignZeroOnYAxesCalculator {
  private _minMaxCalculator = new DataPointMinMaxCalculator();

  updateValueAxesToAlignZero(valueAxes: AmCharts.ValueAxis[], dataPoints: IDataPointsForTime[], axes: ChartAxis[], series: ICompleteChartSeries[]): void {
    const axisMinMaxValues = rejectUndefined(_.map(this._minMaxCalculator.calculateMinMaxValuePerAxis(dataPoints, series, axes), (minMax) => (minMax.isEmpty ? undefined : minMax)));

    const axisValues = _.map(axisMinMaxValues, (axis) => {
      return {
        axis: axis.axis,
        // valueAxis: _.find(valueAxes, valueAxis => valueAxis.id === axis.axis.id) as AmCharts.ValueAxis | undefined,
        valueAxis: valueAxes.find((valueAxis) => valueAxis.id === axis.axis.id),
        maxAbsolute: _.max([Math.abs(axis.min), Math.abs(axis.max)]) as number,
        min: axis.min,
        max: axis.max
      };
    });

    const smallestAxis = _.minBy(axisValues, (axis) => axis.maxAbsolute);
    if (smallestAxis === undefined || smallestAxis.valueAxis === undefined) return;

    const axisValuesRelativeToSmallest = _.map(axisValues, (axis) => {
      const synchronizationMultiplier = Math.ceil(axis.maxAbsolute / smallestAxis.maxAbsolute);
      return {
        valueAxis: axis.valueAxis,
        synchronizationMultiplier: synchronizationMultiplier,
        min: axis.min / synchronizationMultiplier,
        max: axis.max / synchronizationMultiplier
      };
    });

    const minRelativeToSmallest = _(axisValuesRelativeToSmallest)
      .map((value) => value.min)
      .min() as number;
    const maxRelativeToSmallest = _(axisValuesRelativeToSmallest)
      .map((value) => value.max)
      .max() as number;

    const smallestValueAxis = smallestAxis.valueAxis;
    smallestValueAxis.maximum = maxRelativeToSmallest;
    smallestValueAxis.minimum = minRelativeToSmallest;

    axisValuesRelativeToSmallest.forEach((axis) => {
      if (axis.valueAxis === undefined || axis.valueAxis === null || axis.valueAxis.id === smallestValueAxis.id) return;

      axis.valueAxis.synchronizationMultiplier = axis.synchronizationMultiplier;
      axis.valueAxis.synchronizeWith = smallestValueAxis;
    });
  }
}
