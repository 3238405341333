import moment from 'moment';
import { IDataPointsForTime, AppDateTimesSnapshot, ISeriesDataMessageDto, ConfiguredDataDefinition } from '@/apps/timeSeriesViewer';

export class TrendData {
  constructor(
    public appDateTimes: AppDateTimesSnapshot,
    public configuredDataDefinitions: ConfiguredDataDefinition[],
    public dataPoints: IDataPointsForTime[],
    public utcOffset: UtcOffset,
    public messages: ISeriesDataMessageDto[]
  ) {}
}

export class UtcOffset {
  constructor(
    readonly value: moment.Duration,
    readonly displayName: string
  ) {}
}
