import ko from 'knockout';
import _ from 'lodash';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { Trend, TrendServices, ConfiguredDataDefinitionFactory, ConfiguredDataDefinition, AppDateTimes, ConfiguredDataDefinitionRow } from '@/apps/timeSeriesViewer';
import { IDraggableBindingArgs } from './draggable';
import './draggable';

export class Component {
  private _disposable = new CompositeDisposable();

  isSelectedSeriesListVisible = ko.observable(false);
  isConfiguredSeriesListVisible = ko.observable(false);
  configuredDataDefinitionRows: KnockoutComputed<ConfiguredDataDefinitionRow.Args[]>;
  configuredDataDefinitions: KnockoutObservableArray<ConfiguredDataDefinition>;
  goToNextStep: () => void;
  goToPreviousStep: () => void;
  draggable: IDraggableBindingArgs<ConfiguredDataDefinitionRow.Component, ConfiguredDataDefinition>;

  constructor(private _args: Args) {
    this.configuredDataDefinitions = this._args.configuredDataDefinitions;
    this.goToNextStep = this._args.goToNextStep;
    this.goToPreviousStep = this._args.goToPreviousStep;

    this.draggable = {
      collection: this.configuredDataDefinitions,
      getItem: (x) => x.configuredDataDefinition,
      options: { handle: '.draggable', animation: 100 }
    };

    this.configuredDataDefinitionRows = ko.pureComputed(() => {
      return _.map(this._args.configuredDataDefinitions(), (configuredDataDefinition) => {
        return this._args.configuredDataDefinitionRowArgsFactory.create(configuredDataDefinition, {
          delete: this.deleteConfiguredDefinition.bind(this),
          duplicate: this.duplicateConfiguredDefinition.bind(this)
        });
      });
    });

    this._disposable.add(
      ko.computed(() => {
        if (this._args.configuredDataDefinitions().length === 0) this.isConfiguredSeriesListVisible(false);
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }

  private deleteConfiguredDefinition(row: ConfiguredDataDefinitionRow.Args) {
    this._args.configuredDataDefinitions.remove(row.configuredDataDefinition);
  }

  private duplicateConfiguredDefinition(row: ConfiguredDataDefinitionRow.Args) {
    const duplicateConfiguredDefinition = this._args.configuredDataDefinitionFactory.duplicate(row.configuredDataDefinition);
    this._args.configuredDataDefinitions.push(duplicateConfiguredDefinition);
  }

  addCalculatedSeries(): void {
    const calculatedSeries = this._args.configuredDataDefinitionFactory.createCalculated();
    this._args.configuredDataDefinitions.push(calculatedSeries);
  }
}

export class Args {
  constructor(
    public configuredDataDefinitions: KnockoutObservableArray<ConfiguredDataDefinition>,
    public dateTimes: AppDateTimes,
    public configuredDataDefinitionFactory: ConfiguredDataDefinitionFactory,
    public configuredDataDefinitionRowArgsFactory: ConfiguredDataDefinitionRow.ArgsFactory,
    public goToPreviousStep: () => void,
    public goToNextStep: () => void
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(
      this._trend.configuredDataDefinitions,
      this._trend.appDateTimes,
      this._services.configuredDataDefinitionFactory,
      new ConfiguredDataDefinitionRow.ArgsFactory(),
      () => this._trend.currentStepId('RawDataSelection'),
      () => this._trend.currentStepId('Viewer')
    );
  }
}

import html from './configureDataDefinitionsPage.html';
defineComponent(() => Component, 'configureDataDefinitionsPage', html);
require('./configureDataDefinitionsPage.less');
