import ko from 'knockout';
import _ from 'lodash';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { IKoDropOptions } from '@/gr/common/knockout/bindings/drop';
import { Facet, FacetValue, InputDataDefinitionChooserState } from '@/apps/timeSeriesViewer';
import '@/gr/common/knockout/bindings/fastForEach';
import '@/gr/common/knockout/bindings/drop';

export class Component {
  private _selectedFacetsByFacetId = ko.pureComputed(() => _.keyBy(this.selectedFacets(), (f) => f.facet.id));
  private _availableFacetsWithoutSelectedFacets: KnockoutComputed<Facet[]>;

  private _maxFacetsShownWhenCollapsed = 3;

  areManyAvailableFacets = ko.pureComputed(() => this._availableFacetsWithoutSelectedFacets().length > this._maxFacetsShownWhenCollapsed);
  isShowingAll = ko.observable(false);
  selectedFacets: KnockoutObservableArray<FacetValue>;
  availableFacets = ko.pureComputed(() => {
    return this.isShowingAll() ? this._availableFacetsWithoutSelectedFacets() : _.take(this._availableFacetsWithoutSelectedFacets(), this._maxFacetsShownWhenCollapsed);
  });
  facetValuesDrop: IKoDropOptions = {
    template: 'facet-values-template',
    openOn: 'click',
    position: 'bottom left',
    classes: 'facet-values-drop',
    tetherOptions: { attachment: 'top left', targetAttachment: 'bottom left' }
  };

  constructor(private _args: Args) {
    this._availableFacetsWithoutSelectedFacets = ko.pureComputed(() => _.reject(this._args.availableFacets(), (availableFacet) => availableFacet.id in this._selectedFacetsByFacetId()));
    this.selectedFacets = this._args.selectedFacetValues;
  }

  selectFacetValue(facetValue: FacetValue): void {
    this.selectedFacets.push(facetValue);
  }

  toggleIsShowingAll(): void {
    this.isShowingAll(!this.isShowingAll());
  }

  dispose(): void {
    this._disposable.dispose();
  }

  private _disposable = new CompositeDisposable();
}

export class Args {
  constructor(
    public availableFacets: KnockoutObservableArray<Facet>,
    public selectedFacetValues: KnockoutObservableArray<FacetValue>
  ) {}
}

export class ArgsFactory {
  create(state: InputDataDefinitionChooserState): Args {
    return new Args(state.availableFacets, state.selectedFacetValues);
  }
}

import html from './facetChooserComponent.html';
defineComponent(() => Component, 'facetChooser', html);
require('./facetChooserComponent.less');
