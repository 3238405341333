import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import _ from 'lodash';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { GroupByChooserArgs, Trend, TrendServices, Tab, InputDefinitionGridBinding } from '@/apps/timeSeriesViewer';

export class Component {
  private _disposable = new CompositeDisposable();

  groupByChooserArgs = new GroupByChooserArgs(
    ko.pureComputed(() => this._params.tabState().availableGroupingFacets()),
    ko.pureComputed({
      read: () => {
        const selectedGroupingFacets = this._params.tabState().selectedGroupingFacets();
        return selectedGroupingFacets ? _.clone(selectedGroupingFacets) : [];
      },
      write: (value) => this._params.tabState().selectedGroupingFacets(value)
    })
  );

  private _rows = ko.pureComputed(() => this._params.tabState().rows());
  private _columns = ko.pureComputed(() => this._params.tabState().columns());

  noResults = ko.pureComputed(() => !this._params.isLoading() && this._rows().length === 0);

  rawDataDefinitionGrid: InputDefinitionGridBinding.Args;

  anySelectedGroupingFacets = ko.pureComputed(() => {
    const selectedGroupingFacets = this._params.tabState().selectedGroupingFacets();
    return selectedGroupingFacets && selectedGroupingFacets.length > 0 && !this._params.isLoading() && this._rows().length > 0;
  });

  constructor(private _params: Args) {
    this.rawDataDefinitionGrid = this._params.rawDataDefinitionGridArgsFactory.create(this._columns, this._rows);
  }

  dispose(): void {
    this._disposable.dispose();
  }

  expandAll(): void {
    this.rawDataDefinitionGrid.expandAll();
  }

  collapseAll(): void {
    this.rawDataDefinitionGrid.collapseAll();
  }
}

export class Args {
  constructor(
    public tabState: kx.ReadOnlyObservable<Tab>,
    public rawDataDefinitionGridArgsFactory: InputDefinitionGridBinding.ArgsFactory,
    public isLoading: kx.ReadOnlyObservable<boolean>
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(tab: KnockoutObservable<Tab>): Args {
    return new Args(tab, new InputDefinitionGridBinding.ArgsFactory(this._services), this._trend.rawDataDefinitionChooserState.isLoading);
  }
}

import html from './inputDefinitionChooserTab.html';
defineComponent(() => Component, 'inputDataDefinitionChooserTab', html);
require('./inputDefinitionChooserTab.less');
