import moment from 'moment';
import * as log from '@/gr/common/log';
import { ITimerFactory } from '@/gr/common/timer';
import { IDisposable, Disposable, SerialDisposable, CompositeDisposable } from '@/gr/common/disposable';

// This class reloads the page at midnight to work around any memory leaks. Ideally this class can be deleted once the memory leaks are fixed.
export class AutoRefreshService {
  private _disposable = new CompositeDisposable();
  private _timerDisposable = new SerialDisposable();

  constructor(
    private readonly _timerFactory: ITimerFactory,
    private readonly _refresh: () => void
  ) {
    this._disposable.add(this._timerDisposable);
  }

  start(): this {
    this._timerDisposable.setDisposable(this.refreshPageAtMidnight());
    return this;
  }

  private refreshPageAtMidnight(): IDisposable {
    const now = moment();
    const tomorrowMidnight = now.clone().startOf('day').add(1, 'day');
    const durationTilTomorrowMidnight = moment.duration(tomorrowMidnight.diff(now, 'milliseconds'), 'milliseconds');
    const timer = this._timerFactory.create().setToFireOnceIn(durationTilTomorrowMidnight, () => {
      this._refresh();
    });
    log.information(`Page will refresh in ${durationTilTomorrowMidnight.asHours().toFixed(2)} hours at ${tomorrowMidnight.format()}`);
    return new Disposable(() => timer.dispose());
  }

  dispose(): void {
    this._disposable.dispose();
  }
}
