import moment from 'moment';
import { Timer, ITimerFactory } from '../timer';
import { CancellationToken } from '../cancellationToken';

export function rejectAfter<T>(duration: moment.Duration, error: any, promise: Promise<T>) {
  return new Promise<T>((resolve, reject) => {
    let isCompleted = false;

    function complete(action: () => void) {
      if (!isCompleted) {
        isCompleted = true;
        timer.dispose();
        action();
      }
    }

    const timer = new Timer().setToFireOnceIn(duration, () => {
      complete(() => reject(error));
    });

    promise.then(
      (value) => complete(() => resolve(value)),
      (error) => complete(() => reject(error))
    );
  });
}
