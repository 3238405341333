import { getVisibleWidthOutsideMargin, getComputedStylePropertyAsInteger } from '../../utils/measure/element';
import { CompositeDisposable } from '../../disposable';

export class ScrollBarService {
  private _isScrollbarHidden!: boolean;
  private _disposable = new CompositeDisposable();

  constructor(private _window: Window) {}

  restoreScrollbars(): void {
    if (!this._isScrollbarHidden) return;

    this._isScrollbarHidden = false;
    this._disposable.dispose();
    this._disposable = new CompositeDisposable();
  }

  hideScrollbars(): void {
    if (this._isScrollbarHidden) return;

    this._isScrollbarHidden = true;

    const oldBodyOverflow = this._window.document.body.style.overflow;
    const oldHtmlOverflow = this._window.document.documentElement!.style.overflow;
    const oldBodyPaddingRight = this._window.document.body.style.paddingRight || '';
    const computedPaddingRight = getComputedStylePropertyAsInteger(this._window.document.body, 'paddingRight');

    const widthOfPageIncludingScrollbar = this._window.innerWidth;
    const widthOfBodyExcludingScrollbar = this.getWidthOfBodyExcludingScrollbar();
    const isVerticalScrollbarVisible = widthOfBodyExcludingScrollbar < widthOfPageIncludingScrollbar;
    const verticalScrollbarWidth = widthOfPageIncludingScrollbar - widthOfBodyExcludingScrollbar;

    this._window.document.body.style.overflow = 'hidden';
    this._window.document.documentElement!.style.overflow = 'hidden';
    this._disposable.add(() => {
      this._window.document.body.style.overflow = oldBodyOverflow;
      this._window.document.documentElement!.style.overflow = oldHtmlOverflow;
    });

    if (isVerticalScrollbarVisible) {
      this._window.document.body.style.paddingRight = computedPaddingRight + verticalScrollbarWidth + 'px';
      this._disposable.add(() => (this._window.document.body.style.paddingRight = oldBodyPaddingRight));
    }
  }

  private getWidthOfBodyExcludingScrollbar() {
    return Math.max(getVisibleWidthOutsideMargin(this._window.document.body), getVisibleWidthOutsideMargin(this._window.document.documentElement!));
  }
}
