import ko from 'knockout';

ko.bindingHandlers.selectAll = {
  update(element, valueAccessor) {
    if (element instanceof HTMLInputElement) {
      const selectAll = valueAccessor();
      const selectAllValue = ko.unwrap(selectAll);
      if (selectAllValue) {
        element.setSelectionRange(0, element.value.length);
        element.focus();
      }
      // Clear the observable so it can be set again
      if (ko.isObservable(selectAll)) {
        selectAll(false);
      }
    }
  }
};
