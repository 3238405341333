export function getOrAdd<TValue>(map: { [key: number]: TValue }, key: number, factory: () => TValue): TValue;
export function getOrAdd<TValue>(map: { [key: string]: TValue }, key: string, factory: () => TValue): TValue;
export function getOrAdd<TMap, TKey extends keyof TMap, TValue extends TMap[TKey]>(map: TMap, key: TKey, factory: () => TValue): TValue;
export function getOrAdd(map: any, key: any, factory: () => any): any {
  let value = map[key];
  if (value === undefined) {
    value = factory();
    map[key] = value;
  }
  return value;
}

export function getOr<TValue>(map: { [key: number]: TValue }, key: number, factory: () => TValue): TValue;
export function getOr<TValue>(map: { [key: string]: TValue }, key: string, factory: () => TValue): TValue;
export function getOr<TMap, TKey extends keyof TMap, TValue extends TMap[TKey]>(map: TMap, key: TKey, factory: () => TValue): TValue;
export function getOr(map: any, key: any, factory: () => any): any {
  let value = map[key];
  if (value === undefined) {
    value = factory();
  }
  return value;
}
