import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { Trend, TrendServices, TrendStepId, PolicyAuthorisationsService } from '@/apps/timeSeriesViewer';
import { IKoDropOptions } from '@/gr/common/knockout/bindings/drop';

export class Component {
  rawData = this.createStep('RawDataSelection', 'raw-data-definitions-template');
  configuredData = this.createStep('ConfigureData', 'configure-data-definitions-template');
  viewer = this.createStep('Viewer', 'output-channels-template');
  publish = this.createStep(
    'Publish',
    'publish-template',
    ko.pureComputed(() => this._args.policyAuthorisations.isAuthorised('Publishing')())
  );

  rawDataDefinitionNames;
  configuredDataDefinitionNames;

  constructor(private readonly _args: Args) {
    this.rawDataDefinitionNames = this._args.rawDataDefinitionNames;
    this.configuredDataDefinitionNames = this._args.configuredDataDefinitionNames;
  }

  private createStep(stepId: TrendStepId, dropTemplateId: string, isVisible: () => boolean = () => true) {
    return {
      isVisible: isVisible,
      select: () => this._args.currentStepId(stepId),
      isSelected: ko.pureComputed(() => this._args.currentStepId() === stepId),
      drop: {
        template: dropTemplateId,
        openOn: 'hoverTarget',
        position: 'bottom center',
        isEnabled: ko.pureComputed(() => this._args.currentStepId() !== stepId)
      } as IKoDropOptions
    };
  }
}

export class Args {
  constructor(
    public currentStepId: KnockoutObservable<TrendStepId>,
    public rawDataDefinitionNames: kx.ReadOnlyObservable<string[]>,
    public configuredDataDefinitionNames: kx.ReadOnlyObservable<string[]>,
    public policyAuthorisations: PolicyAuthorisationsService
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(
      this._trend.currentStepId,
      ko.pureComputed(() => this._trend.selectedInputDataDefinitions().map((r) => r.name())),
      ko.pureComputed(() => this._trend.configuredDataDefinitions().map((c) => c.displayNameOrDefault())),
      this._services.policyAuthorisations
    );
  }
}

import html from './navigationComponent.html';
defineComponent(() => Component, 'navigation', html);
require('./navigationComponent.less');
