import { IModalIframeServiceFactory } from './modalIframeService';
import { ModalSameOriginIframeServiceFactory } from './modalSameOriginIframeService';
import { ModalCrossOriginIframeServiceFactory } from './modalCrossOriginIframeService';
import { IModalContentService } from '../../windowsApis/modal/modalContentService';

interface IModalGlobalSettings {
  iframeFactory: IModalIframeServiceFactory;
}

export class ModalGlobalSettings {
  private static _isSettingsChanged = false;
  private static _settings: IModalGlobalSettings = { iframeFactory: new ModalSameOriginIframeServiceFactory() };

  static useSameOriginModal() {
    this.settingChanged();
    this._settings.iframeFactory = new ModalSameOriginIframeServiceFactory();
  }

  static useCrossOriginModal(sameOriginPathToBlankPage: string, modalContentService: IModalContentService) {
    this.settingChanged();
    this._settings.iframeFactory = new ModalCrossOriginIframeServiceFactory(modalContentService, sameOriginPathToBlankPage);
  }

  private static settingChanged() {
    if (this._isSettingsChanged) console.warn('The global settings for @/gr/common/knockout/bindings/modal are being set in multiple places');
    this._isSettingsChanged = true;
  }

  static get() {
    return this._settings;
  }
}
