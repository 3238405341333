import ko from 'knockout';
import moment from 'moment';
import _ from 'lodash';
import { ConfiguredDataDefinition, ChartAxis, GraphType, ColourProvider, ChartAxesBuilder, defaultIfNil } from '@/apps/timeSeriesViewer';

export interface ICompleteChartSeries extends ChartSeries {
  // axis: KnockoutObservable<ChartAxis>;
  configuredDataDefinition: ConfiguredDataDefinition;
}

export class ChartSeries {
  readonly id: string;

  readonly dataPeriod = ko.observable<moment.Duration | null>();

  readonly periodSpanWithDefault: KnockoutComputed<number | moment.Duration | null | undefined>;
  readonly lineThicknessWithDefault: KnockoutComputed<number>;

  readonly isColumnWidthApplicable: KnockoutComputed<boolean>;
  readonly isStackedApplicable: KnockoutComputed<boolean>;
  readonly isSteppedApplicable: KnockoutComputed<boolean>;
  readonly isLineThicknessApplicable: KnockoutComputed<boolean>;

  constructor(
    public configuredDataDefinition: ConfiguredDataDefinition,
    public axis: KnockoutObservable<ChartAxis | null>,
    public type: KnockoutObservable<GraphType>,
    public color: KnockoutObservable<string>,
    public dashLength: KnockoutObservable<number | null>,
    public bullet: KnockoutObservable<string | null>,
    public isShown: KnockoutObservable<boolean>,
    public stepDirection: KnockoutObservable<'left' | 'right' | null>,
    public noStepRisers: KnockoutObservable<boolean | null>,
    public periodSpan: KnockoutObservable<number | null>,
    public columnWidth: KnockoutObservable<number | null>,
    public lineThickness: KnockoutObservable<number | null>,
    public showInLegend: KnockoutObservable<boolean>,
    public isStacked: KnockoutObservable<boolean>,
    public isStepped: KnockoutObservable<boolean>
  ) {
    this.id = this.configuredDataDefinition.id;
    this.periodSpanWithDefault = ko.pureComputed(() => (this.periodSpan() != null ? this.periodSpan() : this.dataPeriod()));
    this.lineThicknessWithDefault = ko.pureComputed({ read: () => defaultIfNil(this.lineThickness(), 1.5), write: (x) => this.lineThickness(x) });
    this.isColumnWidthApplicable = ko.pureComputed(() => _.includes(['column'], this.type()));
    this.isStackedApplicable = ko.pureComputed(() => _.includes(['column', 'area'], this.type()));
    this.isSteppedApplicable = ko.pureComputed(() => _.includes(['line', 'area'], this.type()));
    this.isLineThicknessApplicable = ko.pureComputed(() => _.includes(['line'], this.type()));
  }
}

export class ChartSeriesFactory {
  constructor(private _colourProvider: ColourProvider) {}

  createCollectionFrom(configuredDataDefinitions: ConfiguredDataDefinition[], existingSeriesCollection: ChartSeries[], chartAxesBuilder: ChartAxesBuilder): ICompleteChartSeries[] {
    return _.map(configuredDataDefinitions, (configuredDataDefinition) => {
      const existingSeries = this.existingSeriesFor(configuredDataDefinition, existingSeriesCollection);
      if (existingSeries != null) {
        if (existingSeries.axis() == null) {
          existingSeries.axis(chartAxesBuilder.addOrGetAxis(null, configuredDataDefinition.unitsOfMeasure));
        }
        existingSeries.configuredDataDefinition = configuredDataDefinition;
        return existingSeries as ICompleteChartSeries;
      } else {
        return this.createFrom(configuredDataDefinition, null, chartAxesBuilder) as ICompleteChartSeries;
      }
    });
  }

  private existingSeriesFor(configuredDataDefinition: ConfiguredDataDefinition, series: ChartSeries[]): ChartSeries | undefined {
    return _.find(series, (s) => s.configuredDataDefinition.equals(configuredDataDefinition));
  }

  createFrom(configuredDataDefinition: ConfiguredDataDefinition, chartAxisId: string | null, chartAxesBuilder: ChartAxesBuilder): ChartSeries {
    return new ChartSeries(
      configuredDataDefinition,
      ko.observable(chartAxesBuilder.addOrGetAxis(chartAxisId, configuredDataDefinition.unitsOfMeasure)),
      ko.observable<GraphType>('line'),
      ko.observable(this._colourProvider.nextColor()).extend({ rateLimit: { timeout: 500, method: 'notifyWhenChangesStop' } }),
      ko.observable(null),
      ko.observable<string>(null),
      ko.observable<boolean>(true),
      ko.observable(null),
      ko.observable(null),
      ko.observable(null),
      ko.observable(null),
      ko.observable(null),
      ko.observable(true),
      ko.observable(false),
      ko.observable(false)
    );
  }
}
