import moment from 'moment';
import { UtcOffsetDefinitionConverter } from '@/apps/timeSeriesViewer/converters';
import { UtcOffsetDefinition } from '@/apps/timeSeriesViewer/models';

export class Clock {
  constructor(private _getUtcOffset: () => moment.Duration) {}

  static from(utcOffsetDefinition: KnockoutObservable<UtcOffsetDefinition>): Clock {
    return new Clock(() => UtcOffsetDefinitionConverter.toUtcOffset(utcOffsetDefinition()).value);
  }

  get now(): moment.Moment {
    return moment().utcOffset(this._getUtcOffset().asMinutes());
  }
}
