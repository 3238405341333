import { Result } from '@/gr/common/result';
import { IHttpRequester, IOrganisationDto, SuccessHttpResponse } from '@/apps/timeSeriesViewer';

export class OrganisationsApi {
  constructor(private _requester: IHttpRequester) {}

  async getAll(): Promise<Result<IOrganisationDto[], string>> {
    const response = await this._requester.get<IOrganisationDto[]>({ relativeUrl: '/api/organisations' });
    return response.isSuccess ? Result.success((response as SuccessHttpResponse<IOrganisationDto[]>).data) : Result.error("We couldn't retrieve the list of organisations.");
  }
}
