































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { FileResponse, TrendSummaryResourceDto } from '../repositories';
import { GrConfirmDialogue } from '.';

@Component({
  components: { GrConfirmDialogue }
})
export default class GrGridTrendsList extends Vue {
  @Action('trendsStore/loadTrends') loadTrends!: () => Promise<void>;
  @Getter('trendsStore/savedGridTrends') savedGridTrends!: TrendSummaryResourceDto[];
  @Action('trendsStore/deleteTrend') deleteTrend!: (id: string) => Promise<FileResponse>;
  @Action('trendsStore/claimGridTrend') claimGridTrend!: (id: string) => Promise<FileResponse>;
  @Getter('gridStore/grids') grids!: string[];
  @Action('gridStore/loadGrids') loadGrids!: () => Promise<void>;

  loading = true;
  infoDialog = false;
  previewDialog = false;
  previewTrend: TrendSummaryResourceDto | null = null;

  private get previewUrl() {
    if (this.previewTrend?.trendSummary) {
      return `/#/artifacts/${this.previewTrend.trendSummary.id}`;
    }
    return null;
  }

  async created(): Promise<void> {
    await this.loadTrends();
    this.loading = false;
  }

  private get filteredGridTrends() {
    return this.savedGridTrends; //.filter(t => t.trendSummary?.title?.search(new RegExp(this.trendFilter, 'i')) !== -1);
  }

  private closePreview() {
    this.previewDialog = false;
    this.previewTrend = null;
  }

  private async view(trend: TrendSummaryResourceDto) {
    this.previewDialog = true;
    this.previewTrend = trend;
  }

  private async claim(trend: TrendSummaryResourceDto) {
    const confirmDialogue = this.$refs.confirmDialogue as GrConfirmDialogue;
    const message = `Are you sure you want to claim the trend "${trend?.trendSummary?.title}"?  The trend will move into your trends.  You can then share the trend with others in your company`;
    if (trend?.trendSummary?.id && (await confirmDialogue.activate('Claim Trend', message))) {
      this.loading = true;
      await this.claimGridTrend(trend.trendSummary.id);
      this.loading = false;
      this.closePreview();
    }
  }

  private async remove(trend: TrendSummaryResourceDto) {
    const confirmDialogue = this.$refs.confirmDialogue as GrConfirmDialogue;
    if (trend?.trendSummary?.id && (await confirmDialogue.activate('Delete Trend', `Are you sure you want to delete the trend "${trend?.trendSummary?.title}"?`))) {
      this.loading = true;
      await this.deleteTrend(trend.trendSummary.id);
      this.loading = false;
      this.closePreview();
    }
  }
}
