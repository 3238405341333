import { Trend } from '@/apps/timeSeriesViewer/models';
import { TrendTemplatesApi } from '@/apps/timeSeriesViewer/repos';
import { TrendConverterFactory, TrendTemplateSummaryConverter } from '@/apps/timeSeriesViewer/converters';
import { CancellationToken } from '@/gr/common/cancellationToken';
import { Result } from '@/gr/common/result';
import { TemplateSummaryDto } from '@/repositories';

export class TrendTemplatesRepository {
  constructor(
    private _trendTemplateApi: TrendTemplatesApi,
    private _trendConverterFactory: TrendConverterFactory
  ) {}

  private _trendTemplateSummaryConverter = new TrendTemplateSummaryConverter();

  async create(trend: Trend, includeOnHomePage: boolean, cancellationToken: CancellationToken): Promise<Result<TemplateSummaryDto, string>> {
    const trendDto = this._trendConverterFactory.create().toDto(trend);
    const result = await this._trendTemplateApi.create(includeOnHomePage, trendDto.trend, cancellationToken);
    return result.mapSuccess((trendTemplate) => Result.success(this._trendTemplateSummaryConverter.toModel(trendTemplate)));
  }
}
