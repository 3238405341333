import { ChartAxis, SeriesUnitsOfMeasure } from '@/apps/timeSeriesViewer';
import AmCharts from '@/libs/amcharts';

export class ValueAxesFactory {
  create(axes: ChartAxis[]): AmCharts.ValueAxis[] {
    const valueAxes = axes.map((axis) => {
      const position = axis.position();

      // const amChartAxis = new AmCharts.ValueAxis();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const amChartAxis: any = {};
      amChartAxis.id = axis.id;
      const title = axis.title();
      amChartAxis.title = title ? title : '';
      amChartAxis.axisAlpha = 0.2;
      amChartAxis.dashLength = 1;
      amChartAxis.autoOffset = true;
      amChartAxis.position = position;
      amChartAxis.logarithmic = axis.axisLogarithmic();

      amChartAxis.labelFunction = (value: number) => {
        const units = axis.unitsOfMeasure();
        return SeriesUnitsOfMeasure.formatValue(value, units);
      };

      if (axis.axisFixed()) {
        // Setting minimum value directly did not work unless the value had a decimal place.
        // Parsing the number as a float fixed the issue but unsure why :/

        const min = axis.min();
        if (min !== undefined) {
          amChartAxis.minimum = parseFloat(min.toString());
        }
        const max = axis.max();
        if (max !== undefined) {
          amChartAxis.maximum = parseFloat(max.toString());
        }
      }

      return amChartAxis;
    });

    return valueAxes;
  }
}
