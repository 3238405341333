import moment from 'moment';
import { UtcOffset } from '@/apps/timeSeriesViewer';

export const formatStrings = {
  longDateTime: 'Do MMM YYYY, HH:mm',
  longDate: 'Do MMM YYYY',
  longDateTimeExcel: 'DD-MMM-YYYY HH:mm',
  date: 'DD-MM-YYYY',
  time: 'HH:mm'
};

export interface IFormattedDateTime {
  dateTime: string;
  offset: string | null;
  all: string;
}

export class DateTimeFormatter {
  static dateTime(dateTime: moment.Moment | string, utcOffset: UtcOffset): IFormattedDateTime {
    const utcOffsetAsMinutes = utcOffset.value.asMinutes();
    const dateTimeInOffset = moment(dateTime).utcOffset(utcOffsetAsMinutes);

    const formattedDateTime = dateTimeInOffset.format(formatStrings.longDateTime);
    const formattedOffset = utcOffset.displayName;

    const formatted = { dateTime: formattedDateTime, offset: formattedOffset, all: [formattedDateTime, formattedOffset].join(' ') };

    return formatted;
  }

  static toDateTimeOffset(dateTime: moment.Moment | string, utcOffset: UtcOffset): moment.Moment {
    const utcOffsetAsMinutes = utcOffset.value.asMinutes();
    return moment(dateTime).utcOffset(utcOffsetAsMinutes);
  }

  static date(dateTime: moment.Moment | string, utcOffset: UtcOffset): string {
    const dateTimeInOffset = DateTimeFormatter.toDateTimeOffset(dateTime, utcOffset);
    return dateTimeInOffset.format(formatStrings.date);
  }

  static time(dateTime: moment.Moment | string, utcOffset: UtcOffset): string {
    const dateTimeInOffset = DateTimeFormatter.toDateTimeOffset(dateTime, utcOffset);
    return dateTimeInOffset.format(formatStrings.time);
  }

  static dateTimeForExcel(dateTime: moment.Moment | string, utcOffset: UtcOffset): string {
    const dateTimeInOffset = DateTimeFormatter.toDateTimeOffset(dateTime, utcOffset);
    return dateTimeInOffset.format(formatStrings.longDateTimeExcel);
  }

  // Get the relative trading period timestamp.
  // Used for column header names (i.e. "-9 minutes", "+1 hours").
  static relativeTradingPeriodTimestamp(dateTime: moment.Moment | string, utcOffset: UtcOffset, zeroInterval: moment.Moment): string {
    const utcOffsetAsMinutes = utcOffset.value.asMinutes();
    const dateTimeInOffset = moment(dateTime).utcOffset(utcOffsetAsMinutes);
    const dateDiff = dateTimeInOffset.diff(zeroInterval, 'minutes', true);
    return moment.duration(dateDiff, 'minutes').format(`${dateDiff > 0 ? '+' : ''}h[h] m[m]`);
  }
}
