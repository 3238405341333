import kx from '@/gr/common/knockout/extended';
import { Trend, TrendDataDefinition } from '@/apps/timeSeriesViewer/models';

export class TrendSummaryForChannels {
  static fromTrend(trend: Trend): TrendSummaryForChannels {
    return new TrendSummaryForChannels(trend.dataDefinition, trend.title, trend.isReadOnlyMode, trend.isTrendEmbeddable);
  }

  constructor(
    readonly dataDefinition: kx.ReadOnlyObservable<TrendDataDefinition>,
    readonly title: KnockoutComputed<string | null | undefined>,
    readonly isTrendReadOnly: kx.ReadOnlyObservable<boolean | undefined>,
    readonly isTrendEmbeddable: kx.ReadOnlyObservable<boolean>
  ) {}
}
