interface IColorBound {
  value: number;
  paletteEntry: IPaletteEntry;
}

interface IPaletteEntry {
  color: string;
  backgroundColor: string;
}

const palette = {
  blue847pc: { backgroundColor: '#B0BFD8', color: '#000000' },
  blue757pc: { backgroundColor: '#8199C1', color: '#000000' },
  blue667pc: { backgroundColor: '#5273AA', color: '#FFFFFF' },
  grey820pc: { backgroundColor: '#D1D1D1', color: '#000000' },
  red953pc: { backgroundColor: '#F3A7AA', color: '#000000' },
  red925pc: { backgroundColor: '#EC7277', color: '#000000' },
  red898pc: { backgroundColor: '#E53E45', color: '#FFFFFF' },
  white: { backgroundColor: '#FFFFFF', color: '#000000' },
  black251pc: { backgroundColor: '#404040', color: '#FFFFFF' }
};

const colorCodes: { [key: string]: IColorBound[] } = {};

/* tslint:disable:no-string-literal */
colorCodes['Avg Estimated Trading Price'] = [
  { value: 0, paletteEntry: palette.blue667pc },
  { value: 200, paletteEntry: palette.blue757pc },
  { value: 500, paletteEntry: palette.grey820pc },
  { value: 900, paletteEntry: palette.red953pc },
  { value: 15000, paletteEntry: palette.red898pc }
];
colorCodes['Current P30'] = [
  { value: 0, paletteEntry: palette.blue667pc },
  { value: 200, paletteEntry: palette.blue757pc },
  { value: 500, paletteEntry: palette.grey820pc },
  { value: 900, paletteEntry: palette.red953pc },
  { value: 15000, paletteEntry: palette.red898pc }
];
colorCodes['Max Diff'] = [
  { value: 0, paletteEntry: palette.black251pc },
  { value: 100, paletteEntry: palette.blue757pc },
  { value: 500, paletteEntry: palette.grey820pc },
  { value: 1000, paletteEntry: palette.red953pc },
  { value: 15000, paletteEntry: palette.red898pc }
];
colorCodes['Std Dev'] = [
  { value: 0, paletteEntry: palette.black251pc },
  { value: 100, paletteEntry: palette.blue757pc },
  { value: 500, paletteEntry: palette.grey820pc },
  { value: 1000, paletteEntry: palette.red953pc },
  { value: 15000, paletteEntry: palette.red898pc }
];
colorCodes['Spread'] = [
  { value: 0, paletteEntry: palette.black251pc },
  { value: 100, paletteEntry: palette.blue757pc },
  { value: 500, paletteEntry: palette.grey820pc },
  { value: 600, paletteEntry: palette.red953pc },
  { value: 15000, paletteEntry: palette.red898pc }
];
colorCodes['Surplus (def 1)'] = [
  { value: 200, paletteEntry: palette.red898pc },
  { value: 400, paletteEntry: palette.red925pc },
  { value: 600, paletteEntry: palette.red953pc },
  { value: 1400, paletteEntry: palette.grey820pc },
  { value: 2800, paletteEntry: palette.blue667pc }
];
colorCodes['Surplus (def 2)'] = [
  { value: 0, paletteEntry: palette.red925pc },
  { value: 600, paletteEntry: palette.red953pc },
  { value: 1400, paletteEntry: palette.grey820pc },
  { value: 2800, paletteEntry: palette.blue667pc }
];
colorCodes['Wind & Large Solar'] = [
  { value: 0, paletteEntry: palette.black251pc },
  { value: 100, paletteEntry: palette.red953pc },
  { value: 500, paletteEntry: palette.grey820pc },
  { value: 1400, paletteEntry: palette.blue847pc },
  { value: 1900, paletteEntry: palette.blue667pc }
];
colorCodes['IRPM'] = [
  { value: -20, paletteEntry: palette.red898pc },
  { value: 0, paletteEntry: palette.red925pc },
  { value: 30, paletteEntry: palette.red953pc },
  { value: 100, paletteEntry: palette.grey820pc },
  { value: 3500, paletteEntry: palette.blue667pc }
];
/* tslint:enable:no-string-literal */

export class LookupTable {
  static getCellColour(displayName: string, value: number): IPaletteEntry {
    const colorTable = colorCodes[displayName];

    if (colorTable !== undefined) {
      for (let i = 0; i < colorTable.length; i++) {
        const bound = colorTable[i];
        if (value < bound.value) return bound.paletteEntry;
      }
    }

    return palette.white;
  }
}
