import ko from 'knockout';
import { TrendData, TrendDataConverter, TrendDataDefinition, TrendDataDefinitionConverter } from '@/apps/timeSeriesViewer';
import { TrendsMetricsApi } from '@/apps/timeSeriesViewer/repos/api/trendsMetricsApi';

export class TrendDataRepository {
  constructor(
    private _trendDataDefinitionConverter: TrendDataDefinitionConverter,
    private _trendDataConverter: TrendDataConverter,
    private _trendsMetrics: TrendsMetricsApi
  ) {}

  async fillTrendData(
    dataDefinition: TrendDataDefinition,
    trendData: KnockoutObservable<TrendData | null>,
    progress: KnockoutObservable<string> = ko.observable(''),
    isLoading: KnockoutObservable<boolean> = ko.observable(false)
  ): Promise<void> {
    await this._trendsMetrics.fillData(dataDefinition, trendData, progress, isLoading);
  }
}
