import ko from 'knockout';
import { IDisposable } from '@/gr/common/disposable';
import { isFunction } from '@/gr/common/utils/function';
import { TrendSummaryForChannels } from '@/apps/timeSeriesViewer/models';
import { ChartState } from './chartState';
import { TableState } from './tableState';
import { FileState } from './fileState';
import { ApiState } from './apiState';

export type OutputChannelTypeId = 'NEW' | 'CHART' | 'TABLE' | 'FILE' | 'API';

export class OutputChannelContainer implements IDisposable {
  constructor(public outputChannel: KnockoutObservable<OutputChannel>) {}

  static empty(): OutputChannelContainer {
    return new OutputChannelContainer(ko.observable(new OutputChannel('NEW', null)));
  }

  dispose(): void {
    this.outputChannel().dispose();
  }
}

export class OutputChannel implements IDisposable {
  constructor(
    public channelType: OutputChannelTypeId,
    public state: ChartState | TableState | FileState | ApiState | null
  ) {}

  dispose(): void {
    if (this.state != null && 'dispose' in this.state && isFunction(this.state.dispose)) this.state.dispose();
  }
}

export class OutputChannelFactory {
  constructor(
    public channelType: OutputChannelTypeId,
    private _channelStateFactory: IOutputChannelStateFactory,
    private _trendSummary: TrendSummaryForChannels,
    public isEnabled: boolean
  ) {}

  create(): OutputChannel {
    return new OutputChannel(this.channelType, this._channelStateFactory.create(this._trendSummary));
  }
}

export interface IOutputChannelStateFactory {
  create(trendSummary: TrendSummaryForChannels): ChartState | TableState | FileState | ApiState | null;
}
