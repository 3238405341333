import moment from 'moment';
import _ from 'lodash';
import { getEndOfFiscalYearExclusive, fiscalYearStartMonth } from '@/apps/timeSeriesViewer';
import * as log from '@/gr/common/log';

export interface IRelativeDurationUnit {
  id: RelativeDurationUnitId;
  name: string;
  addTo(m: moment.Moment, count: number): moment.Moment;
}

function create(id: RelativeDurationUnitId, unit: moment.unitOfTime.DurationConstructor): IRelativeDurationUnit {
  return {
    id: id,
    name: _.startCase(unit),
    addTo(m: moment.Moment, count: number) {
      return m.clone().add(moment.duration(count, unit));
    }
  };
}

function createComplete(id: RelativeDurationUnitId, unit: moment.unitOfTime.DurationConstructor): IRelativeDurationUnit {
  return {
    id: id,
    name: _.startCase(id),
    addTo(m: moment.Moment, count: number) {
      return m.clone().startOf(unit).add(moment.duration(count, unit));
    }
  };
}

const completeFinancialYear: IRelativeDurationUnit = {
  id: 'completeFinancialYear',
  name: 'Complete Financial Year',
  addTo(m: moment.Moment, count: number) {
    // The last complete fiscal year is the current fiscal year - 1
    return getEndOfFiscalYearExclusive(m, fiscalYearStartMonth.australia).subtract(1, 'year').add(moment.duration(count, 'year'));
  }
};

export type RelativeDurationUnitId = 'minute' | 'hour' | 'day' | 'week' | 'month' | 'year' | 'completeWeek' | 'completeMonth' | 'completeQuarter' | 'completeFinancialYear' | 'completeCalendarYear';

export const relativeDurationUnitsCollection = [
  create('minute', 'minute'),
  create('hour', 'hour'),
  create('day', 'day'),
  create('week', 'week'),
  create('month', 'month'),
  create('year', 'year'),
  createComplete('completeWeek', 'week'),
  createComplete('completeMonth', 'month'),
  createComplete('completeQuarter', 'quarter'),
  completeFinancialYear,
  createComplete('completeCalendarYear', 'year')
];

export const relativeDurationUnits = _.keyBy(relativeDurationUnitsCollection, (u) => u.id) as { [P in RelativeDurationUnitId]: IRelativeDurationUnit };

export function getRelativeDurationUnitFromId(id: RelativeDurationUnitId): IRelativeDurationUnit {
  const unit = relativeDurationUnits[id];
  if (unit) return unit;
  log.warning('Unknown relative duration unit: ' + id);
  return relativeDurationUnits.day;
}

export class RelativeDuration {
  static none = new RelativeDuration(0, relativeDurationUnits.minute);

  constructor(
    readonly count: number,
    readonly unit: IRelativeDurationUnit
  ) {}

  from(m: moment.Moment, direction: 'forward' | 'back'): moment.Moment {
    return this.unit.addTo(m, direction === 'forward' ? this.count : -this.count);
  }

  withCount(count: number): RelativeDuration {
    return new RelativeDuration(count, this.unit);
  }

  withUnit(unit: IRelativeDurationUnit): RelativeDuration {
    return new RelativeDuration(this.count, unit);
  }
}
