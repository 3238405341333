import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { untitledTrendName, coalesceTrendName, TrendServices, TrendSaver, Trend } from '@/apps/timeSeriesViewer';
import '@/gr/common/knockout/bindings/modal';

export class Component {
  private _disposable = new CompositeDisposable();

  constructor(public args: Args) {
    this._disposable.add(
      this.args.isOpen.subscribe((value) => {
        if (value) this._name(args.name());
      })
    );
  }

  private _name = ko.observable<string | null>();

  name = ko.pureComputed({ read: () => this._name(), write: (value) => this._name(coalesceTrendName(value)) });
  canSave = ko.pureComputed(() => this._name() != null);
  untitledTrendName = untitledTrendName;

  save(): void {
    this.args.name(this._name());
    this.args.isOpen(false);
    this.args.trendSaver.save();
  }

  cancel(): void {
    this.args.isOpen(false);
  }

  dispose(): void {
    this._disposable.dispose();
  }
}

export class Args {
  constructor(
    public name: KnockoutComputed<string | null | undefined>,
    public trendSaver: TrendSaver
  ) {}

  isOpen = ko.observable(false);

  show(): void {
    this.isOpen(true);
  }
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(this._trend.title, this._services.trendSaver);
  }
}

import html from './saveDialog.html';
defineComponent(() => Component, 'saveDialog', html);
require('./saveDialog.less');
