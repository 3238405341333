import { addSameOriginFullScreenInvisibleModalIframeTo } from '../../iframes/iframeHelper';
import { IModalIframeService, IModalIframeServiceFactory, ModalIframeService } from './modalIframeService';
import { ScrollBarService } from '../../utils/window/scrollBarService';
import { getAncestorWindowsAndSelfDepthFirst, isWindowSameOrigin } from '../../utils/window/navigation';
import $ from 'jquery';

export class ModalSameOriginIframeServiceFactory implements IModalIframeServiceFactory {
  create(): Promise<IModalIframeService> {
    return Promise.resolve(new ModalSameOriginIframeService());
  }
}

class ModalSameOriginIframeService extends ModalIframeService {
  private _iframe: HTMLIFrameElement;
  private _scrollBarService: ScrollBarService;

  constructor() {
    const shallowestAncestorWindow = ModalSameOriginIframeService.getShallowestSameOriginAncestorWindow();
    const iframe = addSameOriginFullScreenInvisibleModalIframeTo(shallowestAncestorWindow);
    super(iframe.contentWindow!);
    this._iframe = iframe;
    this._scrollBarService = new ScrollBarService(shallowestAncestorWindow);
  }

  private static getShallowestSameOriginAncestorWindow() {
    const sameOriginAncestorWindows = getAncestorWindowsAndSelfDepthFirst(window).reverse().filter(isWindowSameOrigin);
    return sameOriginAncestorWindows[0];
  }

  showInternal() {
    this._scrollBarService.hideScrollbars();
    $(this._iframe).css({
      'z-index': 100000,
      display: 'block'
    });
    return Promise.resolve();
  }

  hideInternal() {
    this._scrollBarService.restoreScrollbars();
    $(this._iframe).css({
      display: 'none'
    });
    return Promise.resolve();
  }
}
