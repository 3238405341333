import moment from 'moment';
import { IAppDateTimes, RelativeDuration, UtcOffsetDefinition } from '@/apps/timeSeriesViewer';

export class AppDateTimesSnapshot implements IAppDateTimes {
  now(): moment.Moment {
    return this._now;
  }
  start(): moment.Moment {
    return this._start;
  }
  end(): moment.Moment {
    return this._end;
  }
  fixedStart(): moment.Moment {
    return this._fixedStart;
  }
  fixedEnd(): moment.Moment {
    return this._fixedEnd;
  }
  lookBack(): RelativeDuration {
    return this._lookBack;
  }
  lookForward(): RelativeDuration {
    return this._lookForward;
  }
  isRelative(): boolean {
    return this._isRelative;
  }
  utcOffsetDefinition(): UtcOffsetDefinition {
    return this._utcOffsetDefinition;
  }

  constructor(
    private _now: moment.Moment,
    private _start: moment.Moment,
    private _end: moment.Moment,
    private _fixedStart: moment.Moment,
    private _fixedEnd: moment.Moment,
    private _lookBack: RelativeDuration,
    private _lookForward: RelativeDuration,
    private _isRelative: boolean,
    private _utcOffsetDefinition: UtcOffsetDefinition
  ) {}
}
