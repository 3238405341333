import $ from 'jquery';
import * as log from '@/gr/common/log';
import { Result } from '@/gr/common/result';
import { IHttpRequester, ISearchRequestDto, ISearchResultDto, IRawSearchRequestDto, IInputMetricDto, IInputDataDefinitionDto, ErrorHelper, SuccessHttpResponse } from '@/apps/timeSeriesViewer';
import { CancellationToken } from '@/gr/common/cancellationToken';

export class InputDataDefinitionsApi {
  constructor(private _requester: IHttpRequester) {}

  async search(request: ISearchRequestDto, cancellationToken: CancellationToken): Promise<Result<ISearchResultDto, string>> {
    const rawRequest: IRawSearchRequestDto = {
      search: request.search,
      facets: `[${request.facets.map((f) => [f.facetId, ':', f.facetValueId].join('')).join(',')}]`,
      groups: request.tab && request.tab.groupingFacets ? `[${request.tab.groupingFacets.map((g) => g.id).join(',')}]` : null,
      tabId: request.tab ? request.tab.id : null
    };

    const response = await this._requester.get<ISearchResultDto>({
      relativeUrl: '/api/inputDataDefinitions/search?' + $.param(rawRequest),
      cancellationToken: cancellationToken
    });
    if (response.isSuccess) {
      return Result.success((response as SuccessHttpResponse<ISearchResultDto>).data);
    } else {
      log.error('Requested search for input data definitions failed {@error}', ErrorHelper.SummaryObject.getForAjaxRequest(response));
      return Result.error(ErrorHelper.Message.getForAjaxDataRequest(response, 'An error occurred while executing your search.'));
    }
  }

  async get(ids: IInputMetricDto[]): Promise<Result<IInputDataDefinitionDto[], string>> {
    if (ids.length === 0) return Promise.resolve(Result.success([]));

    const response = await this._requester.post<IInputDataDefinitionDto[]>({
      relativeUrl: '/api/inputDataDefinitions',
      data: JSON.stringify(ids)
    });
    if (response.isSuccess) {
      return Result.success((response as SuccessHttpResponse<IInputDataDefinitionDto[]>).data);
    } else {
      log.error('Request for input data definitions failed {@error}', ErrorHelper.SummaryObject.getForAjaxRequest(response));
      return Result.error(ErrorHelper.Message.getForAjaxDataRequest(response, 'An error occurred while trying to get more information about your datasets.'));
    }
  }
}
