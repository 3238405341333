import moment from 'moment';
import { ITimeControl } from '@/common/timeContext';

export class TimeController implements ITimeController {
  jumpToTime: (time: moment.Moment) => void;
  canJumpToTime: boolean;

  constructor(private _timeControl: ITimeControl) {
    const time = _timeControl.time();
    if (time) {
      this.jumpToTime = (time) => _timeControl.travelTo(time);
      this.canJumpToTime = true;
    } else {
      this.jumpToTime = () => {
        // do nothing
      };
      this.canJumpToTime = false;
    }
  }
}

export interface ITimeController {
  jumpToTime: (time: moment.Moment) => void;
  canJumpToTime: boolean;
}
