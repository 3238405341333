import { drop, Positions, OpenOnOptions, IDropOptions } from '../../ui/drop/drop';
import ko from 'knockout';
import kx from '../extended';
import $ from 'jquery';
import _ from 'lodash';
import { SerialDisposable, CompositeDisposable } from '../../disposable';
import './drop.less';

export interface IKoDropOptions extends IDropOptions {
  viewModel?: any;
  template: string;
  isOpen?: KnockoutObservable<boolean>;
  isEnabled?: kx.ReadOnlyObservable<boolean>;
}

ko.bindingHandlers.drop = {
  init: (element, valueAccessor, allBindingsAccessor, deprecated1, bindingContext) => {
    const bindingDisposable = new SerialDisposable();

    const computed = ko.computed(() => {
      const disposable = new CompositeDisposable();
      bindingDisposable.setDisposable(disposable);

      const rawOptions = ko.unwrap(valueAccessor());

      if (rawOptions.isEnabled !== undefined && !rawOptions.isEnabled()) return;

      const options = _.defaults({}, rawOptions, { target: element, remove: true, classes: 'drop-theme-arrows' }) as IKoDropOptions;

      const dropInstance = new drop(options);

      if (rawOptions.isOpen !== undefined) {
        const isOpen = rawOptions.isOpen;
        disposable.add(
          ko.computed(() => {
            if (isOpen()) {
              dropInstance.open();
            } else {
              dropInstance.close();
            }
          })
        );
      }

      dropInstance.on('open', () => {
        const viewModel = _.isUndefined(options.viewModel) ? bindingContext.$data : options.viewModel;
        ko.renderTemplate(
          options.template,
          bindingContext.createChildContext(viewModel),
          {
            afterRender: () => {
              dropInstance.position();
            }
          },
          dropInstance.content
        );
        if (rawOptions.isOpen !== undefined && !rawOptions.isOpen()) rawOptions.isOpen(true);
      });

      dropInstance.on('close', () => {
        ko.cleanNode(dropInstance.content);
        $(dropInstance.content).unbind();
        if (rawOptions.isOpen !== undefined && rawOptions.isOpen()) rawOptions.isOpen(false);
      });

      disposable.add(() => {
        ko.cleanNode(dropInstance.content);
        $(dropInstance.content).unbind();
        dropInstance.destroy();
      });
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      computed.dispose();
      bindingDisposable.dispose();
    });
  }
};
