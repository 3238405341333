import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import '@/gr/common/knockout/bindings/modal';
import { refreshTopmostSameOriginWindow } from '@/apps/timeSeriesViewer';

export class NewVersionDialogArgs {
  readonly isDialogOpen = ko.observable(false);
  readonly refresh = refreshTopmostSameOriginWindow;

  showDialog(): void {
    this.isDialogOpen(true);
  }
}

export class NewVersionDialogViewModel {
  constructor(public args: NewVersionDialogArgs) {}

  refresh(): void {
    this.args.refresh();
  }
}

import html from './newVersionDialog.html';
defineComponent(() => NewVersionDialogViewModel, 'newVersionDialog', html);
require('./newVersionDialog.less');
