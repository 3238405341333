







































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { Organisation, IsAuthorisedDto, FileResponse, TrendPublishDto } from '@/repositories';
import { Prop, Watch } from 'vue-property-decorator';
import GrLoading from './GrLoading.vue';

@Component({
  components: { GrLoading }
})
export default class GrPublishTab extends Vue {
  @Getter('organisationsStore/myOrganisation') myOrganisation!: Organisation;
  @Getter('organisationsStore/organisations') organisations!: Organisation[];
  @Action('organisationsStore/loadMyOrganisation') loadMyOrganisation!: () => Promise<void>;
  @Action('organisationsStore/loadOrganisations') loadOrganisations!: () => Promise<void>;
  @Action('authorisationsStore/loadAuthorisations') loadAuthorisations!: (policies: string[]) => Promise<IsAuthorisedDto>;
  @Getter('authorisationsStore/authorisations') authorisations!: IsAuthorisedDto;
  @Action('artifacsStore/createArtifactFromTrend') createArtifactFromTrend!: (trendId: string) => Promise<FileResponse>;
  @Action('artifactsStore/deleteArtifact') deleteArtifact!: (id: string) => Promise<FileResponse>;
  @Action('trendsStore/publishTrend') publishTrend!: ({ id, trendPublishDto }: { id: string; trendPublishDto: TrendPublishDto }) => Promise<FileResponse>;

  //--
  @Prop({ default: undefined })
  public readonly id!: string;

  //---
  @Prop({ default: undefined })
  public readonly title?: string;

  @Watch('title')
  private localCopyOfTitleHack?: string;

  @Prop()
  kOTitleCallback!: (value: unknown) => null;

  //---
  @Prop()
  public readonly publishTo!: string[];

  @Watch('publishTo')
  private localCopyOfPublishToHack!: string[];

  @Prop()
  kOPublishToCallback!: (value: unknown) => null;

  //---
  @Prop({ default: false })
  public readonly isPublished!: boolean;

  @Watch('isPublished')
  private localCopyofIsPublishedHack!: boolean;

  @Prop()
  kOIsPublishedCallback!: (value: unknown) => null;

  //---
  private isLoading = true;
  private isPublishing = false;
  private validForm = false;
  private rules = {
    title: [(value: string) => !!value || 'Please provide a trend name'],
    organisation: [(value: []) => (value.length > 0 ? true : 'You must publish to at least one organisation')]
  };
  private canPublishToOrgs = false;

  private statusShow = false;
  private statusMessage = '';
  private statusColour = 'information';

  async created(): Promise<void> {
    this.localCopyofIsPublishedHack = this.isPublished;
    this.localCopyOfPublishToHack = this.publishTo;
    const results = await Promise.all([this.loadMyOrganisation(), this.loadAuthorisations(['GlobalRoam'])]);
    this.canPublishToOrgs = results[1].isAuthorised;
    if (this.canPublishToOrgs) {
      await this.loadOrganisations();
    }
    if (this.myOrganisation.id && !this.localCopyOfPublishToHack.includes(this.myOrganisation.id)) {
      this.localCopyOfPublishToHack.push(this.myOrganisation.id);
    }
    this.localCopyOfTitleHack = this.title;
    this.isLoading = false;
  }

  private async publish() {
    if (!this.id || !(this.$refs.publishForm as Vue & { validate: () => boolean }).validate()) return;

    this.isPublishing = true;
    const trendPublishDto = new TrendPublishDto({
      id: this.id,
      title: this.localCopyOfTitleHack,
      publishedToOrganisations: this.localCopyOfPublishToHack
    });

    const result = await this.publishTrend({ id: this.id, trendPublishDto });
    if (result.status == 200) {
      this.localCopyofIsPublishedHack = true;
      this.kOIsPublishedCallback(true);
      this.kOPublishToCallback(this.localCopyOfPublishToHack);
      this.kOTitleCallback(this.localCopyOfTitleHack);
      try {
        (this.$refs.iframe as HTMLIFrameElement).contentWindow?.location.reload();
      } catch (e) {
        //do nothing
      }
      this.statusMessage = `Successfuly published trend "${this.title}"`;
      this.statusColour = 'success';
    } else {
      this.statusMessage = `Problems publishing trend ${this.title}\n${result.data}`;
      this.statusColour = 'error';
    }
    this.statusShow = true;
    this.isPublishing = false;
  }

  private async unpublish() {
    if (!this.id) return;

    this.isPublishing = true;
    const result = await this.deleteArtifact(this.id);
    if (result.status == 200) {
      this.localCopyofIsPublishedHack = false;
      this.kOIsPublishedCallback(false);
      this.statusMessage = `Successfuly unpublished trend`;
      this.statusColour = 'success';
    } else {
      this.statusMessage = `Problems unpublishing trend\n${result.data}`;
      this.statusColour = 'error';
    }
    this.isPublishing = false;
    this.statusShow = true;
  }

  private titleEvent(event: string) {
    if (this.kOTitleCallback) this.kOTitleCallback(event);
    this.localCopyOfTitleHack = event;
    this.$emit('update:title', event);
  }

  private publishingToAll() {
    return this.localCopyOfPublishToHack.length === this.organisations.length;
  }

  private toggleSelectAll() {
    this.$nextTick(() => {
      if (this.publishingToAll()) {
        this.localCopyOfPublishToHack = [];
      } else {
        this.localCopyOfPublishToHack = this.organisations.map((x) => x.id as string);
      }
    });
  }

  private icon() {
    if (this.publishingToAll()) return 'mdi-close-box';
    if (this.localCopyOfPublishToHack.length === 0) return 'mdi-checkbox-blank-outline';
    return 'mdi-minus-box';
  }
}
