import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { StopWatch } from '../stopWatch';
import { EnrichedPropertyValue, IAnalyticsObserver } from './analyticsObserver';

export abstract class BaseAnalyticsObserver implements IAnalyticsObserver {
  protected _commonProperties: { [property: string]: EnrichedPropertyValue } = {};
  private _lastMoment!: moment.Moment;

  javascriptSessionId: number;

  constructor() {
    this.javascriptSessionId = this.generateId();

    this.enrichWithProperty('JavascriptSessionId', this.javascriptSessionId).enrichWithProperty('CreationTime', () => this.getUniqueTime());
  }

  enrichWithProperty(property: string, value: EnrichedPropertyValue) {
    this._commonProperties[property] = value;
    return this;
  }

  abstract event(eventType: string, eventData?: { [key: string]: any }): void;

  pageView(eventData?: { [key: string]: any }) {
    this.event(
      'PageView',
      _.defaults(
        {
          RequestUrl: window.document.location && window.document.location.href,
          Host: window.document.location!.host,
          Path: window.document.location!.pathname,
          UserAgent: navigator.userAgent,
          DeviceDimensions: { width: $(window).width(), height: $(window).height() }
        },
        eventData as any
      )
    );
  }

  timedEvent(eventType: string, eventData?: { [key: string]: any }, config?: { sendStartedEvent: boolean }) {
    const id = this.generateId();

    if (config && config.sendStartedEvent) {
      const startEventData = {
        Data: eventData,
        TimedEventId: id,
        State: 'Started'
      };

      this.event(eventType, startEventData);
    }

    const timer = new StopWatch();
    timer.start();

    return {
      completed: () => {
        const endEventData = {
          Data: eventData,
          TimedEventId: id,
          State: 'Completed',
          DurationInSeconds: timer.timeElapsedInSeconds()
        };

        this.event(eventType, endEventData);
      },
      failed: (error: any) => {
        const endEventData = {
          Data: eventData,
          TimedEventId: id,
          State: 'Failed',
          Error: error,
          DurationInSeconds: timer.timeElapsedInSeconds()
        };

        this.event(eventType, endEventData);
      }
    };
  }

  generateId() {
    return Math.floor(Math.random() * 9999999);
  }

  getUniqueTime() {
    // Ensure moments are monotonically increasing in increments of 1ms (the smallest increment visible in ISO format) so that events order correctly
    let newMoment = moment();
    if (this._lastMoment && newMoment.diff(this._lastMoment) <= 0) {
      this._lastMoment.add(1, 'ms');
      newMoment = this._lastMoment;
    } else {
      this._lastMoment = newMoment;
    }
    return newMoment.toISOString(); // Ensure ISO format without offset as this has the highest precision (1ms precision)
  }
}
