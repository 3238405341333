import ko from 'knockout';
import { Aggregate, ConfiguredDataDefinition, TimeStep } from '@/apps/timeSeriesViewer/models';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable } from '@/gr/common/disposable';
import { CalculatedMetric } from '@/apps/timeSeriesViewer';

export class Component {
  private _disposable = new CompositeDisposable();

  id: string;
  calculationVariableName: string;
  rawLongName: KnockoutObservable<string>;
  displayName: KnockoutObservable<string | undefined>;
  timeStep: KnockoutObservable<TimeStep | undefined>;
  aggregate: KnockoutObservable<Aggregate | undefined>;
  requiresAggregate = ko.pureComputed(() => this.timeStep() !== 'Raw');
  calculatedInput = ko.pureComputed(() => {
    return this._args.configuredDataDefinition.input.id instanceof CalculatedMetric ? this._args.configuredDataDefinition.input.id : null;
  });
  isIncludedInOutputChannels: KnockoutObservable<boolean>;
  configuredDataDefinition: ConfiguredDataDefinition;

  constructor(private _args: Args) {
    this.id = this._args.configuredDataDefinition.id;
    this.calculationVariableName = this._args.configuredDataDefinition.calculationVariableName;
    this.rawLongName = this._args.configuredDataDefinition.input.name;
    this.displayName = this._args.configuredDataDefinition.displayName;
    this.timeStep = this._args.configuredDataDefinition.timeStep;
    this.aggregate = this._args.configuredDataDefinition.aggregate;
    this.requiresAggregate = ko.pureComputed(() => this.timeStep() !== 'Raw');
    this.calculatedInput = ko.pureComputed(() => {
      return this._args.configuredDataDefinition.input.id instanceof CalculatedMetric ? this._args.configuredDataDefinition.input.id : null;
    });
    this.isIncludedInOutputChannels = this._args.configuredDataDefinition.isIncludedInOutputChannels;
    this.configuredDataDefinition = this._args.configuredDataDefinition;
  }

  dispose(): void {
    this._disposable.dispose();
  }

  duplicateRow(): void {
    this._args.strategies.duplicate(this._args);
  }

  deleteRow(): void {
    this._args.strategies.delete(this._args);
  }
}

export class Args {
  constructor(
    public configuredDataDefinition: ConfiguredDataDefinition,
    public strategies: IConfiguredDataDefinitionRowStrategies
  ) {}
}

export class ArgsFactory {
  create(configuredDataDefinition: ConfiguredDataDefinition, strategies: IConfiguredDataDefinitionRowStrategies): Args {
    return new Args(configuredDataDefinition, strategies);
  }
}

export type DuplicateStrategy = (configuredDataDefinitionRow: Args) => void;
export type DeleteStrategy = (configuredDataDefinitionRow: Args) => void;

export interface IConfiguredDataDefinitionRowStrategies {
  duplicate: DuplicateStrategy;
  delete: DeleteStrategy;
}

import html from './configuredDataDefinitionRow.html';

defineComponent(() => Component, 'configuredDataDefinitionRow', html);
require('./configuredDataDefinitionRow.less');
