import moment from 'moment';

interface IDurationWithBackoffArgs {
  startingDuration: moment.Duration;
  startingDurationRandomOffset?: moment.Duration;
  backoffFactor: number;
  maxDuration: moment.Duration;
  maxDurationRandomOffset?: moment.Duration;
}

export class DurationWithBackoff {
  private _startingDuration: moment.Duration;
  private _startingDurationRandomOffset: moment.Duration;
  private _backoffFactor: number;
  private _maxDuration: moment.Duration;

  private _currentDuration!: moment.Duration;

  constructor(config: IDurationWithBackoffArgs) {
    this._startingDuration = config.startingDuration;
    this._startingDurationRandomOffset = config.startingDurationRandomOffset || moment.duration(0);
    this._backoffFactor = config.backoffFactor;
    const maxDurationRandomOffset = config.maxDurationRandomOffset || moment.duration(0);
    this._maxDuration = moment.duration(config.maxDuration.asMilliseconds() + maxDurationRandomOffset.asMilliseconds() * Math.random());
    this.reset();
  }

  increase() {
    const newMilliseconds = this._currentDuration.asMilliseconds() * this._backoffFactor;
    this._currentDuration = moment.duration(Math.min(newMilliseconds, this._maxDuration.asMilliseconds()));
  }

  reset() {
    this._currentDuration = moment.duration(this._startingDuration.asMilliseconds() + this._startingDurationRandomOffset.asMilliseconds() * Math.random());
  }

  get() {
    return this._currentDuration;
  }
}
