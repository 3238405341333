




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { GrConfirmDialogue } from '@/components';
import { TemplateSummaryDto, HomeEntryPointArgs } from '@/repositories';

@Component({
  components: { GrConfirmDialogue }
})
export default class GrTemplateList extends Vue {
  @Getter('templatesStore/templates') templates!: TemplateSummaryDto[];
  @Action('templatesStore/loadTemplates') loadTemplates!: () => Promise<void>;
  @Action('templatesStore/removeTemplate') removeTemplate!: (id: string) => Promise<void>;
  @Getter('homeStore/homeEntryPointArgs') homeEntryPointArgs!: HomeEntryPointArgs;

  private loading = true;

  async created(): Promise<void> {
    await this.loadTemplates();
    this.loading = false;
  }

  private get allowDelete() {
    return this.homeEntryPointArgs.policyAuthorisations.some((x) => x.name === 'GlobalRoam' && x.isAuthorised);
  }

  private async confirmDeleteAndRefresh(template: TemplateSummaryDto) {
    const confirmDialogue = this.$refs.confirmDialogue as GrConfirmDialogue;
    if (template.id && (await confirmDialogue.activate('Delete Template', `Are you sure you want to delete the template '${template.name}'?`))) {
      await this.removeTemplate(template.id);
    }
  }
}
