import moment from 'moment';

export function multiplyDuration(duration: moment.Duration, count: number): moment.Duration {
  return moment.duration(duration.asMilliseconds() * count);
}

export const fiscalYearStartMonth = {
  australia: 6
};

export function getEndOfFiscalYearExclusive(m: moment.Moment, startMonth: number): moment.Moment {
  const endOfFiscalYear = m.clone().startOf('year').add(startMonth, 'months');
  return m.month() < startMonth ? endOfFiscalYear : endOfFiscalYear.add(1, 'year');
}
