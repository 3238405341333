// Do not import large dependencies into this file as it is used by apps that must have a small file size
import trim from 'trimmer';

export namespace UrlHelper {
  export function combine(...paths: string[]) {
    return paths
      .filter((path) => path !== undefined && path !== null && path.length > 0)
      .map((path, index) => {
        const segment = index === 0 ? trim.right(trim.right(path), '/\\') : trim.left(trim.left(path), './\\');
        // since we join using a '/' we replace any segment that is that character with an empty string
        // otherwise we end up with a '//' in the url
        return segment === '/' ? '' : segment;
      })
      .join('/');
  }

  export function isAbsolute(url: string) {
    const r = new RegExp('^(?:[a-z]+:)?//', 'i');
    return r.test(url);
  }

  export function parseUrl(url: string): IUrl {
    const u = document.createElement('a') as any;
    u.href = url;
    // IE doesn't populate all link properties when setting .href with a relative URL,
    // however .href will return an absolute URL which then can be used on itself
    // to populate these additional fields.
    if (u.host === '') {
      u.href = u.href;
    }
    u.hostFull = u.protocol.concat('//', u.hostname, u.port ? ':' + u.port : '');
    return u;
  }
}

interface IUrl {
  /** [protocol:]//[domain]:[port]/[path][?search] */
  href: string;
  /** [protocol:]//[domain]:[port] */
  origin: string;
  /** [domain]:[port] */
  host: string /*  */;
  /** [domain] */
  hostname: string;
  /** [pathname] */
  pathname: string;
  /** [search] */
  search: string;
  /** [protocol] */
  protocol: string;
}
