import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import _ from 'lodash';
import { Grid, GridOptions, ColDef, GetNodeChildDetails } from 'ag-grid/main';
import { CompositeDisposable } from '@/gr/common/disposable';
import { keyBy } from '@/gr/common/utils/array';
import { Row, Column, TrendServices, InputDataDefinitionsStore } from '@/apps/timeSeriesViewer';
import { InputDefinitionCellRenderer, IInputDefinitionCellRendererParams } from './inputDefinitionCellRenderer';
import { resetGlobalVariables } from '@/libs/aggrid/resetGlobalVariables';
import { SingleClickGroupCellRenderer } from './singleClickGroupCellRenderer';
import './inputDefinitionGridBinding.less';
import 'ag-grid/dist/styles/ag-grid.css';

interface IRowItem {
  rowHeader: string;
  isExpanded: boolean;
  children: IRowItem[] | null;
  [columnId: string]: string | boolean | IRowItem[] | null;
}

export class Component {
  private _disposable = new CompositeDisposable();
  private _rowHeaderField: keyof IRowItem = 'rowHeader';

  constructor(valueAccessor: () => Args, element: HTMLElement) {
    element.classList.add('raw-data-definition-grid-binding');

    const gridOptions: GridOptions = {
      suppressNoRowsOverlay: true,
      suppressMovableColumns: true,
      // suppressScrollLag: false /* in IE11, set to true to make scrolling respond faster at the cost of freezing the UI*/,
      colWidth: 100,
      autoSizePadding: 10,
      rowHeight: 35,
      getNodeChildDetails: this.getNodeChildDetails.bind(this) as GetNodeChildDetails,
      animateRows: true,
      onVirtualColumnsChanged: () => {
        this.resizeRowHeaderHeightToContent(element, gridOptions, { reset: false });
        this.updateIsHorizontalScrollbarVisible(element, gridOptions);
      },
      onViewportChanged: () => this.resizeFirstColumnWidthToContent(element, gridOptions, { reset: false }),
      onRowGroupOpened: () => {
        this.resizeFirstColumnWidthToContent(element, gridOptions, { reset: false });
      }
    };

    const resetDisposable = resetGlobalVariables();

    const grid = new Grid(element, gridOptions);

    SingleClickGroupCellRenderer.overwriteDefaultGroupRenderer(grid);

    resetDisposable.dispose();

    this._disposable.add(() => grid.destroy());

    this._disposable.add(
      ko.computed(() => {
        const args = valueAccessor();

        const firstColumn = {
          headerName: '',
          field: this._rowHeaderField,
          cellClass: 'row-header',
          pinned: true,
          width: 150,
          tooltipField: 'rowName',
          cellRenderer: 'agGroupCellRenderer',
          cellRendererParams: {
            suppressCount: true
          }
        } as ColDef;

        const remainingColumns = args.columns().map(
          (c) =>
            ({
              headerName: c.name,
              field: c.index.toString(),
              cellRenderer: InputDefinitionCellRenderer,
              cellRendererParams: { rawDataDefinitionsStore: args.rawDataDefinitionsStore } as IInputDefinitionCellRendererParams,
              headerTooltip: c.name
            }) as ColDef
        );

        const columnDefs = [firstColumn].concat(remainingColumns);

        const rowItems = this.convertRows(args.rows(), { isFirstChildExpanded: true });

        if (gridOptions.api) {
          if (rowItems.length > 0) {
            element.classList.add('contains-rows');
          } else {
            element.classList.remove('contains-rows');
          }
          gridOptions.api.setColumnDefs(columnDefs);
          gridOptions.api.setRowData(rowItems);
          this.onRenderingCompleted(element, gridOptions);
          setTimeout(() => this.onRenderingCompleted(element, gridOptions), 0);
        }

        args.collapseAll = () => {
          if (gridOptions.api) gridOptions.api.collapseAll();
        };
        args.expandAll = () => {
          if (gridOptions.api) gridOptions.api.expandAll();
        };
      })
    );
  }

  private onRenderingCompleted(element: HTMLElement, gridOptions: GridOptions) {
    this.resizeRowHeaderHeightToContent(element, gridOptions, { reset: true });
    this.updateIsHorizontalScrollbarVisible(element, gridOptions);
    this.resizeFirstColumnWidthToContent(element, gridOptions, { reset: true });
  }

  private updateIsHorizontalScrollbarVisible(element: HTMLElement, gridOptions: GridOptions) {
    if (gridOptions.columnApi && gridOptions.api) {
      const visibleColumns = gridOptions.columnApi.getAllDisplayedVirtualColumns().length;
      const totalColumns = gridOptions.columnApi.getAllDisplayedColumns().length;
      if (visibleColumns < totalColumns) {
        element.classList.add('overflow-x');
      } else {
        element.classList.remove('overflow-x');
      }
    }
  }

  private resizeFirstColumnWidthToContent(element: HTMLElement, gridOptions: GridOptions, config: { reset: boolean }) {
    if (gridOptions.columnApi && gridOptions.api) {
      let maxOffsetWidth = _(element.querySelectorAll('.ag-pinned-left-cols-container .ag-cell > span'))
        .map((e) => (e as HTMLElement).offsetWidth)
        .max();
      if (maxOffsetWidth == null) return;
      maxOffsetWidth = maxOffsetWidth + 5;
      const width = gridOptions.columnApi.getColumn(this._rowHeaderField).getActualWidth() || 0;
      if (maxOffsetWidth > width || config.reset) gridOptions.columnApi.setColumnWidth(this._rowHeaderField as string, maxOffsetWidth);
    }
  }

  private resizeRowHeaderHeightToContent(element: HTMLElement, gridOptions: GridOptions, config: { reset: boolean }) {
    if (gridOptions.api) {
      let maxOffsetHeight = _(element.querySelectorAll('.ag-header-cell-label'))
        .map((e) => (e as HTMLElement).offsetHeight)
        .max();
      if (maxOffsetHeight == null) return;
      maxOffsetHeight = maxOffsetHeight + 5;
      const headerHeight = gridOptions.headerHeight || 0;
      if (maxOffsetHeight > headerHeight || config.reset) gridOptions.api.setHeaderHeight(maxOffsetHeight);
    }
  }

  private getNodeChildDetails(rowItem: IRowItem) {
    if (rowItem.children != null && rowItem.children.length > 0) {
      return {
        group: true,
        expanded: rowItem.isExpanded,
        children: rowItem.children,
        field: this._rowHeaderField,
        key: rowItem.rowHeader
      };
    } else {
      return null;
    }
  }

  private convertRows(rows: Row[], options: { isFirstChildExpanded: boolean }): IRowItem[] {
    const rowData = rows.map((row, index) => {
      const isExpanded = index === 0 && options.isFirstChildExpanded;
      const rowData = keyBy(row.cells, (c) => c.column.index.toString()) as IRowItem;
      rowData.rowHeader = row.name;
      rowData.isExpanded = isExpanded;
      rowData.children = row.children == null ? null : this.convertRows(row.children, { isFirstChildExpanded: isExpanded });
      return rowData;
    });
    return rowData;
  }

  dispose(): void {
    this._disposable.dispose();
  }
}

export class Args {
  constructor(
    readonly columns: kx.ReadOnlyObservable<Column[]>,
    readonly rows: kx.ReadOnlyObservable<Row[]>,
    readonly rawDataDefinitionsStore: InputDataDefinitionsStore
  ) {}

  expandAll = (): void => {
    // do nothing
  };
  collapseAll = (): void => {
    // do nothing
  };
}

export class ArgsFactory {
  constructor(private _services: TrendServices) {}

  create(columns: kx.ReadOnlyObservable<Column[]>, rows: kx.ReadOnlyObservable<Row[]>): Args {
    return new Args(columns, rows, this._services.inputDataDefinitionsStore);
  }
}

ko.bindingHandlers.rawDataDefinitionGrid = {
  init(element, valueAccessor) {
    const binding = new Component(valueAccessor, element);
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => binding.dispose());
    return { controlsDescendantBindings: true };
  }
};
