export type Result<TResult, TError> = SuccessResult<TResult, TError> | ErrorResult<TResult, TError>;

export namespace Result {
  export function success(): SuccessResult<undefined, never>;
  export function success<T>(value: T): SuccessResult<T, never>;
  export function success(value?: any) {
    return new SuccessResult<any, never>(value);
  }

  export function error(): ErrorResult<never, undefined>;
  export function error<T>(value: T): ErrorResult<never, T>;
  export function error(value?: any) {
    return new ErrorResult<never, any>(value);
  }

  export function all<TR1, TE1, TR2, TE2>(results: [Result<TR1, TE1>, Result<TR2, TE2>]): Result<[TR1, TR2], (TE1 | TE2)[]>;
  export function all<T1, TR2, TE2>(results: [T1, Result<TR2, TE2>]): Result<[T1, TR2], [TE2]>;
  export function all<TR1, TE1, T2>(results: [Result<TR1, TE1>, T2]): Result<[TR1, T2], [TE1]>;
  export function all<TR1, TE1, TR2, TE2, TR3, TE3>(results: [Result<TR1, TE1>, Result<TR2, TE2>, Result<TR3, TE3>]): Result<[TR1, TR2, TR3], (TE1 | TE2 | TE3)[]>;
  export function all<T1, TR2, TE2, TR3, TE3>(results: [T1, Result<TR2, TE2>, Result<TR3, TE3>]): Result<[T1, TR2, TR3], (TE2 | TE3)[]>;
  export function all<T1, T2, TR3, TE3>(results: [T1, T2, Result<TR3, TE3>]): Result<[T1, T2, TR3], [TE3]>;
  export function all<TResult, TError>(results: (TResult | Result<TResult, TError>)[]) {
    if (results.every(isSuccessResult)) {
      const successResults: (any | SuccessResult<any, never>)[] = results;
      return Result.success(successResults.map((result) => (result instanceof SuccessResult ? result.value : result)) as any);
    } else {
      return Result.error(results.filter((result) => result instanceof ErrorResult).map((result: any) => result.error) as any);
    }
  }

  function isSuccessResult(result: any | Result<any, any>): boolean {
    return !isResult(result) || result.isSuccess;
  }

  function isResult(result: any): result is Result<any, any> {
    return result instanceof BaseResult;
  }
}

export namespace Results {
  export function successes<TR, TE>(results: Result<TR, TE>[]) {
    const values: TR[] = [];
    for (const result of results) {
      if (result.isSuccess) values.push(result.value);
    }
    return values;
  }

  export function errors<TR, TE>(results: Result<TR, TE>[]) {
    const errors: TE[] = [];
    for (const result of results) {
      if (result.isError) errors.push(result.error);
    }
    return errors;
  }
}

interface IIfSuccessOrIfErrorConfig<TResult, TError> {
  ifError(): TResult | TError;
  ifError<TNewError>(handle: (error: TError) => TNewError): TResult | TNewError;
}

abstract class BaseResult<TResult, TError> {
  mapSuccess<TNewResult>(handle: (value: TResult) => SuccessResult<TNewResult, never>): Result<TNewResult, TError>;
  mapSuccess<TNewError>(handle: (value: TResult) => ErrorResult<never, TNewError>): Result<TResult, TNewError>;
  mapSuccess<TNewResult, TNewError>(handle: (value: TResult) => SuccessResult<TNewResult, never> | ErrorResult<never, TNewError>): Result<TNewResult, TNewError>;
  mapSuccess<TNewResult, TNewError>(handle: (value: TResult) => Result<TNewResult, TNewError>): Result<TNewResult, TError | TNewError> {
    return this.map<any, any>(handle, (error) => Result.error(error));
  }

  mapError<TNewError>(handle: (error: TError) => ErrorResult<never, TNewError>): Result<TResult, TNewError>;
  mapError<TNewResult>(handle: (error: TError) => SuccessResult<TNewResult, never>): Result<TResult | TNewResult, TError>;
  mapError<TNewResult, TNewError>(handle: (error: TError) => SuccessResult<TNewResult, never> | ErrorResult<never, TNewError>): Result<TResult | TNewResult, TNewError>;
  mapError<TNewResult>(handle: (error: TError) => TNewResult): Result<TResult | TNewResult, never>;
  mapError<TNewResult, TNewError>(handle: (error: TError) => Result<TNewResult, TNewError>): Result<TResult | TNewResult, TNewError> {
    return this.map<any, any>((value) => Result.success(value), handle);
  }

  ifSuccess(): IIfSuccessOrIfErrorConfig<TResult, TError>;
  ifSuccess<TNewResult>(handle: (value: TResult) => TNewResult): IIfSuccessOrIfErrorConfig<TNewResult, TError>;
  ifSuccess<TNewResult>(handle?: (value: TResult) => TNewResult): IIfSuccessOrIfErrorConfig<TNewResult, TError> {
    const newValue = this.map<any, any>(handle || ((value) => value), (value) => value);
    return { ifError: (handleError?: any) => this.map<any, any>((value) => newValue, handleError || ((value) => value)) };
  }

  protected abstract map<TNewResult, TNewError>(onSuccess: (value: TResult) => TNewResult, onError: (value: TError) => TNewError): TNewResult | TNewError;
}

export class SuccessResult<TResult, TError> extends BaseResult<TResult, TError> {
  constructor(public value: TResult) {
    super();
  }

  isSuccess: true = true;
  isError: false = false;

  protected map<TNewResult, TNewError>(onSuccess: (value: TResult) => TNewResult, onError: (value: TError) => TNewError): TNewResult {
    return onSuccess(this.value);
  }
}

export class ErrorResult<TResult, TError> extends BaseResult<TResult, TError> {
  constructor(public error: TError) {
    super();
  }

  isSuccess: false = false;
  isError: true = true;

  protected map<TNewResult, TNewError>(onSuccess: (value: TResult) => TNewResult, onError: (value: TError) => TNewError): TNewError {
    return onError(this.error);
  }
}
