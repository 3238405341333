import ko from 'knockout';
import moment from 'moment';
import { Trend, SavingStatus } from '../models';
import { TrendsRepository } from '../repos';
import { DurationWithBackoff } from '@/gr/common/durationWithBackoff';
import { Timer } from '@/gr/common/timer';

export class TrendSaver {
  private _timer = new Timer();
  private _debounceDuration = moment.duration(1, 'seconds');
  private _autoSaveInterval = new DurationWithBackoff({ startingDuration: moment.duration(20, 'seconds'), backoffFactor: 1.2, maxDuration: moment.duration(1, 'minutes') });
  private _tooltipHtmlMessage = `Your trend will automatically save when you make changes.`;

  savingStatus = ko.observable(new SavingStatus('saved', 'Saved', this._tooltipHtmlMessage));

  constructor(
    private _trend: Trend,
    private _trendRepository: TrendsRepository
  ) {}

  startAutoSaving(): void {
    this.save();

    ['keydown', 'click'].forEach((eventType) => window.document.addEventListener(eventType, () => this.onUserInteraction()));
  }

  private onUserInteraction() {
    this.savingStatus(new SavingStatus('unsaved', this.savingStatus().htmlMessage, this._tooltipHtmlMessage));
    this._timer.setToFireOnceIn(this._debounceDuration, () => this.save());
  }

  save(): void {
    this.saveAsync();
    // this._timer.stop();
    // const updateTrend = this._trendRepository.save(this._trend);
    // if (updateTrend.hasTrendChanged) {
    //     this.savingStatus(new SavingStatus('saving', 'Saving...', this._tooltipHtmlMessage));
    // }
    // updateTrend.promise.then(result => {
    //     result
    //         .ifSuccess(() => {
    //             this.savingStatus(new SavingStatus('saved', 'Saved', this._tooltipHtmlMessage));
    //             this._autoSaveInterval.reset();
    //             this._timer.setToFireOnceIn(this._autoSaveInterval.get(), () => this.save());
    //         })
    //         .ifError(errorMessage => {
    //             this.savingStatus(
    //                 new SavingStatus(
    //                     'errored',
    //                     `Saving failed. Retrying...`,
    //                     `<p>We failed to save. Trying again in ${Math.round(this._autoSaveInterval.get().asSeconds())} seconds.</p>` + errorMessage
    //                 )
    //             );
    //             this._autoSaveInterval.increase();
    //             this._timer.setToFireOnceIn(this._autoSaveInterval.get(), () => this.save());
    //         });
    // });
  }

  saveAsync(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._timer.stop();
      const updateTrend = this._trendRepository.save(this._trend);
      if (updateTrend.hasTrendChanged) {
        this.savingStatus(new SavingStatus('saving', 'Saving...', this._tooltipHtmlMessage));
      }
      updateTrend.promise.then((result) => {
        result
          .ifSuccess(() => {
            this.savingStatus(new SavingStatus('saved', 'Saved', this._tooltipHtmlMessage));
            this._autoSaveInterval.reset();
            this._timer.setToFireOnceIn(this._autoSaveInterval.get(), () => this.save());
            resolve();
          })
          .ifError((errorMessage) => {
            this.savingStatus(
              new SavingStatus('errored', `Saving failed. Retrying...`, `<p>We failed to save. Trying again in ${Math.round(this._autoSaveInterval.get().asSeconds())} seconds.</p>` + errorMessage)
            );
            this._autoSaveInterval.increase();
            this._timer.setToFireOnceIn(this._autoSaveInterval.get(), () => this.save());
            reject();
          });
      });
    });
  }

  dispose(): void {
    this._timer.dispose();
  }
}
