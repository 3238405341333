import ko from 'knockout';
import _ from 'lodash';
import kx from '@/gr/common/knockout/extended';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { OutputChannelContainer, Trend, OutputChannelContainerArgsFactory, TrendServices, getOutputChannelCss } from '@/apps/timeSeriesViewer';

export class Component {
  constructor(private _args: Args) {
    this.isHeaderVisible = this._args.isHeaderVisible;
    this.outputChannelContainers = this._args.outputChannelContainers;
  }

  outputChannelContainers: KnockoutObservableArray<OutputChannelContainer>;

  selectedOutputChannelContainer = ko.pureComputed(() => {
    return this._args.outputChannelContainerArgsFactory.create(this.selectedContainer());
  });

  isHeaderVisible: kx.ReadOnlyObservable<boolean>;

  isSelected(container: OutputChannelContainer): boolean {
    return container === this.selectedContainer();
  }

  getCss(container: OutputChannelContainer): string {
    return getOutputChannelCss(container.outputChannel().channelType);
  }

  private selectedContainer() {
    return this._args.selectedOutputChannelContainer();
  }

  addNewOutputChannel(): void {
    const newContainer = OutputChannelContainer.empty();
    this.outputChannelContainers.push(newContainer);
    this._args.selectedOutputChannelContainer(newContainer);
  }

  selectOutputChannel(container: OutputChannelContainer): void {
    if (this._args.selectedOutputChannelContainer().outputChannel !== container.outputChannel) this._args.selectedOutputChannelContainer(container);
  }

  closeOutputChannel(container: OutputChannelContainer): void {
    const wasSelected = this.selectedContainer() === container;
    const index = _.indexOf(this.outputChannelContainers(), container);

    this.outputChannelContainers.remove(container);
    container.dispose();

    if (this.outputChannelContainers().length === 0) this.addNewOutputChannel();
    else if (wasSelected) this.selectOutputChannel(this.outputChannelContainers()[Math.max(index - 1, 0)]);
  }
}

export class Args {
  constructor(
    public outputChannelContainers: KnockoutObservableArray<OutputChannelContainer>,
    public selectedOutputChannelContainer: KnockoutObservable<OutputChannelContainer>,
    public isHeaderVisible: kx.ReadOnlyObservable<boolean>,
    public outputChannelContainerArgsFactory: OutputChannelContainerArgsFactory
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    const isHeaderVisible = ko.pureComputed(() => !this._trend.isReadOnlyMode());
    const outputChannelContainerArgsFactory = new OutputChannelContainerArgsFactory(this._trend, this._services);
    return new Args(this._trend.outputChannelContainers, this._trend.selectedOutputChannelContainer, isHeaderVisible, outputChannelContainerArgsFactory);
  }
}

import html from './outputChannelsComponent.html';
defineComponent(() => Component, 'outputChannels', html);
require('./outputChannelsComponent.less');
