import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { IKoDropOptions } from '@/gr/common/knockout/bindings/drop';
import { Trend, TrendServices, SavingStatus } from '@/apps/timeSeriesViewer';

export class Component {
  savingStatus;
  savingIndicatorDrop: IKoDropOptions = { template: 'saving-indicator-template', openOn: 'hoverTarget', position: 'bottom center' };
  constructor(private readonly _args: Args) {
    this.savingStatus = this._args.savingStatus;
  }
}

export class Args {
  constructor(public savingStatus: KnockoutObservable<SavingStatus>) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(this._services.trendSaver.savingStatus);
  }
}

import html from './savingIndicatorComponent.html';
defineComponent(() => Component, 'savingIndicator', html);
require('./savingIndicatorComponent.less');
