import ko from 'knockout';
import _ from 'lodash';
import { IPolicyAuthorisationDto } from '@/apps/timeSeriesViewer/repos';
import { PolicyAuthorisation } from '@/apps/timeSeriesViewer/models';

export class PolicyAuthorisationsService {
  private _policyAuthorisations: { name: PolicyAuthorisation; isAuthorised: KnockoutObservable<boolean> }[];

  constructor(
    policyAuthorisations: IPolicyAuthorisationDto[],
    private _defaultAuthorisationState = false
  ) {
    this._policyAuthorisations = policyAuthorisations.map((p) => ({ name: p.name, isAuthorised: ko.observable(p.isAuthorised) }));
  }

  isAuthorised(name: PolicyAuthorisation): KnockoutObservable<boolean> {
    let policy = _.find(this._policyAuthorisations, (x) => x.name === name);
    if (!policy) {
      policy = { name, isAuthorised: ko.observable(this._defaultAuthorisationState) };
      this._policyAuthorisations.push(policy);
    }
    return policy.isAuthorised;
  }
}
