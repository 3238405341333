




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import { HomeEntryPointArgs, TrendSummaryResourceDto } from '@/repositories';

import { GrFooter, GrGridTrendsList, GrLoading, GrTemplateList, GrTrendsAppBar, GrTrendsList } from '@/components';

@Component({
  components: { GrLoading, GrTrendsAppBar, GrFooter, GrTemplateList, GrTrendsList, GrGridTrendsList }
})
export default class TrendsHome extends Vue {
  @Getter('homeStore/homeEntryPointArgs') homeEntryPointArgs!: HomeEntryPointArgs;
  @Action('homeStore/loadHomeEntryPointArgs') loadHomeEntryPointArgs!: () => Promise<void>;
  @Getter('trendsStore/savedGridTrends') savedGridTrends!: TrendSummaryResourceDto[];

  private loading = true;

  private get isLoading() {
    return this.loading;
  }

  private get hasGridTrends() {
    return this.savedGridTrends.length > 0;
  }

  async created(): Promise<void> {
    await this.load();
  }

  private async load() {
    this.loading = true;
    await this.loadHomeEntryPointArgs();
    this.loading = false;
  }

  private get showAppBar() {
    return this.homeEntryPointArgs.showAppBar;
  }
}
