import _ from 'lodash';
import kx from '@/gr/common/knockout/extended';
import { guidGenerator } from '@/gr/common/guidGenerator';
import { ConfiguredDataDefinition, InputDataDefinition, CalculatedMetric, InputDataDefinitionFactory } from '@/apps/timeSeriesViewer';

export class ConfiguredDataDefinitionFactory {
  constructor(
    private _configuredDataDefinitions: kx.ReadOnlyObservable<ConfiguredDataDefinition[]>,
    private _inputDataDefinitionFactory: InputDataDefinitionFactory
  ) {}

  createFromInput(input: InputDataDefinition): ConfiguredDataDefinition {
    const configured = new ConfiguredDataDefinition(guidGenerator.create(), this.getVariableName(), input);
    configured.displayName(input.name());
    return configured;
  }

  createCalculated(): ConfiguredDataDefinition {
    const input = new InputDataDefinition(new CalculatedMetric());
    const configured = new ConfiguredDataDefinition(guidGenerator.create(), this.getVariableName(), input);
    return configured;
  }

  duplicate(configuredDataDefinition: ConfiguredDataDefinition): ConfiguredDataDefinition {
    const newInput = this._inputDataDefinitionFactory.duplicate(configuredDataDefinition.input);
    const newConfigured = this.createFromInput(newInput);
    newConfigured.displayName(configuredDataDefinition.displayName());
    newConfigured.timeStep(configuredDataDefinition.timeStep());
    newConfigured.aggregate(configuredDataDefinition.aggregate());
    newConfigured.isIncludedInOutputChannels(configuredDataDefinition.isIncludedInOutputChannels());
    newConfigured.unitsOfMeasure = configuredDataDefinition.unitsOfMeasure;
    return newConfigured;
  }

  private getVariableName() {
    const existingVariableNames = _.keyBy(this._configuredDataDefinitions(), (d) => d.calculationVariableName);
    for (let i = 0; ; i++) {
      const variableName = this.toAlphabetic(i);
      if (!(variableName in existingVariableNames)) return variableName;
    }
  }

  private toAlphabetic(i: number): string {
    const quotient = Math.floor(i / 26);
    const remainder = i % 26;
    const letter = String.fromCharCode(65 + remainder);

    if (quotient === 0) {
      return letter;
    } else {
      return this.toAlphabetic(quotient - 1) + letter;
    }
  }
}
