import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import _ from 'lodash';
import * as log from '@/gr/common/log';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { CompositeDisposable, SerialDisposable } from '@/gr/common/disposable';
import { CancellationTokenSource, CancellationToken } from '@/gr/common/cancellationToken';
import {
  TrendServices,
  Trend,
  InputDataDefinitionChooserState,
  FacetChooser,
  InputDataDefinitionsRepository,
  InputDataDefinitionsStore,
  Tab,
  InputDefinitionChooserTab,
  SearchBox,
  Messages,
  InputDataDefinition,
  SearchParameters
} from '@/apps/timeSeriesViewer';
import '@/gr/common/knockout/bindings/fastForEach';
import '@/gr/common/knockout/bindings/slideVisible';

export class Component {
  private readonly _requestDisposable = new SerialDisposable();
  private readonly _disposable = new CompositeDisposable();
  private readonly _state: InputDataDefinitionChooserState;

  private _lastSearchParameters?: SearchParameters;

  readonly tabs: KnockoutObservableArray<Tab>;
  readonly selectedTab: KnockoutObservable<Tab | null>;
  readonly selectedInputDataDefinitions: kx.ReadOnlyObservable<InputDataDefinition[]>;
  readonly goToNextStep: () => void;
  readonly isSelectedDefinitionsListExpanded = ko.observable(false);
  readonly facetChooser: FacetChooser.Args;
  readonly inputDataDefinitionChooserTab: InputDefinitionChooserTab.Args;
  readonly resultCount: KnockoutObservable<number | null>;
  readonly searchBox: SearchBox.Args;
  readonly isLoading: KnockoutObservable<boolean>;

  constructor(private _args: Args) {
    this._state = this._args.state;
    this.tabs = this._args.state.tabs;
    this.selectedTab = this._args.state.selectedTab;
    this.selectedInputDataDefinitions = this._args.selectedInputDataDefinitions;
    this.goToNextStep = this._args.goToNextStep;
    this.isSelectedDefinitionsListExpanded = ko.observable(false);
    this.facetChooser = new FacetChooser.ArgsFactory().create(this._args.state);
    this.inputDataDefinitionChooserTab = this._args.inputDataDefinitionChooserTabFactory.create(this.selectedTab as KnockoutObservable<Tab>);
    this.resultCount = this._args.state.resultCount;
    this.searchBox = this._args.searchBoxArgsFactory.create(this._args.state, () => ko.tasks.schedule(() => this.searchDebounced.flush()));
    this.isLoading = this._args.state.isLoading;
    this._disposable.add(
      ko.computed(() => {
        this.onSearchParametersChanged(this._args.inputDataDefinitionsRepository.getSearchParameters(_args.state));
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }

  private onSearchParametersChanged(searchParameters: SearchParameters) {
    if (!searchParameters.equals(this._lastSearchParameters)) {
      const lastSearchParameters = this._lastSearchParameters;
      this._lastSearchParameters = searchParameters;
      const cancellationSource = new CancellationTokenSource();
      this._requestDisposable.setDisposable(cancellationSource);
      this.isLoading(true);
      this.searchDebounced(searchParameters, cancellationSource.token);
      // If first query, or anything except search string has changed, run query immediately
      if (lastSearchParameters === undefined || lastSearchParameters === null || searchParameters.dto.search === lastSearchParameters.dto.search) this.searchDebounced.flush();
    }
  }

  private search = (searchParameters: SearchParameters, cancellation: CancellationToken) => {
    this._args.inputDataDefinitionsRepository
      .search(searchParameters, cancellation)
      .then((result) => {
        cancellation.throwIfCancelled();
        this.isLoading(false);
        result
          .ifSuccess((resultSearchParameters) => {
            /* State already updated by repository */
            this._lastSearchParameters = resultSearchParameters;
          })
          .ifError((error) => {
            this._args.messages.add(error);
            this._lastSearchParameters = undefined;
          });
      })
      .catch((error) => {
        if (CancellationToken.isCancellationError(error)) return;
        this.isLoading(false);
        this._lastSearchParameters = undefined;
        log.errorEx(error, 'An error occured while processing search');
      });
  };

  private searchDebounced = _.debounce(this.search, 750);

  selectTab(tab: Tab): void {
    if (tab === this.selectedTab()) return;
    this.isLoading(true);
    const previousTab = this.selectedTab();
    if (previousTab) {
      previousTab.rows([]);
      previousTab.columns([]);
    }
    this.selectedTab(tab);
  }

  toggleSelectedDefinitionsList(): void {
    if (this._args.selectedInputDataDefinitions().length > 0) this.isSelectedDefinitionsListExpanded(!this.isSelectedDefinitionsListExpanded());
  }

  removeInputDataDefinition = (dataDefinition: InputDataDefinition): void => {
    this._args.inputDataDefinitionsStore.remove(dataDefinition.id);
  };
}

export class Args {
  constructor(
    public state: InputDataDefinitionChooserState,
    public selectedInputDataDefinitions: kx.ReadOnlyObservable<InputDataDefinition[]>,
    public inputDataDefinitionsStore: InputDataDefinitionsStore,
    public goToNextStep: () => void,
    public inputDataDefinitionsRepository: InputDataDefinitionsRepository,
    public inputDataDefinitionChooserTabFactory: InputDefinitionChooserTab.ArgsFactory,
    public searchBoxArgsFactory: SearchBox.ArgsFactory,
    public messages: Messages.Args
  ) {}
}

export class ArgsFactory {
  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {}

  create(): Args {
    return new Args(
      this._trend.rawDataDefinitionChooserState,
      this._trend.selectedInputDataDefinitions,
      this._services.inputDataDefinitionsStore,
      () => this._trend.currentStepId('ConfigureData'),
      this._services.inputDataDefinitionsRepository,
      new InputDefinitionChooserTab.ArgsFactory(this._trend, this._services),
      new SearchBox.ArgsFactory(),
      this._trend.messages
    );
  }
}

import html from './inputDefinitionChooserComponent.html';
defineComponent(() => Component, 'inputDataDefinitionChooser', html);
require('./inputDefinitionChooserComponent.less');
