import _ from 'lodash';
import * as rawAnalytics from '@/gr/common/analytics/analytics';
import { StopWatch } from '@/gr/common/stopWatch';

class Analytics {
  private _stepStopWatch = new StopWatch();
  private _stepCount = 0;
  private _stepId!: number | null;
  private _stepName!: string;
  private _stepActions = 0;

  stepStarted(stepName: string) {
    this.stepEnded();
    this._stepName = stepName;
    this._stepCount++;
    this._stepId = this._stepCount;
    this._stepStopWatch.start();
    this.stepEvent({ Action: 'StepStarted', Step: stepName });
    this._stepActions = 0;
  }

  private stepEnded() {
    if (!_.isNil(this._stepId)) {
      this.stepEvent({ Action: 'StepEnded', Step: this._stepName, DurationInSeconds: this._stepStopWatch.timeElapsedInSeconds(), UserActions: this._stepActions });
      this._stepId = null;
      this._stepActions = 0;
    }
  }

  stepEvent(eventData: { Action: string; [key: string]: unknown }) {
    this._stepActions++;
    eventData.StepId = this._stepId;
    rawAnalytics.event('TimeSeriesWizard', eventData);
  }

  raw = rawAnalytics;
}

export const analytics = new Analytics();
