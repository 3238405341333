import ko from 'knockout';
import _ from 'lodash';
import moment from 'moment';
import { CompositeDisposable, SerialDisposable } from '@/gr/common/disposable';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { IScreenshotable } from '@/gr/common/social/imageRenderer';
import AmCharts from '@/libs/amcharts';
import {
  ChartState,
  GraphFactory,
  ValueAxesFactory,
  TrendData,
  AmChartDataPointsTransformer,
  createAmChartDefaults,
  AmChartImageService,
  AmChartCurrentPointInTimeLine,
  TrendServices,
  AmChartDataPointAggregatorFactory,
  AmChartDataPointAggregator,
  DateTimeFormatter,
  AmChartValueAxisOffsetCalculator,
  AmChartAlignZeroOnYAxesCalculator,
  Trend,
  FileNamingService,
  ChartTheme,
  IAmChartAxisOffsets,
  untitledTrendName,
  TrendDataDefinition
} from '@/apps/timeSeriesViewer';

export class Component implements IScreenshotable {
  constructor(
    private _args: Args,
    element: Node
  ) {
    this._themeId = ko.pureComputed(() => this._args.state.theme() || 'light');
    const chart = ko.computed(() => {
      return this.writeChartToDom(this._themeId(), element as HTMLElement);
    });
    this._disposable.add(chart);
    this._args.chartFunctions.downloadImage = () => this._imageService.downloadImage(this._args.fileNamingService.getDefaultFileName(this._args.state.trendData()));
    this._disposable.add(
      ko.computed(() => {
        this.update(chart(), this._args.state.trendData());
      })
    );
    this._disposable.add(this._chartDisposable);
  }
  dispose(): void {
    this._disposable.dispose();
  }
  private readonly _disposable = new CompositeDisposable();
  private readonly _chartDisposable = new SerialDisposable();
  private _imageService!: AmChartImageService;
  private _dataPointAggregator!: AmChartDataPointAggregator;
  private readonly _currentPointInTimeLine = new AmChartCurrentPointInTimeLine();
  private readonly _valueAxisOffsetCalculator = new AmChartValueAxisOffsetCalculator();
  private readonly _alignZeroOnYAxesCalculator = new AmChartAlignZeroOnYAxesCalculator();
  private readonly _themeId: KnockoutComputed<ChartTheme>;
  private readonly _theme = ko.pureComputed(() => AmCharts.themes[this._themeId()]);
  private writeChartToDom(themeId: ChartTheme, element: HTMLElement) {
    const chart = this._args.createAmChart(themeId, this._args.state.minPeriod);
    this._chartDisposable.setDisposable(() => chart.clear());
    element.style.backgroundColor = this.getBackgroundColor(themeId);
    this._imageService = new AmChartImageService(chart, element);
    this._dataPointAggregator = this._args.dataPointAggregatorFactory.create(chart, () => element.offsetWidth);
    chart.write(element.querySelector('.am-chart') as HTMLDivElement);
    return chart;
  }
  private update(chart: AmCharts.AmSerialChart, data: TrendData | null) {
    if (data === null || data.dataPoints.length === 0) {
      this.updateEmpty(chart);
      return;
    }
    // this.updateDataPeriodOnSeries(this._args.state.series(), data);
    (chart.balloon as AmCharts.AmBalloon).enabled = this._args.state.hasTooltips();
    chart.chartCursor.enabled = true;
    (chart.chartCursor as AmCharts.ChartCursor).categoryBalloonFunction = (date: Date) => AmCharts.formatDate(date, 'DD MMM YYYY JJ:NN') + ' ' + data.utcOffset.displayName;
    (chart.chartCursor as AmCharts.ChartCursor).showNextAvailable = this._args.state.showTooltipsForNextAvailable();
    chart.titles = this.getTitles(data);
    (chart.chartScrollbar as AmCharts.ChartScrollbar).enabled = this._args.state.hasScrollBar();
    (chart.legend as AmCharts.AmLegend).enabled = this._args.state.hasLegend() || this._args.state.hasCustomLegend();
    chart.valueAxes = this._args.valueAxesFactory.create(this._args.state.axes()).map((a) => _.assign(a, this._theme().AxisBase));
    chart.graphs = this._args.graphFactory.create(this._args.state.series(), chart.valueAxes);
    // Reset left and right margin in case axes have changed sides (since autoMargins only affects sides with axes)
    chart.marginRight = 30;
    chart.marginLeft = 30;
    chart.allLabels = [];
    this._currentPointInTimeLine.update(chart, data.appDateTimes.now(), data.utcOffset.value);
    const dataPointsWithStartAndEnd = this._args.dataPointTransformer.withStartAndEnd(data.dataPoints, data.appDateTimes.start(), data.appDateTimes.end());
    const dataPointsWithCorrectOffset = this._args.dataPointTransformer.transformDataPointsToUtcOffset(dataPointsWithStartAndEnd, data.utcOffset.value);
    this._dataPointAggregator.setDataPointsOnChart(dataPointsWithCorrectOffset);
    if (this._args.state.alignZeroOnYAxes())
      this._alignZeroOnYAxesCalculator.updateValueAxesToAlignZero(chart.valueAxes, dataPointsWithCorrectOffset, this._args.state.axes(), this._args.state.series());
    // Synchronize grid is incompatible with min and max (fixed axis)
    (chart as AmCharts.AmSerialChart).synchronizeGrid = chart.valueAxes.filter((a) => a.minimum !== null || a.maximum != null).length === 0;
    const disabled = this._dataPointAggregator.disableReaggregation();
    (chart as AmCharts.AmChart).validateNow(/* validateData: */ true, /* skip events */ true);
    // Zoom out here is required, otherwise the min and max time on the chart will not change to reflect the new data (which is very confusing)
    chart.zoomOut();
    // The following settings must be set only after the chart has redrawn once
    const axisOffsets = this._valueAxisOffsetCalculator.updateAxisOffsets(chart);
    chart.allLabels = this.getLabels(chart, axisOffsets);
    chart.legend.valueText = '';
    chart.invalidateSize();
    (chart as AmCharts.AmChart).validateNow(/* validateData: */ false, /* skip events */ true);
    disabled.dispose();
    this.updateMinAndMaxOnAxes(chart);
  }
  // private updateDataPeriodOnSeries(seriesCollection: ICompleteChartSeries[], data: TrendData) {
  //     _.forEach(seriesCollection, series => {});
  // }
  private updateMinAndMaxOnAxes(chart: AmCharts.AmSerialChart) {
    _.forEach(this._args.state.axes(), (axis) => {
      const amAxis = _.find<AmCharts.ValueAxis>(chart.valueAxes, (a) => a.id === axis.id);
      if (amAxis) {
        axis.dataMin(amAxis.min);
        axis.dataMax(amAxis.max);
      }
    });
  }
  private updateEmpty(chart: AmCharts.AmSerialChart) {
    chart.valueAxes = [this.defaults(new AmCharts.ValueAxis(), {})];
    // Peek to prevent observable from responding to changes in dataDefinition
    const dataDefinition = this._args.state.dataDefinition.peek() as TrendDataDefinition;
    chart.categoryAxis.title = 'Time';
    chart.graphs = [];
    chart.chartCursor.enabled = false;
    (chart.legend as AmCharts.AmLegend).enabled = false;
    chart.legend.valueText = '';
    (chart.chartScrollbar as AmCharts.ChartScrollbar).enabled = false;
    chart.dataProvider = [{ [chart.categoryField]: dataDefinition.appDateTimes.start().format() }, { [chart.categoryField]: dataDefinition.appDateTimes.end().format() }];
    (chart as AmCharts.AmChart).validateNow(true, false);
    chart.zoomOut();
  }
  private getBackgroundColor(theme: ChartTheme) {
    switch (theme) {
      case 'light':
        return 'transparent';
      case 'dark':
        return '#282828';
    }
  }
  private getTitles(data: TrendData) {
    let title = this._args.state.title();
    const time = DateTimeFormatter.dateTime(data.appDateTimes.now(), data.utcOffset).all;
    if (this._args.state.hasTitle()) {
      if (!title) title = untitledTrendName;
      return [{ text: title + ` (as at ${time})`, size: 15 } as AmCharts.Title];
    } else {
      return [];
    }
  }
  private getLabels(chart: AmCharts.AmSerialChart, axisOffsets: IAmChartAxisOffsets) {
    const titlesHeight = chart.titles.length * 32;
    const scrollBarHeight = (chart.chartScrollbar as AmCharts.ChartScrollbar).enabled ? 23 : 0;
    const totalHeight = chart.marginTop + titlesHeight + scrollBarHeight + 3;
    // Reduce font size and alpha of watermark on smaller screens to prevent it being too distracting
    const fontSize = Math.max(10, 10 + 3 * (window.innerWidth / 1920));
    const alpha = Math.max(0.2, 0.3 * (window.innerWidth / 1920));
    return [
      this.defaults(new AmCharts.Label(), { x: axisOffsets.left + 20, y: totalHeight, text: this._args.productName, size: fontSize, alpha: alpha }),
      this.defaults(new AmCharts.Label(), { x: axisOffsets.left + 20, y: totalHeight + 1.2 * fontSize, text: 'powered by TimeSeries', size: 10, alpha: alpha })
    ];
  }
  private defaults<T>(instance: T, defaults: Partial<T>): T {
    return _.defaults(instance, defaults) as T;
  }
  onCapturingImageStarted(): Promise<void> {
    return this._imageService.replaceChartWithImage();
  }
  onCapturingImageCompleted(): Promise<void> {
    return this._imageService.replaceImageWithChart();
  }
}

export class Args {
  constructor(
    public state: ChartState,
    public createAmChart: (theme: string, minPeriod: moment.Duration) => AmCharts.AmSerialChart,
    public dataPointAggregatorFactory: AmChartDataPointAggregatorFactory,
    public dataPointTransformer: AmChartDataPointsTransformer,
    public valueAxesFactory: ValueAxesFactory,
    public graphFactory: GraphFactory,
    public productName: string,
    public fileNamingService: FileNamingService
  ) {}

  chartFunctions: IChartFunctions = {
    downloadImage: () => Promise.reject<void>('Download functionality not yet available')
  };
}

export class ArgsFactory {
  constructor(
    private _services: TrendServices,
    private _trend: Trend
  ) {}

  create(state: ChartState): Args {
    return new Args(
      state,
      (theme, minPeriod) => {
        return AmCharts.makeChart('', createAmChartDefaults(theme, minPeriod)) as AmCharts.AmSerialChart;
      },
      new AmChartDataPointAggregatorFactory(),
      new AmChartDataPointsTransformer(),
      new ValueAxesFactory(),
      new GraphFactory(),
      this._trend.productName + (this._trend.prereleaseTag ? ` (${this._trend.prereleaseTag})` : ''),
      FileNamingService.create(this._trend)
    );
  }
}

export interface IChartFunctions {
  downloadImage(): Promise<void>;
}

import html from './amChart.html';
defineComponent(() => Component, 'amChart', html);
require('./amChart.less');
