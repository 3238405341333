











import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { IProductDto } from '@/repositories';
import { UrlHelper } from '@/gr/common/urlHelper';

@Component
export default class GrFeedbackBtn extends Vue {
  @Prop()
  feedbackBaseUrl!: string;

  @Prop()
  product!: IProductDto;

  @Prop()
  color!: string;

  private btnColor = this.color ?? 'secondary';

  private space = !!this.$slots.default;

  //TODO Remove coupling of back end to Feedback location.
  private url = UrlHelper.combine(
    this.feedbackBaseUrl ?? 'https://feedback.global-roam.com',
    `/#/send/?appId=${this.product?.id ?? 'undefined'}&appName=${encodeURIComponent(this.product?.name ?? 'undefined')}&referrer=${encodeURIComponent(window.location.href)}`
  );
}
