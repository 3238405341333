import moment from 'moment';
import _ from 'lodash';
import { Result } from '@/gr/common/result';
import {
  InputDataDefinitionConverter,
  ConfiguredDataDefinition,
  IConfiguredDataDefinitionDto,
  InputDataDefinitionFactory,
  InputDataDefinitionResult,
  nilToNull,
  DataSummary,
  IDataSummaryDto,
  nil,
  Aggregate,
  TimeStep,
  ErroredInputDataDefinitionResult,
  SuccessfulInputDataDefinitionResult
} from '@/apps/timeSeriesViewer';

export class ConfiguredDataDefinitionConverter {
  constructor(
    private _inputDataDefinitionConverter: InputDataDefinitionConverter,
    private _inputDataDefinitionFactory: InputDataDefinitionFactory
  ) {}

  toDto(model: ConfiguredDataDefinition): IConfiguredDataDefinitionDto {
    return {
      id: model.id,
      calculationVariableName: model.calculationVariableName,
      name: model.displayName() as string,
      aggregate: model.aggregate() as Aggregate,
      timeStep: model.timeStep() as TimeStep,
      isIncludedInOutputChannels: model.isIncludedInOutputChannels(),
      unitsOfMeasure: model.unitsOfMeasure,
      dataSummary: this.dataSummaryToDto(model.dataSummary),
      inputDataDefinitionId: this._inputDataDefinitionConverter.idToDto(model.input.id)
    };
  }

  private dataSummaryToDto(model: DataSummary | nil) {
    return model ? { period: model.period ? model.period.toString() : null } : null;
  }

  toModel(dto: IConfiguredDataDefinitionDto, inputDataDefinitions: InputDataDefinitionResult[]): Result<ConfiguredDataDefinition, string> {
    const inputDataDefinitionId = this._inputDataDefinitionConverter.idToModel(dto.inputDataDefinitionId);
    const inputDataDefinition = _.find(inputDataDefinitions, (d) => d.id.equals(inputDataDefinitionId));
    if (inputDataDefinition === undefined) {
      throw new Error('No input data definition exists for ' + inputDataDefinitionId);
    } else if (!inputDataDefinition.isSuccess) {
      return Result.error((inputDataDefinition as ErroredInputDataDefinitionResult).errorMessage);
    }
    const model = new ConfiguredDataDefinition(dto.id, dto.calculationVariableName, this._inputDataDefinitionFactory.duplicate((inputDataDefinition as SuccessfulInputDataDefinitionResult).value));
    model.displayName(dto.name);
    model.timeStep(dto.timeStep);
    model.aggregate(dto.aggregate);
    model.isIncludedInOutputChannels(dto.isIncludedInOutputChannels);
    model.unitsOfMeasure = nilToNull(dto.unitsOfMeasure);
    model.dataSummary = this.dataSummaryToModel(dto.dataSummary);
    return Result.success(model);
  }

  private dataSummaryToModel(dto: IDataSummaryDto | nil) {
    return dto ? new DataSummary(dto.period ? moment.duration(dto.period) : null) : null;
  }
}
