import ko from 'knockout';
import moment from 'moment';
import { Grid, GridOptions, ColDef, ColGroupDef } from 'ag-grid/main';
import { CompositeDisposable } from '@/gr/common/disposable';
import { TableState, DateTimeFormatter, SeriesUnitsOfMeasure } from '@/apps/timeSeriesViewer';
import { resetGlobalVariables } from '@/libs/aggrid/resetGlobalVariables';
import 'ag-grid/dist/styles/ag-grid.css';
import './tableOutputGridBinding.less';
import './nyrstar/lookupTable';
import { LookupTable } from './nyrstar/lookupTable';

export class Component {
  private _disposable = new CompositeDisposable();

  constructor(valueAccessor: () => Args, element: HTMLElement) {
    element.classList.add('table-output-grid-binding');

    const gridOptions: GridOptions = {
      suppressNoRowsOverlay: true,
      suppressLoadingOverlay: true,
      autoSizePadding: 10,
      rowHeight: 35,
      animateRows: true
    };

    const resetDisposable = resetGlobalVariables();

    const grid = new Grid(element, gridOptions);

    resetDisposable.dispose();

    this._disposable.add(() => grid.destroy());

    this._disposable.add(
      ko.computed(() => {
        const args = valueAccessor();
        const trendData = args.state.trendData();

        // Set up first (main) column.
        const firstColumn = {
          headerName: '',
          field: 'displayName',
          cellClass: 'row-header',
          cellRenderer: 'agGroupCellRenderer',
          pinned: true,
          width: 250
        } as ColDef;

        if (!trendData || trendData.dataPoints.length == 0) {
          if (gridOptions.api) {
            element.classList.remove('contains-rows');

            gridOptions.api.setColumnDefs([firstColumn]);
            gridOptions.api.setRowData([]);
            gridOptions.api.redrawRows();
          }
          return;
        }

        let previousDate: moment.Moment = moment(0);

        // limit to 100 points in the table
        const dataPoints = trendData.dataPoints.slice(0, 1000);

        // walk the trend data timestamps to find the first timestamp greater then now.  If not found set the last timestamp to zero point.
        let zeroIndex: number = dataPoints.findIndex((i) => moment(i.timeStamp).isSameOrAfter(trendData.appDateTimes.now()));
        if (zeroIndex === -1) zeroIndex = dataPoints.length - 1;
        const zeroInterval = moment(dataPoints[zeroIndex].timeStamp);

        // Set up remaining columns.
        const columns = dataPoints.map((d, index) => {
          let headerName;

          // Change header name depending on relative or fixed time range.
          if (trendData.appDateTimes.isRelative() && index !== zeroIndex) {
            headerName = DateTimeFormatter.relativeTradingPeriodTimestamp(d.timeStamp, trendData.utcOffset, zeroInterval);
          } else {
            headerName = DateTimeFormatter.time(d.timeStamp, trendData.utcOffset);
          }

          const dateTimeOffset = DateTimeFormatter.toDateTimeOffset(d.timeStamp, trendData.utcOffset);
          const showGroupHeader = !dateTimeOffset.isSame(previousDate, 'day');
          previousDate = dateTimeOffset;

          // Return column.
          return {
            headerName: showGroupHeader ? DateTimeFormatter.date(d.timeStamp, trendData.utcOffset) : '',
            children: [
              {
                headerName: headerName,
                field: d.timeStamp,
                headerTooltip: DateTimeFormatter.dateTimeForExcel(d.timeStamp, trendData.utcOffset),
                width: trendData.appDateTimes.isRelative() ? 80 : 95,
                cellClass: 'numeric-cell',
                cellStyle: (cell) => {
                  if (cell.value) {
                    const value = parseFloat(cell.value.replace(/,/g, ''));
                    return LookupTable.getCellColour(cell.data.dataDefinitionId, value);
                  }
                },
                suppressMovable: true
              }
            ]
          } as ColGroupDef;
        });

        const rows = trendData.configuredDataDefinitions.map((dataDefinition) => {
          const row: { [key: string]: string } = {
            displayName:
              dataDefinition.displayNameOrDefault() +
              (dataDefinition.unitsOfMeasure?.displayName.length && dataDefinition.unitsOfMeasure?.displayName.length > 0 ? ` (${dataDefinition.unitsOfMeasure?.displayName})` : ''),
            dataDefinitionId: dataDefinition.displayName() as string
          };

          dataPoints.forEach((dataPoint) => {
            if (dataDefinition.unitsOfMeasure) row[dataPoint.timeStamp] = SeriesUnitsOfMeasure.roundValue(dataPoint[dataDefinition.id], dataDefinition.unitsOfMeasure);
          });

          return row;
        });

        if (gridOptions.api) {
          if (rows.length > 0) {
            element.classList.add('contains-rows');
          } else {
            element.classList.remove('contains-rows');
          }

          gridOptions.api.setColumnDefs([firstColumn].concat(columns));
          gridOptions.api.setRowData(rows);
          gridOptions.api.redrawRows();
        }
      })
    );
  }

  dispose(): void {
    this._disposable.dispose();
  }
}

export class Args {
  constructor(public state: TableState) {}
}

export class ArgsFactory {
  create(state: TableState): Args {
    return new Args(state);
  }
}

ko.bindingHandlers.tableOutputGridBinding = {
  init(element, valueAccessor) {
    const binding = new Component(valueAccessor, element);
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => binding.dispose());
    return { controlsDescendantBindings: true };
  }
};
