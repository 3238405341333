import moment from 'moment';

function convertMinPeriod(minPeriod: moment.Duration) {
  const minutes = Math.floor(minPeriod.asMinutes());
  return (minutes === 1 ? '' : minutes) + 'mm';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createAmChartDefaults(theme: string, minPeriod: moment.Duration): any {
  return {
    path: '/amcharts/',
    type: 'serial',
    theme: theme,
    zoomOutOnDataUpdate: false,
    autoMarginOffset: 20,
    marginTop: 15,
    mouseWheelZoomEnabled: true,
    addClassNames: false,
    chartScrollbar: {
      autoGridCount: true,
      scrollbarHeight: 20,
      updateOnReleaseOnly: true
    },
    balloon: {
      animationDuration: 0,
      borderThickness: 1,
      fadeOutDuration: 0,
      fixedPosition: true,
      shadowAlpha: 0,
      maxWidth: 1000
    },
    chartCursor: {
      showNextAvailable: true,
      leaveCursor: false,
      cursorPosition: 'start'
    },
    categoryField: 'timeStamp',
    categoryAxis: {
      title: '',
      parseDates: true,
      dashLength: 1,
      minPeriod: convertMinPeriod(minPeriod), // The minimum period of time (this determines the width of columns and steps)
      minorGridEnabled: false,
      gridAlpha: 0.07,
      titleBold: false,
      titleFontSize: 10,
      centerLabelOnFullPeriod: false, // Setting this to false causes labels to be left aligned within a cell (closer to what we want, which is centering the labels under the grid lines)
      centerLabels: false,
      equalSpacing: false // Setting this to true centers labels under grid lines but causes zooming to be buggy
    },
    export: {
      enabled: false
    },
    legend: {
      valueWidth: 100,
      align: 'center',
      reversedOrder: true,
      rollOverGraphAlpha: 0.1,
      markerSize: 8,
      autoMargins: false,
      marginLeft: 5,
      marginRight: 5,
      marginTop: -15,
      marginBottom: 5,
      markerType: 'circle'
    },
    hideCredits: true
  };
}
