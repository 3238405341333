export class ColourProvider {
  nextColor(): string {
    return ColourProvider.colours[this.index++ % ColourProvider.colours.length];
  }

  private index = 0;

  private static colours = [
    '#FF6600',
    '#FCD202',
    '#B0DE09',
    '#0D8ECF',
    '#2A0CD0',
    '#CD0D74',
    '#CC0000',
    '#00CC00',
    '#0000CC',
    '#DDDDDD',
    '#999999',
    '#333333',
    '#990000',

    // http://tools.medialab.sciences-po.fr/iwanthue/
    '#9c54c7',
    '#5ab84b',
    '#d44db4',
    '#a7b438',
    '#5e6bd9',
    '#d5982f',
    '#5289cb',
    '#d95429',
    '#47bcd2',
    '#d9465d',
    '#55b88e',
    '#db4183',
    '#427d3e',
    '#b966b6',
    '#9ab168',
    '#725c9f',
    '#787429',
    '#b09ae6',
    '#9c602d',
    '#dd87b5',
    '#d3a568',
    '#9e4666',
    '#e48059',
    '#b03c32',
    '#d47b7b',

    '#4db1da',
    '#d84c34',
    '#5aca65',
    '#9958cf',
    '#4d9b2e',
    '#c347a8',
    '#576dd7',
    '#c6a937',
    '#ce88e2',
    '#5acc9a',
    '#e6447f',
    '#73a967',
    '#b73c6d',
    '#a3bc41',
    '#b2433d',
    '#788fd3',
    '#dd8437',
    '#7c579c',
    '#de89ba',
    '#6a742b',
    '#dd7e79',
    '#cca765',
    '#9d612f',

    '#d187cf',
    '#53d64f',
    '#b251e5',
    '#95cc3d',
    '#d34cb9',
    '#579c40',
    '#7570dc',
    '#c7c542',
    '#7995d4',
    '#e0a935',
    '#73cdd6',
    '#e24733',
    '#74d791',
    '#dd487d',
    '#49a684',
    '#d47270',
    '#5d8c9d',
    '#c97736',
    '#d1b3c9',
    '#8c8930',
    '#9c6e8e',
    '#d0bd79',
    '#6f895c',
    '#aa8268',
    '#b8c2a6'
  ];
}
