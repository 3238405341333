


























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { GrConfirmDialogue, GrGridTrendsList } from '@/components';
import { TrendSummaryResourceDto } from '@/repositories';
import moment from 'moment';

@Component({
  components: { GrConfirmDialogue, GrGridTrendsList }
})
export default class GrTrendsList extends Vue {
  @Getter('trendsStore/savedTrends') savedTrends!: TrendSummaryResourceDto[];
  @Action('trendsStore/loadTrends') loadTrends!: () => Promise<void>;
  @Action('trendsStore/deleteTrend') deleteTrend!: (id: string) => Promise<void>;

  private loading = true;

  private trendFilter = '';

  private sortBy = 'Last Saved';

  async created(): Promise<void> {
    await this.loadTrends();
    this.loading = false;
  }

  private formatDate(date: Date): string {
    return moment(date).format('MMMM Do YYYY');
  }

  private get isLoading() {
    return this.loading;
  }

  private async confirmDeleteAndRefresh(trend: TrendSummaryResourceDto) {
    const confirmDialogue = this.$refs.confirmDialogue as GrConfirmDialogue;
    if (trend?.trendSummary?.id && (await confirmDialogue.activate('Delete Trend', `Are you sure you want to delete the trend "${trend?.trendSummary?.title}"?`))) {
      this.loading = true;
      await this.deleteTrend(trend.trendSummary.id);
      await this.loadTrends();
      this.loading = false;
    }
  }

  private get sortByOptions() {
    return ['Last Saved', 'Title'];
  }

  private get filteredTrends() {
    return this.savedTrends.filter((t) => t.trendSummary?.title?.search(new RegExp(this.trendFilter, 'i')) !== -1).sort(this.sortFunction);
  }

  private sortFunction(a: TrendSummaryResourceDto, b: TrendSummaryResourceDto): number {
    if (this.sortBy === 'Title') {
      return (a.trendSummary?.title ?? '').localeCompare(b.trendSummary?.title ?? '');
    } else if (this.sortBy === 'Last Saved') {
      if (a.trendSummary?.lastSavedTime && b.trendSummary?.lastSavedTime) {
        return moment(a.trendSummary.lastSavedTime) > moment(b.trendSummary.lastSavedTime) ? -1 : 1;
      }
      return 0;
    }
    return 0;
  }
}
