import _ from 'lodash';
import { Metric } from '.';

export class AggregateMetric {
  constructor(
    readonly entityTypeId: string,
    readonly propertyId: string,
    readonly facets: { facetId: string; valueId: string }[]
  ) {}

  equals(d: Metric): boolean {
    return d instanceof AggregateMetric && d.entityTypeId === this.entityTypeId && d.propertyId === this.propertyId && _.isEqual(this.facets, d.facets);
  }

  toUniqueString(): string {
    return [this.entityTypeId]
      .concat(this.facets.map((f) => `[${f.facetId}:${f.valueId}]`))
      .concat(this.propertyId)
      .join('.');
  }
}
