

















import { Tools } from '@/services';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import Component from 'vue-class-component';

@Component
export default class GrColourPickerWrapper extends Vue {
  @Prop()
  kOInputCallback!: (value: unknown) => null;

  @Prop({ default: '#777777FF' })
  value!: string;

  private localCopyOfValueHack = this.value;

  @Watch('value')
  private updateLocalCopyOfValueHack() {
    this.localCopyOfValueHack = this.value;
  }

  private menu = false;

  private inputEvent(event: string) {
    if (this.kOInputCallback) this.kOInputCallback(event);
    this.localCopyOfValueHack = event;
    this.$emit('input', event);
  }

  private swatchStyle() {
    return {
      cursor: 'pointer',
      height: '30px',
      width: '30px',
      borderRadius: this.menu ? '50%' : '4px',
      transition: 'border-radius 200ms ease-in-out'
    };
  }

  private backgroundSwatchStyle() {
    return {
      background: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWNgYGCQwoKxgqGgcJA5h3yFAAs8BRWVSwooAAAAAElFTkSuQmCC) repeat',
      ...this.swatchStyle()
    };
  }

  private forgroundSwatchSyle() {
    // note this conversion from #rgba to rgba() is needed to maintain compatibility with chromium v57 used in ez2view v7
    return {
      background: `${Tools.hashToRgba(this.value ?? '')}`,
      ...this.swatchStyle()
    };
  }
}
