import { Moment } from 'moment';
import { AppDateTimesSnapshot, IConfiguredDataDefinitionDto, IDataPointDto, InputDataDefinitionResult, TrendData, TrendDataConverter, TrendDataDefinition } from '@/apps/timeSeriesViewer';
import { DataPointDto } from '@/repositories';

export interface ITrendDataUpdater {
  update(): void;

  add(configuredMetricId: string, dataPoints: DataPointDto[]): void;
}

export class TrendDataUpdater implements ITrendDataUpdater {
  private _collectedDataPoints: {
    configuredMetric: IConfiguredDataDefinitionDto;
    isForecast: boolean;
    dataPoints: DataPointDto[];
  }[] = [];
  private readonly _inputDataDefinitionResults: InputDataDefinitionResult[];
  private readonly _appDateTimes: AppDateTimesSnapshot;

  constructor(
    private _trendData: KnockoutObservable<TrendData | null>,
    private _configuredMetrics: { configuredMetric: IConfiguredDataDefinitionDto; isForecast: boolean }[],
    private _trendDataConverter: TrendDataConverter,
    dataDefinition: TrendDataDefinition
  ) {
    this._inputDataDefinitionResults = dataDefinition.configuredDataDefinitions.map((d) => ({ isSuccess: true as const, id: d.input.id, value: d.input }));
    this._appDateTimes = dataDefinition.appDateTimes;
    this._configuredMetrics.forEach((x) => this._collectedDataPoints.push({ configuredMetric: x.configuredMetric, isForecast: x.isForecast, dataPoints: [] }));
  }

  update(): void {
    const start = this._appDateTimes.start();
    const end = this._appDateTimes.end();
    const now = this._appDateTimes.now();
    const series = this._collectedDataPoints.map((x) => ({
      configuredDataDefinition: x.configuredMetric,
      dataPoints: this.getFilteredDataPoints(x.dataPoints, start, end, now, x.isForecast),
      messages: [],
      hasError: false
    }));

    const newTrendData = this._trendDataConverter.toModel({ series }, this._appDateTimes, this._inputDataDefinitionResults);
    this._trendData(newTrendData);
  }

  add(configuredMetricId: string, dataPoints: DataPointDto[]): void {
    const entry = this._collectedDataPoints.find((x) => x.configuredMetric.id === configuredMetricId);
    if (entry) entry.dataPoints = entry.dataPoints.concat(dataPoints);
  }

  private getFilteredDataPoints(dataPoints: DataPointDto[], start: Moment, end: Moment, now: Moment, isForecast: boolean): IDataPointDto[] {
    const endFilter = TrendDataUpdater.endOfInterval(end);
    const nowFilter = TrendDataUpdater.endOfInterval(now);

    // in the special case that the start is from now we include the start in the filter (especially relevant in time travel)
    const inclusivity = now.isSame(start) ? '[]' : '(]';

    return (
      dataPoints
        // round the end date to the interval ceiling to make sure we capture values for the end of the currently requested interval
        .filter((x) => {
          if (isForecast) {
            return x.timeStamp.isBetween(nowFilter, endFilter, undefined, inclusivity);
          } else {
            return x.timeStamp.isBetween(start, nowFilter < endFilter ? nowFilter : endFilter, undefined, inclusivity);
          }
        })
        .map((x) => {
          return {
            timeStamp: x.timeStamp.toISOString(),
            value: x.value
          } as IDataPointDto;
        })
    );
  }

  private static endOfInterval(time: Moment): Moment {
    const startOfMinute = time.clone().startOf('minutes');
    const minutes = startOfMinute.minutes() % 5;
    if (minutes != 0 || !time.isSame(startOfMinute)) {
      startOfMinute.add(5 - minutes, 'minutes');
    }
    return startOfMinute;
  }
}
