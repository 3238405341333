import ko from 'knockout';
import _ from 'lodash';
import Sortable from 'sortablejs';

export interface IDraggableBindingArgs<TItemViewModel, TItem = TItemViewModel> {
  collection: KnockoutObservableArray<TItem>;
  getItem: (viewModel: TItemViewModel) => TItem;
  options: any;
}

export interface ISortableEvent {
  to: HTMLElement; // the list the element was dragged to
  from: HTMLElement; // the list the element was dragged from
  item: HTMLElement; // the item that was dragged
  clone: HTMLElement;
  oldIndex: number | undefined; // old index in old list
  newIndex: number | undefined; // new index in new list
}

function isObservableArray(obj: unknown): obj is KnockoutObservableArray<unknown> {
  return ko.isObservable(obj) && !((obj as any).destroyAll === undefined);
}

class DraggableBinding<TItemViewModel, TItem = TItemViewModel> {
  private readonly _sortableElement: Sortable;
  private readonly _collection: KnockoutObservableArray<TItem>;
  private readonly _getItem: (viewModel: TItemViewModel) => TItem;
  private readonly _sortableOptions!: Sortable.Options & { containerDragClass: string };
  constructor(
    private readonly _element: HTMLElement,
    valueAccessor: () => IDraggableBindingArgs<TItemViewModel, TItem>
  ) {
    const value = valueAccessor();
    this._collection = value.collection;
    this._getItem = value.getItem;

    if (this._element === undefined) debugger;

    if (!_.isFunction(this._getItem)) throw new Error("Knockout 'draggable' binding is not configured correctly: the getItem parameter is not a function");
    if (!isObservableArray(this._collection)) throw new Error("Knockout 'draggable' binding is not configured correctly: the collection parameter is not an ObservableArray");

    const defaultSortableOptions = {
      group: { pull: false, put: false }
    };
    const sortableOptions = _.defaults({ containerDragClass: 'sortable-contains-drag' }, value.options, defaultSortableOptions);

    ['onStart', 'onEnd', 'onRemove', 'onAdd', 'onUpdate', 'onSort', 'onFilter', 'onMove', 'onClone'].forEach((eventType) => {
      if (sortableOptions[eventType] || this[eventType]) {
        const handler = sortableOptions[eventType];
        sortableOptions[eventType] = (event: ISortableEvent & Element & TItemViewModel) => {
          if (handler) handler(event, this._collection, this._getItem);
          const temp = this[eventType];
          if (temp && temp instanceof Function) temp.apply(this, [event]);
        };
      }
    });

    this._sortableOptions = sortableOptions;
    this._sortableElement = Sortable.create(_element, sortableOptions);
  }

  [eventType: string]: any;
  //     | ((event: ISortableEvent) => void)
  //     | ((item: Element) => number)
  //     | ((element: Element) => { element: Element; viewModel: TItemViewModel; model: TItem })
  //     | Sortable
  //     | KnockoutObservableArray<TItem>
  //     | TItem
  //     | (Sortable.Options & { containerDragClass: string })
  //     | HTMLElement
  //     | ((viewModel: TItemViewModel) => TItem);

  private onStart() {
    this._element.classList.add(this._sortableOptions.containerDragClass);
  }

  private onEnd() {
    this._element.classList.remove(this._sortableOptions.containerDragClass);
  }

  private onUpdate(event: ISortableEvent) {
    const item = this.getItemFor(event.item);

    // get a local copy of the array to edit so the screen doesn't update while we change it
    const collection = ko.observableArray(this._collection());

    // update the collection
    collection.remove(item.model);
    const newIndex = this.getNewIndexOfItem(event.item, collection);
    collection.splice(newIndex, 0, item.model);

    // Remove the dragged element which knockout may try to duplicate
    event.item.remove();

    // update the screen
    this._collection(collection());
  }

  private getNewIndexOfItem(item: Element, collection: KnockoutObservableArray<TItem>) {
    // Since the visible collection items may be a filtered version of collection, we cannot rely on event.newIndex.
    // Instead, we should insert the item in the collection relative to the previous or next items that are visible.
    // debugger;
    const newPreviousItem = item.previousElementSibling ? this.getItemFor(item.previousElementSibling) : null;
    if (newPreviousItem) {
      const newPreviousItemIndex = collection.indexOf(newPreviousItem.model);
      if (newPreviousItemIndex < 0) {
        console.error("Knockout 'draggable' binding is not configured correctly: could not find model in collection", collection(), newPreviousItem);
        throw new Error();
      }
      return newPreviousItemIndex + 1;
    }

    const newNextItem = item.nextElementSibling ? this.getItemFor(item.nextElementSibling) : null;
    if (newNextItem) {
      const newNextItemIndex = collection.indexOf(newNextItem.model);
      if (newNextItemIndex < 0) {
        console.error("Knockout 'draggable' binding is not configured correctly: could not find model in collection", collection(), newNextItem);
        throw new Error();
      }
      return newNextItemIndex;
    }
    return 0;
  }

  private getItemFor(element: Element) {
    const viewModel = ko.dataFor(element) as TItemViewModel;
    const model = this._getItem(viewModel);
    return { element, viewModel, model };
  }

  dispose(): void {
    this._sortableElement.destroy();
  }
}

ko.bindingHandlers.draggable = {
  init: (element, valueAccessor) => {
    const binding = new DraggableBinding<unknown>(element, valueAccessor);
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => binding.dispose());
  }
};
