import { defineComponent } from '@/gr/common/knockout/defineComponent';
import {
  OutputChannel,
  OutputChannelFactory,
  Trend,
  TrendServices,
  ChartStateFactory,
  TableStateFactory,
  FileStateFactory,
  ApiStateFactory,
  TrendSummaryForChannels,
  getOutputChannelCss
} from '@/apps/timeSeriesViewer';

export class Component {
  private outputChannel: KnockoutObservable<OutputChannel>;

  availableChannels: OutputChannelFactory[];

  constructor(private params: Args) {
    this.outputChannel = params.outputChannel;
    this.availableChannels = params.factories;
  }

  select = (outputChannelFactory: OutputChannelFactory): boolean => {
    if (outputChannelFactory.isEnabled) this.outputChannel(outputChannelFactory.create());
    return true;
  };

  getCss(channel: OutputChannelFactory): string {
    return getOutputChannelCss(channel.channelType);
  }
}

export class Args {
  constructor(
    public outputChannel: KnockoutObservable<OutputChannel>,
    public factories: OutputChannelFactory[]
  ) {}
}

export class ArgsFactory {
  private readonly _trendSummary;

  private readonly _outputChannelFactories: OutputChannelFactory[];

  constructor(
    private _trend: Trend,
    private _services: TrendServices
  ) {
    this._trendSummary = TrendSummaryForChannels.fromTrend(this._trend);

    this._outputChannelFactories = [
      new OutputChannelFactory('CHART', new ChartStateFactory(this._services), this._trendSummary, true),
      new OutputChannelFactory('TABLE', new TableStateFactory(), this._trendSummary, true),
      new OutputChannelFactory('FILE', new FileStateFactory(), this._trendSummary, true),
      new OutputChannelFactory('API', new ApiStateFactory(), this._trendSummary, false)
    ];
  }

  create(outputChannel: KnockoutObservable<OutputChannel>): Args {
    return new Args(outputChannel, this._outputChannelFactories);
  }
}

import html from './outputChannelSelector.html';
defineComponent(() => Component, 'outputChannelSelector', html);
require('./outputChannelSelector.less');
