


















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { GrFeedbackBtn } from '.';
import { IProductDto } from '@/repositories';

@Component({
  components: { GrFeedbackBtn }
})
export default class GrTrendsAppBar extends Vue {
  @Prop()
  product!: IProductDto;

  @Prop()
  feedbackBaseUrl!: string;

  // @Prop()
  // navDrawer!: boolean;

  private productLogo = `/images/product/${this.product?.id}/appbar.png`;
}
