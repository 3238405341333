import _ from 'lodash';
import moment from 'moment';
import { rejectAfter } from '@/gr/common/utils/promise';
import AmCharts from '@/libs/amcharts';

/** This class converts an AmChart into an image and either 1) inserts it into the DOM to be screenshoted, or 2) allows the user to download it. */
export class AmChartImageService {
  private _captureScreenshotPromise: Promise<any>;
  private _screenshotElements!: HTMLElement[];

  constructor(
    private _chart: AmCharts.AmChart,
    private _elementToInsertImage: HTMLElement
  ) {
    if (!_chart.export || !_chart.export.enabled) {
      // Export plugin needs to be enabled in order to capture screenshots
      _chart.export = {
        enabled: true,
        menu: []
      } as any;
    }

    this._captureScreenshotPromise = new Promise((resolve, reject) => {
      _chart.addListener('rendered', (e: any) => {
        const interval = setInterval(() => {
          if ((window as any).fabric) {
            clearInterval(interval);
            resolve(e.chart.export.capture);
          }
        }, 100);
      });
    });
  }

  downloadImage(fileName: string): Promise<void> {
    return rejectAfter(
      moment.duration(30, 'seconds'),
      'Timed-out waiting to capture image of chart',
      new Promise<void>((resolve, reject) => {
        this._chart.export.capture({}, () => {
          this._chart.export.toJPG({ data: this._chart.dataProvider }, (data: any) => {
            resolve();
            (this as any).download(data, (this as any).defaults.formats.JPG.mimeType, fileName + '.jpg');
          });
        });
      })
    );
  }

  replaceChartWithImage(): Promise<void> {
    this.disposeScreenshotElements();
    const that = this;

    return rejectAfter(
      moment.duration(30, 'seconds'),
      'Timed-out waiting to capture image of chart',
      new Promise<void>((resolve) => {
        this._captureScreenshotPromise.then((captureScreenshot) => {
          captureScreenshot({}, () => {
            (this as any).toCanvas({}, (canvas: any) => {
              that._screenshotElements.push(canvas);
              that._elementToInsertImage.appendChild(canvas);
              resolve();
            });
          });
        });
      })
    );
  }

  replaceImageWithChart(): Promise<void> {
    this.disposeScreenshotElements();
    return Promise.resolve();
  }

  private disposeScreenshotElements() {
    _.forEach(this._screenshotElements, (element) => {
      element.remove();
    });

    this._screenshotElements = [];
  }
}
