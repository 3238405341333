import { Result } from '@/gr/common/result';
import { OrganisationsApi, IOrganisationDto } from '@/apps/timeSeriesViewer';

export class OrganisationsRepository {
  constructor(private _organisationsApi: OrganisationsApi) {}

  getAll(): Promise<Result<IOrganisationDto[], string>> {
    return this._organisationsApi.getAll();
  }
}
