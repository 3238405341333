import ko from 'knockout';
import kx from '@/gr/common/knockout/extended';
import { Metric } from '@/apps/timeSeriesViewer';

export class InputDataDefinitionChooserState {
  search = ko.observable<string>('');
  availableFacets = ko.observableArray<Facet>([]);
  selectedFacetValues = ko.observableArray<FacetValue>();
  tabs = ko.observableArray<Tab>([]);
  selectedTab = ko.observable<Tab | null>(null);
  resultCount = ko.observable<number | null>(null);
  isLoading = ko.observable<boolean>(false);
}

export class Tab {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly availableGroupingFacets: KnockoutObservableArray<FacetKey>,
    readonly selectedGroupingFacets: KnockoutObservable<FacetKey[] | null>,
    readonly columns: KnockoutObservableArray<Column>,
    readonly rows: KnockoutObservableArray<Row>,
    readonly isVisible: KnockoutObservable<boolean>
  ) {}
}

export class Row {
  constructor(
    readonly name: string,
    readonly cells: Cell[],
    readonly children: Row[],
    readonly isExpanded: KnockoutObservable<boolean>
  ) {}
}

export class Cell {
  constructor(
    readonly column: Column,
    readonly inputDataDefinitionId: Metric,
    readonly isSelected: kx.ReadOnlyObservable<boolean>,
    public score: number
  ) {}
}

export class Column {
  constructor(
    readonly index: number,
    readonly name: string
  ) {}
}

export class Facet {
  constructor(
    readonly id: string,
    readonly name: string
  ) {}

  values!: FacetValue[];
}

export class FacetKey {
  constructor(
    readonly id: string,
    readonly name: string
  ) {}
}

export class FacetValue {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly recordCount: number,
    readonly facet: Facet
  ) {}
}
