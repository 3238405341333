import moment from 'moment';
import { ErrorResult, Result, SuccessResult } from '@/gr/common/result';
import { ArtifactsApi, ITrendApiLinks } from '@/apps/timeSeriesViewer';

export class ArtifactsRepository {
  constructor(private _artifactsApi: ArtifactsApi) {}

  publish(trendLinks: ITrendApiLinks): Promise<Result<void, string>> {
    return this._artifactsApi.publish(trendLinks.publish);
  }

  unpublish(trendLinks: ITrendApiLinks): Promise<Result<void, string>> {
    return this._artifactsApi.unpublish(trendLinks.delete);
  }

  async getLastSaved(trendId: string): Promise<ErrorResult<never, string> | SuccessResult<moment.Moment | null, never>> {
    const lastSaved = await this._artifactsApi.getLastSaved(trendId);
    return lastSaved.isSuccess ? Result.success(lastSaved.value !== undefined ? moment(lastSaved.value) : null) : lastSaved;
  }
}
