import ko from 'knockout';
import { ICellRendererComp, ICellRendererParams } from 'ag-grid/main';
import { CompositeDisposable, SerialDisposable } from '@/gr/common/disposable';
import { InputDataDefinitionsStore, Cell, AggregateMetric } from '@/apps/timeSeriesViewer';

export class InputDefinitionCellRenderer implements ITypedCellRendererComp<Cell, IInputDefinitionCellRendererParams> {
  private readonly _iconElement: HTMLElement;
  private readonly _disposable = new CompositeDisposable();
  private readonly _isSelectedSubscription = new SerialDisposable();
  private _params: (ITypedCellRendererParams<Cell> & IInputDefinitionCellRendererParams) | null = null;

  constructor() {
    this._iconElement = document.createElement('div');
    this._iconElement.classList.add('cell');
    this.addEventListener(this._iconElement, 'click', this.onChanged.bind(this));
    this._disposable.add(this._isSelectedSubscription);
  }

  private addEventListener(element: HTMLElement, eventType: string, eventListener: (event: Event) => void) {
    element.addEventListener(eventType, eventListener);
    this._disposable.add(() => element.removeEventListener(eventType, eventListener));
  }

  init(params: ITypedCellRendererParams<Cell> & IInputDefinitionCellRendererParams): void {
    this.updateUi(params);
  }

  getGui(): HTMLElement {
    return this._iconElement;
  }

  refresh(params: ITypedCellRendererParams<Cell> & IInputDefinitionCellRendererParams): boolean {
    this.updateUi(params);
    return true;
  }

  destroy(): void {
    this._disposable.dispose();
  }

  private updateUi(params: ITypedCellRendererParams<Cell> & IInputDefinitionCellRendererParams) {
    this._params = params;
    const cell = params.value;
    params.eGridCell.style.padding = '0';
    params.eGridCell.style.outline = 'none';
    if (cell) {
      this._iconElement.setAttribute('data-test-name', 'raw-data-item');
      this._iconElement.setAttribute('data-test-id', cell.inputDataDefinitionId.toUniqueString());
      this._isSelectedSubscription.setDisposable(
        ko.computed(() => {
          this.updateIsSelectedAppearance(cell.isSelected());
        })
      );
      this._iconElement.classList.add('selectable');

      if (cell.inputDataDefinitionId instanceof AggregateMetric) {
        params.eGridCell.style.backgroundColor = 'transparent';
        this._iconElement.title = 'Select the sum of all the data sets in this group';
        this._iconElement.classList.remove('raw');
        this._iconElement.classList.add('aggregate');
      } else {
        params.eGridCell.style.backgroundColor = this.calculateBackgroundColor(cell.score);
        this._iconElement.title = 'Select this data set';
        this._iconElement.classList.add('raw');
        this._iconElement.classList.remove('aggregate');
      }
    } else {
      params.eGridCell.style.backgroundColor = 'transparent';
      this._iconElement.classList.remove('selectable');
    }
  }

  private updateIsSelectedAppearance(isSelected: boolean) {
    if (isSelected) this._iconElement.classList.add('selected');
    else this._iconElement.classList.remove('selected');
  }

  private calculateBackgroundColor(score: number) {
    const max = 255;
    const min = 249;
    const byte = Math.min(Math.max(Math.floor(min + (max - min) * score), min), max);
    return `rgb(${byte}, ${byte}, ${byte})`;
  }

  private onChanged() {
    if (this._params && this._params.value) {
      const value = this._params.value;
      const isSelected = !value.isSelected();
      this.updateIsSelectedAppearance(isSelected);
      this._params.rawDataDefinitionsStore.setSelected(value.inputDataDefinitionId, isSelected).then(() => this.updateIsSelectedAppearance(value.isSelected()));
    }
  }
}

export interface IInputDefinitionCellRendererParams {
  rawDataDefinitionsStore: InputDataDefinitionsStore;
}

interface ITypedCellRendererComp<TCell, TParams> extends ICellRendererComp {
  init(params: ITypedCellRendererParams<TCell> & TParams): void;
  refresh(params: ITypedCellRendererParams<TCell> & TParams): boolean;
}

interface ITypedCellRendererParams<T> extends ICellRendererParams {
  value: T;
}
